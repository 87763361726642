import React from 'react';
import { Link } from 'react-router-dom';
import { data } from './datacentral';




const ShopGridV1 = () => {
	let publicUrl=""
	

  return (
	<div>
			<div className="ltn__product-area ltn__product-gutter mb-100">
				<div className="container">
						<div className="row">
							<div className="col-lg-12">
							<div className="ltn__shop-options">
								<ul>
								<li>
									<div className="ltn__grid-list-tab-menu ">
									<div className="nav">
										<a className="active show" data-bs-toggle="tab" href="#liton_product_grid"><i className="fas fa-th-large" /></a>
										<a data-bs-toggle="tab" href="#liton_product_list"><i className="fas fa-list" /></a>
									</div>
									</div>
								</li>
							
								</ul>
							</div>
							<div className="tab-content ">
								<div className="tab-pane fade active show" id="liton_product_grid">
								<div className="ltn__product-tab-content-inner ltn__product-grid-view">
									<div className="row">
									{data.map((property) => (
											<div className="col-lg-4 col-sm-6 col-12" style={{zIndex:"0",position:"relative",marginTop:"30px"}}>
										<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
										<div className="product-img">
                                        <Link to={`/detailview/${property.id}`} ><img src={property.img} alt="#" /></Link>
											<div className="real-estate-agent">
											<div className="agent-img">
                                            <Link to={`/detailview/${property.id}`} ></Link>
											</div>
											</div>
										</div>
										<div className="product-info">
											<div className="product-badge">
											<ul>
												<li className="sale-badg">{property.propertyname}</li>
											</ul>
											</div>
											
											<div className="product-img-location">
											<ul>
												<li className="go-top">
												<Link to={`/detailview/${property.id}`} ><i className="flaticon-pin" /> {property.address1}</Link>
												</li>
											</ul>
											</div>
											<ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
											<li><span>{property.Configurations} </span>
												
											</li>
											
											</ul>
											
										</div>
										<div className="product-info-bottom">
											<div className="product-price">
											<span>₹ {property.price}<label>/Sq.Ft.</label></span>
										
											</div>
											<div className="special-link" style={{position:"absolute",right:"10%",bottom: "24px"}}>
											<Link to={`/detailview/${property.id}`} >View Details</Link>
											
											</div>
										</div>
										</div>
											
									</div>

))}


									
									</div>
								</div>
								</div>



								<div className="tab-pane fade" id="liton_product_list">
								<div className="ltn__product-tab-content-inner ltn__product-list-view">
									<div className="row">
								
									{data.map((property) => (
									<div className="col-lg-12">
										<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
										<div className="product-img">
                                        <Link to={`/detailview/${property.id}`} ><img src={property.img} alt="#" /></Link>
										</div>
										<div className="product-info">
											<div className="product-badge-price">
											<div className="product-badge">
												<ul>
												<li className="sale-badg">For Sale</li>
												</ul>
											</div>
											<div className="product-price">
												<span>₹ {property.price}<label>/Sq.Ft.</label></span>
											</div>
											</div>
											<h2 className="product-title go-top"> <Link to={`/detailview/${property.id}`} >{property.propertyname}</Link></h2>
											<div className="product-img-location">
											<ul>
												<li className="go-top">
                                                <Link to={`/detailview/${property.id}`} ><i className="flaticon-pin" />{property.address1}</Link>
												</li>
											</ul>
											</div>
											<ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
											<li><span>{property.Configurations} </span></li>
											</ul>
										</div>
										<div className="product-info-bottom">
											<div className="real-estate-agent">
											<div className="special-link" style={{position:"absolute",right:"10%",bottom: "24px"}}>
											<Link to={`/detailview/${property.id}`} >View Details</Link>
											
											</div>

											</div>
										</div>
										</div>
									</div>
									))}
									
									</div>
								</div>
								</div>
							</div>
							
							</div>
						</div>
				</div>
			</div>
						
			

			

			


			</div>
  )
}

export default ShopGridV1;