
let publicUrl = process.env.PUBLIC_URL + "/";

export const data =[
    { id:'HT5', 
    propertyname:"Ace Startlit",
    img:publicUrl+"assets/img/banner/Star.jpg",
    logo:"https://i.imgur.com/fLlQilH.png",
    modal:publicUrl+"assets/img/modal/hunting.jpg",
    floorPlan11:publicUrl+"assets/img/FloorPlan/Starlit/1.jpg",
    floorPlan21:publicUrl+"assets/img/FloorPlan/Starlit/2.jpg",
    pic1:publicUrl+"assets/img/Gallerynew/starlit/1.webp",
    pic2:publicUrl+"assets/img/Gallerynew/starlit/2.webp",
    pic3:publicUrl+"assets/img/Gallerynew/starlit/3.webp",
    pic4:publicUrl+"assets/img/Gallerynew/starlit/4.webp",
    pic5:publicUrl+"assets/img/Gallerynew/starlit/5.webp",
    pic6:publicUrl+"assets/img/Gallerynew/starlit/6.webp",
    siteplan:publicUrl+"assets/img/SitePlan/starlit.png",
    plan1:"2 BHK",
    plan2:"3 BHK",
    superareaplan11:"1350 Sq.Ft.",
    superareaplan21:"1775 Sq.Ft.",
    Noofparking:"1500+",
    parkingLevel:"2",
    constructionType:"Mivan",
    clubhousesize:"100000  sq.ft",
    podium:"Non-Podium",
    lift:"2 & 3",
    floorvsflats:"6/4",
    price:"8,000",
    address1:"Sector 152, Noida",
    address2:"SC-01/09, Sector 152, Noida, Uttar Pradesh 201303",
    RERANO:"UPRERAPRJ677294",
    status:"Under Construction",
    projectType:"Residential",
    projectArea:"7 Acres",
    sizes:"1350 - 1775 Sq.ft.",
    projectUnits:"496 Flats",
    launchDate:"May 2021",
    possessionDate:"April 2025",
    totalTowers:"5 Towers",
   totalFloors:"28",
    projectStatus:"Under Construction",
    configurations:"2BHK, 3BHK",
    location:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.052801603713!2d77.46963881829703!3d28.447824651381527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cc1e91a98d78b%3A0xa19520e37654e5d5!2sACE%20Starlit!5e0!3m2!1sen!2sin!4v1679739244588!5m2!1sen!2sin", 
    totalFlats:"492",
    greenArea:"70%",
    parkingLevel:"",
    totalFloors:"",
    ceilingHeight:"10 Ft.",
    mainDoorHeight:"9 Ft.",
    internalDoorHeight:"7 Ft.",
    para1:"Ace Starlit is a premium residential project located in Sector 152, Noida, Uttar Pradesh. Developed by Ace Group, this project offers luxurious and comfortable living spaces that cater to the needs and preferences of modern-day families.",
    para2:"The apartments at Ace Starlit are well-designed and come in 2 BHK, 3 BHK, and 4 BHK configurations, with sizes ranging from 1085 square feet to 3220 square feet. The apartments are spacious and well-ventilated, and come equipped with a range of modern amenities and facilities, including air conditioning, modular kitchens, high-speed elevators, and 24/7 security services.",
    para3:"Ace Starlit also offers a range of recreational and lifestyle facilities, including a swimming pool, a gymnasium, a club house, a children's play area, and a dedicated sports area. The project also features beautifully landscaped gardens and green spaces that provide a serene and peaceful environment for residents to relax and unwind.",
    para4:"Located in Sector 152, Noida, Ace Starlit enjoys excellent connectivity to major business and commercial hubs in Noida and Delhi. The project is well-connected to major roads and highways, including the Noida-Greater Noida Expressway, the Yamuna Expressway, and the FNG Expressway. It is also located close to major metro stations and bus stops, making it easy for residents to commute to different parts of the city.",
    para5:"Overall, Ace Starlit is a great investment for homebuyers looking for a luxurious and comfortable living experience in the bustling city of Noida. With its range of modern amenities and facilities, excellent connectivity, and serene environment, Ace Starlit is the perfect place to call home.",
    text1:"Ace Starlit is a premium residential project located in Sector 152, Noida, offering luxurious 2, 3, and 4 BHK apartments with world-class amenities such as a clubhouse, swimming pool, gymnasium, and landscaped gardens. The apartments are designed with modern architecture and spacious interiors, providing a comfortable and elegant living experience. The project is located in a prime location with easy access to major landmarks and is well-connected to other parts of the city via major highways and roads. It also incorporates sustainable features like rainwater harvesting, solar power generation, and waste management systems, making it an ideal choice for those who are environmentally conscious. With its prime location, luxurious amenities, and sustainable features, Ace Starlit is an excellent choice for those looking for a premium living experience in Noida.",
    faq1:"Why to choose Ace Startlit ?",
    ans1:"Posh location,Excellent Connectivity",
    faq2:"What is Ace Startlit Address?",
    ans2:"SC-01/09, Sector 152, Noida",
    faq3:"What is 2 BHK Starting Price? ",
    ans3:"2 BHK flat of Size 1350 Sq.Ft. sq.ft Starts from ₹ 1 Cr + Govt. Charges",
    faq4:"How many flat units available in Ace Startlit ?",
    ans4:"492 flats are available in the project",
    faq5:"Does Ace Startlit has kids play area?",
    ans5:"Yes, there is kids playing area available",
    faq6:"What is the RERA no. of Ace Startlit ?",
    ans6:"Ace Startlit RERA NO.UPRERAPRJ677294",
    faq7:"What is the Possession date of Ace Startlit ?",
    ans7:"Expected Possession date is around April 2025 ",
    faq8:"Is this Project is Bank Approved ?",
    ans8:"Yes, Ace Startlit is Approved by SBI Bank and HDFC Bank and many more.",
    faq9:"From where I can download Ace Startlit Brochure ?",
    ans9:"You can download the brochure from the website. Download brochure link:",
    faq10:"Does Ace Startlit have Penthouse ?",
    ans10:"No, the Project does not have Penthouse.",
  
  },
  { id:'HT6', 
  propertyname:"Kalpataru Vista ",
  logo:"https://i.imgur.com/heCGt8u.png",
  modal:publicUrl+"assets/img/modal/hunting.jpg",
  img:publicUrl+"assets/img/banner/kalpataruvista.webp",
  floorPlan11:publicUrl+"assets/img/FloorPlan/Vista/1.jpg",
  floorPlan21:publicUrl+"assets/img/FloorPlan/Vista/2.jpg",
  floorPlan22:publicUrl+"assets/img/FloorPlan/Vista/0.jpg",
  pic1:publicUrl+"assets/img/Gallerynew/Kalpatru/1.webp",
  pic2:publicUrl+"assets/img/Gallerynew/Kalpatru/2.webp",
  pic3:publicUrl+"assets/img/Gallerynew/Kalpatru/3.webp",
  pic4:publicUrl+"assets/img/Gallerynew/Kalpatru/4.webp",
  pic5:publicUrl+"assets/img/Gallerynew/Kalpatru/5.webp",
  pic6:publicUrl+"assets/img/Gallerynew/Kalpatru/6.webp",
  siteplan:publicUrl+"assets/img/SitePlan/vista.png",
  plan1:"2 BHK",
  plan2:"4 BHK",
  superareaplan11:"3047 Sq.Ft.",
  superareaplan21:"4145 Sq.Ft.",
  superareaplan22:"3995 Sq.Ft.",
  Noofparking:"250+",
  parkingLevel:"2",
  clubhousesize:"25000 Sq.Ft",
  constructionType:"Mivan",
  podium:"Podium",
  lift:"3",
  floorvsflats:"2",
  price:"11,500",
  address1:"Sector 128 Noida",
  address2:"Kalpataru Vista, Plot B 24 C Wish Town, Sector 128 Noida, Uttar Pradesh 201304",
  RERANO:" UPRERAPRJ14980",
  projectType:"Residential",
  projectArea:"3 Acres",
  sizes:"3011 - 4145 Sq.ft.",
  launchDate:"May, 2018",
  possessionDate:"April 2024",
  totalTowers:"2",
  totalFloors:"34 Floors",
  status:"Under Construction",
  configurations:"3BHK, 4BHK",
  location:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.655759661275!2d77.35810201455693!3d28.520002995917014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce7cfaa61f5b7%3A0xeee1789eb2eac629!2sKalpataru%20Vista!5e0!3m2!1sen!2sin!4v1679739330826!5m2!1sen!2sin" , 
  totalFlats:"250 Flats",
  greenArea:"78%",
  ceilingHeight:"10 Ft.",
  mainDoorHeight:"9 Ft.",
  internalDoorHeight:"7 Ft.",
  para1:"Kalpataru Vista is a luxurious residential project located in Sector 128, Noida, Uttar Pradesh. Developed by Kalpataru Group, this project offers premium living spaces that cater to the needs and preferences of modern-day families.",
      para2:"The apartments at Kalpataru Vista are well-designed and come in 3 BHK and 4 BHK configurations, with sizes ranging from 2007 square feet to 2763 square feet. The apartments are spacious and well-ventilated, and come equipped with a range of modern amenities and facilities, including air conditioning, modular kitchens, high-speed elevators, and 24/7 security services.",
      para3:"Kalpataru Vista also offers a range of recreational and lifestyle facilities, including a swimming pool, a gymnasium, a club house, a children's play area, and a dedicated sports area. The project also features beautifully landscaped gardens and green spaces that provide a serene and peaceful environment for residents to relax and unwind.",
      para4:"Located in Sector 128, Noida, Kalpataru Vista enjoys excellent connectivity to major business and commercial hubs in Noida and Delhi. The project is well-connected to major roads and highways, including the Noida-Greater Noida Expressway, the Yamuna Expressway, and the FNG Expressway. It is also located close to major metro stations and bus stops, making it easy for residents to commute to different parts of the city.",
      para5:"Overall, Kalpataru Vista is a great investment for homebuyers looking for a luxurious and comfortable living experience in the bustling city of Noida. With its range of modern amenities and facilities, excellent connectivity, and serene environment, Kalpataru Vista is the perfect place to call home.",
  text1:"Kalpataru Vista is a luxurious residential project located in Sector 128, Noida. The project offers premium 3 and 4 BHK apartments with contemporary design and world-class amenities such as a clubhouse, swimming pool, gymnasium, and landscaped gardens. The apartments are designed to provide a comfortable and luxurious living experience with spacious interiors and elegant finishes. The project is located in a prime location with excellent connectivity to major landmarks and is well-connected to other parts of the city via major highways and roads. It also incorporates sustainable features like rainwater harvesting, solar power generation, and waste management systems, making it an ideal choice for those who are environmentally conscious. With its prime location, luxurious amenities, and sustainable features, Kalpataru Vista is an excellent choice for those looking for a premium living experience in Noida.",
  faq1:"Why to choose Kalpataru Vista ?",
  ans1:"Posh location,Excellent Connectivity",
  faq2:"What is Kalpataru Vista Address?",
  ans2:"Kalpataru Vista, Plot B 24 C Wish Town, Sector 128 Noida ",
  faq3:"What is 3 BHK Apartment Starting Price? ",
  ans3:"3 BHK flat of Size 1608 - 3000 sq.ft Starts from ₹ 4.4 - 8.58 Cr + Govt. Charges",
  faq4:"How many flat units available in Kalpataru Vista ?",
  ans4:"1748 flats are available in the project",
  faq5:"Does Kalpataru Vista has kids play area?",
  ans5:"Yes, there is kids playing area available",
  faq6:"What is the RERA no. of Kalpataru Vista ?",
  ans6:"Kalpataru Vista RERA NO. UPRERAPRJ14980",
  faq7:"What is the Possession date of Kalpataru Vista ?",
  ans7:"Expected Possession date is around June 2024 ",
  faq8:"Is this Project is Bank Approved ?",
  ans8:"Yes, Kalpataru Vista is Approved by SBI Bank and HDFC Bank and many more.",
  faq9:"From where I can download Kalpataru Vista Brochure ?",
  ans9:"You can download the brochure from the website. Download brochure link:",
  faq10:"What are the security features of Kalpataru Vista ?",
  ans10:"Kalpataru Vista offers Video Door Security, 24x7 Security, Security Cabin and InterCom to ensure all the residents feel safe and secure.",
  
  },{ id:'HT8', 
  propertyname:"Tata Eureka",
  logo:"https://i.imgur.com/e1xDJjr.png",
  modal:publicUrl+"assets/img/modal/hunting.jpg",
  img:publicUrl+"assets/img/banner/tataeureka.jpg",
  floorPlan11:publicUrl+"assets/img/FloorPlan/Tataeureka/1.jpg",
  floorPlan21:publicUrl+"assets/img/FloorPlan/Tataeureka/1.jpg",
  floorPlan22:publicUrl+"assets/img/FloorPlan/Tataeureka/1.jpg",
  pic1:publicUrl+"assets/img/Gallerynew/TataEureka/1.webp",
  pic2:publicUrl+"assets/img/Gallerynew/TataEureka/2.webp",
  pic3:publicUrl+"assets/img/Gallerynew/TataEureka/3.webp",
  pic4:publicUrl+"assets/img/Gallerynew/TataEureka/4.webp",
  pic5:publicUrl+"assets/img/Gallerynew/TataEureka/5.webp",
  pic6:publicUrl+"assets/img/Gallerynew/TataEureka/6.webp",
  lift:"2",
  plan1:"2 BHK",
  plan2:"3 BHK",
  superareaplan11:"995 Sq.Ft.",
  superareaplan21:"1285 Sq.Ft.",
  superareaplan22:"1575 Sq.Ft.",
  siteplan:publicUrl+"assets/img/SitePlan/eureka.png",
  price:"8,500",
  address1:"Sector 150 Noida",
  address2:"SC-01 A1, Sector 150, Noida, Uttar Pradesh 201310",
  RERANO:"UPRERAPRJ469983",
  status:"Under Construction",
  launchDate:"May 2017",
  projectType:"Residential",
  projectArea:"20 Acres",
  sizes:"1100 - 1575 Sq.ft.",
  projectUnits:"1160 Flats",
  possessionDate:"Sep, 2023",
  totalTowers:"20 Towers",
  totalFloors:"28 Floors",
  configurations:"2BHK, 3BHK",
  constructionType:"Mivan",
  floorvsflats:"4 & 6",
  location:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1754.496229486344!2d77.48419290799964!3d28.419484404594524!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cc1fd2779cc97%3A0x13b2293706a249e3!2sTata%20Eureka%20Park!5e0!3m2!1sen!2sin!4v1679739473260!5m2!1sen!2sin", 
  greenArea:"80%",
  ceilingHeight:"10 Ft.",
  mainDoorHeight:"9 Ft.",
  internalDoorHeight:"7 Ft.",
  para1:"Tata Eureka Park is a premium residential project located in Sector 150, Noida, Uttar Pradesh. Developed by Tata Value Homes, this project offers luxurious and comfortable living spaces that cater to the needs and preferences of modern-day families.",
  para2:"The apartments at Tata Eureka Park are well-designed and come in 2 BHK and 3 BHK configurations, with sizes ranging from 1100 square feet to 1575 square feet. The apartments are spacious and well-ventilated, and come equipped with a range of modern amenities and facilities, including air conditioning, modular kitchens, high-speed elevators, and 24/7 security services.",
  para3:"Tata Eureka Park also offers a range of recreational and lifestyle facilities, including a swimming pool, a gymnasium, a club house, a children's play area, and a dedicated sports area. The project also features beautifully landscaped gardens and green spaces that provide a serene and peaceful environment for residents to relax and unwind.",
  para4:"Located in Sector 150, Noida, Tata Eureka Park enjoys excellent connectivity to major business and commercial hubs in Noida and Delhi. The project is well-connected to major roads and highways, including the Noida-Greater Noida Expressway, the Yamuna Expressway, and the FNG Expressway. It is also located close to major metro stations and bus stops, making it easy for residents to commute to different parts of the city.",
  para5:"Overall, Tata Eureka Park is a great investment for homebuyers looking for a luxurious and comfortable living experience in the bustling city of Noida. With its range of modern amenities and facilities, excellent connectivity, and serene environment, Tata Eureka Park is the perfect place to call home.",
  text1:"Tata Eureka Park is a premium residential project located in Sector 150, Noida. Spread across 20 acres, the project offers 2 and 3 BHK apartments in high-rise towers with contemporary design and world-class amenities. The apartments are designed to provide a comfortable and luxurious living experience with spacious interiors and elegant finishes. The project also incorporates several green features like rainwater harvesting, solar power generation, and waste management systems, making it an ideal choice for those who are environmentally conscious. The project is located in a prime location with excellent connectivity to major landmarks and is well-connected to other parts of the city via major highways and roads. With its prime location, luxurious amenities, and sustainable features, Tata Eureka Park is an excellent choice for those looking for a premium living experience in Noida.",
  faq1:"Why to choose Tata Eureka?",
  ans1:"Posh location,Excellent Connectivity",
  faq2:"What is Tata Eureka Address?",
  ans2:"SC-01 A1, Sector 150, Noida",
  faq3:"What is 2 BHK Starting Price? ",
  ans3:"2 BHK flat of Size 995 Sq. ft. Starts from Rs. 88 Lakhs onwards",
  faq4:"How many flat units available in Tata Eureka ?",
  ans4:"1160 flats are available in the project",
  faq5:"Does Tata Eureka has kids play area?",
  ans5:"Yes, there is kids playing area available",
  faq6:"What is the RERA no. of Tata Eureka ?",
  ans6:"Tata EurekaRERA NO.UPRERAPRJ469983",
  faq7:"What is the Possession date of Tata Eureka  ?",
  ans7:"Expected Possession date is around March 2023 ",
  faq8:"Is this Project is Bank Approved ?",
  ans8:"Yes, Tata Eureka is Approved by SBI Bank and HDFC Bank and many more.",
  faq9:"From where I can download Tata Eureka Brochure ?",
  ans9:"You can download the brochure from the website. Download brochure link:",
  faq10:"Does Tata Eureka have Penthouse ?",
  ans10:"Yes, the Project has Penthouse.",
  
  },{ id:'HT10', 
  propertyname:"Eldeco Live By The Greens",
  modal:publicUrl+"assets/img/modal/hunting.jpg",
  logo:"https://i.imgur.com/6TVnhPU.png",
  img:publicUrl+"assets/img/banner/Eldeco.jpg",
  floorPlan11:publicUrl+"assets/img/FloorPlan/Eldeco/1.jpg",
  floorPlan12:publicUrl+"assets/img/FloorPlan/Eldeco/2.jpg",
  floorPlan21:publicUrl+"assets/img/FloorPlan/Eldeco/3.jpg",
  pic1:publicUrl+"assets/img/Gallerynew/Eldeco/1.webp",
  pic2:publicUrl+"assets/img/Gallerynew/Eldeco/2.webp",
  pic3:publicUrl+"assets/img/Gallerynew/Eldeco/3.webp",
  pic4:publicUrl+"assets/img/Gallerynew/Eldeco/4.webp",
  pic5:publicUrl+"assets/img/Gallerynew/Eldeco/5.webp",
  pic6:publicUrl+"assets/img/Gallerynew/Eldeco/6.webp",
  siteplan:publicUrl+"assets/img/SitePlan/eldeco.png",
  plan1:"2 BHK",
  plan2:"3 BHK",
  superareaplan11:"1137 Sq.Ft.",
  superareaplan12:"1155 Sq.Ft.",
  superareaplan21:"1404 Sq.Ft.",
  parkingLevel:"2",
  clubhousesize:"35,000 Sq.Ft",
  constructionType:"Block",
  podium:" Non Podium",
  lift:"2",
  floorvsflats:"5",
  price:"7,000",
  address1:"Sector 150, Noida",
  address2:"SC-02/A9,Sec-150,Noida",
  RERANO:"UPRERAPRJ15172",
  status:"Under Construction",
  launchDate:"May 2017",
  projectType:"Residential",
  projectArea:"20 Acres",
  sizes:"1137 - 1404 Sq.ft.",
  launchDate:"Feb, 2018",
  possessionDate:"Dec 2023",
  totalTowers:"17 Towers",
  totalFloors:"G + 22 Floors",
  configurations:"2BHK, 3BHK",
  location:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.034572277574!2d77.4832939182916!3d28.418213652745607!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cc175e7bce8df%3A0xa38445461590a6!2sEldeco%20Live%20by%20the%20Greens!5e0!3m2!1sen!2sin!4v1679740492839!5m2!1sen!2sin" , 
  totalFlats:"1700 Flats",
  greenArea:"80%",
  ceilingHeight:"10 Ft.",
  mainDoorHeight:"9 Ft.",
  internalDoorHeight:"7 Ft.",
  para1:"Eldeco Live by the Greens is a premium residential project located in Sector 150, Noida, Uttar Pradesh. Developed by Eldeco Group, this project offers luxurious and comfortable living spaces that cater to the needs and preferences of modern-day families.",
  para2:"The apartments at Eldeco Live by the Greens are well-designed and come in 2 BHK, 3 BHK and 4 BHK configurations, with sizes ranging from 1137 square feet to 2025 square feet. The apartments are spacious and well-ventilated, and come equipped with a range of modern amenities and facilities, including air conditioning, modular kitchens, high-speed elevators, and 24/7 security services.",
  para3:"Eldeco Live by the Greens also offers a range of recreational and lifestyle facilities, including a swimming pool, a gymnasium, a club house, a children's play area, and a dedicated sports area. The project also features beautifully landscaped gardens and green spaces that provide a serene and peaceful environment for residents to relax and unwind.",
  para4:"Located in Sector 150, Noida, Eldeco Live by the Greens enjoys excellent connectivity to major business and commercial hubs in Noida and Delhi. The project is well-connected to major roads and highways, including the Noida-Greater Noida Expressway, the Yamuna Expressway, and the FNG Expressway. It is also located close to major metro stations and bus stops, making it easy for residents to commute to different parts of the city.",
  para5:"Overall, Eldeco Live by the Greens is a great investment for homebuyers looking for a luxurious and comfortable living experience in the bustling city of Noida. With its range of modern amenities and facilities, excellent connectivity, and serene environment, Eldeco Live by the Greens is the perfect place to call home.",
  text1:"Eldeco Live By The Greens is a premium residential project located in Sector 150, Noida. Spread across a vast area of lush greenery, the project offers luxurious 2, 3, and 4 BHK apartments that are designed to offer a comfortable and modern living experience. The apartments are spacious and well-ventilated, with large windows that allow natural light and fresh air to enter. The project also offers a wide range of amenities including a swimming pool, a fully equipped gymnasium, a jogging track, a clubhouse, a kids' play area, and more. With its strategic location, top-notch amenities, and exquisite design, Eldeco Live By The Greens is an ideal destination for those seeking a premium lifestyle in Noida.",
  faq1:"Why to choose Eldeco Live By The Greens ?",
  ans1:"Posh location,Excellent Connectivity",
  faq2:"What is Eldeco Live By The Greens Address?",
  ans2:"SC-02/A9,Sec-150,Noida",
  faq3:"What is 2 BHK Starting Price? ",
  ans3:"2 BHK flat of Size 995 Sq. ft. Starts from Rs. 85 Lakhs onwards",
  faq4:"How many flat units available inEldeco Live By The Greens?",
  ans4:"1748 flats are available in the project",
  faq5:"Does Eldeco Live By The Greens has kids play area?",
  ans5:"Yes, there is kids playing area available",
  faq6:"What is the RERA no. of Eldeco Live By The Greens ?",
  ans6:"Eldeco Live By The Greens RERA NO.UPRERAPRJ15172",
  faq7:"What is the Possession date of Eldeco Live By The Greens ?",
  ans7:"Expected Possession date is around August 2023 ",
  faq8:"Is this Project is Bank Approved ?",
  ans8:"Yes, Eldeco Live By The Greens is Approved by SBI Bank and HDFC Bank and many more.",
  faq9:"From where I can download Eldeco Live By The Greens Brochure ?",
  ans9:"You can download the brochure from the website. Download brochure link:",
  faq10:"Does Eldeco Live By The Greens have Penthouse ?",
  ans10:"No, Eldeco Live By The Greens doesn not have a Penthouse",
  
  },
  { id:'HT14', 
propertyname:"Godrej Nest",
logo:"https://i.imgur.com/YzVM0SB.png",
modal:publicUrl+"assets/img/modal/hunting.jpg",
img:publicUrl+"assets/img/banner/Nest.jpg",
floorPlan11:publicUrl+"assets/img/FloorPlan/Nest/1.jpg",
floorPlan12:publicUrl+"assets/img/FloorPlan/Nest/0.jpg",
floorPlan13:publicUrl+"assets/img/FloorPlan/Nest/0.jpg",
floorPlan14:publicUrl+"assets/img/FloorPlan/Nest/0.jpg",
floorPlan21:publicUrl+"assets/img/FloorPlan/Nest/2.jpg",
floorPlan22:publicUrl+"assets/img/FloorPlan/Nest/0.jpg",
pic1:publicUrl+"assets/img/Gallerynew/NEST/1.webp",
pic2:publicUrl+"assets/img/Gallerynew/NEST/2.webp",
pic3:publicUrl+"assets/img/Gallerynew/NEST/3.webp",
pic4:publicUrl+"assets/img/Gallerynew/NEST/4.webp",
pic5:publicUrl+"assets/img/Gallerynew/NEST/5.webp",
pic6:publicUrl+"assets/img/Gallerynew/NEST/6.webp",
siteplan:publicUrl+"assets/img/SitePlan/nest.png",
plan1:"3 BHK",
plan2:"4 BHK",
superareaplan11:"1907 Sq.Ft.",
superareaplan12:"1476 Sq.Ft.",
superareaplan13:"2062 Sq.Ft.",
superareaplan14:"2330 Sq.Ft.",
superareaplan21:"2700 Sq.Ft.",
superareaplan22:"3027 Sq.Ft.",
Noofparking:"",
parkingLevel:"",
constructionType:"Mivan",
clubhousesize:"45000 Sq.Ft",
podium:"Non Podium",
lift:"2",
floorvsflats:"4",
price:"7,000",
address1:"Sector 150",
address2:"Sector 150, Noida-Greater Noida Expressway, Noida",
RERANO:"UPRERAPRJ13521",
PropertyID:"HZ29",
status:"Under Construction",
launchDate:"Nov, 2017",
possessionDate:"Dec-2024",
projectType:"Residential",
projectArea:"18 Acres",
sizes:"1907.00 sq.ft. - 3027.00 sq.ft.",
launchDate:"Nov, 2017",
possessionDate:"Mar, 2025",
totalTowers:"14",
totalFloors:"31",
configurations:"3, 4 BHK Apartments",
location:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.961647329656!2d77.49184201455466!3d28.420414100401555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cc17b07d6cb7f%3A0xe77057bdd6c21349!2sGodrej%20Nest%2C%20Noida!5e0!3m2!1sen!2sin!4v1679740775899!5m2!1sen!2sin" , 
totalFlats:"1200",
greenArea:"70%",
ceilingHeight:"10 Ft.",
mainDoorHeight:"9 Ft.",
internalDoorHeight:"7 Ft.",
para1:"Godrej Nest is a luxurious residential project located in Sector 150, Noida, Uttar Pradesh. Developed by Godrej Properties, this project offers spacious and comfortable living spaces that cater to the needs and preferences of modern-day families.",
para2:"The apartments at Godrej Nest come in 2 BHK, 3 BHK, and 4 BHK configurations, with sizes ranging from 1250 square feet to 3050 square feet. The apartments are well-designed and come equipped with a range of modern amenities and facilities, including air conditioning, modular kitchens, high-speed elevators, and 24/7 security services.",
para3:"Godrej Nest also offers a range of recreational and lifestyle facilities, including a swimming pool, a gymnasium, a club house, a children's play area, and a dedicated sports area. The project also features beautifully landscaped gardens and green spaces that provide a serene and peaceful environment for residents to relax and unwind.",
para4:"Located in Sector 150, Noida, Godrej Nest enjoys excellent connectivity to major business and commercial hubs in Noida and Delhi. The project is well-connected to major roads and highways, including the Noida-Greater Noida Expressway, the Yamuna Expressway, and the FNG Expressway. It is also located close to major metro stations and bus stops, making it easy for residents to commute to different parts of the city.",
para5:"Overall, Godrej Nest is a great investment for homebuyers looking for a luxurious and comfortable living experience in the bustling city of Noida. With its range of modern amenities and facilities, excellent connectivity, and serene environment, Godrej Nest is the perfect place to call home.",
text1:"Godrej Nest is a luxurious residential project located in the prime location of Sector 150 in Noida. Spread across 9 acres of land, it offers a perfect blend of comfortable living and contemporary architecture. The project comprises of 7 towers and 926 units of 1 BHK, 2 BHK, 3 BHK, and 4 BHK apartments with sizes ranging from 712 square feet to 2,347 square feet. The apartments are equipped with modern amenities like a fully equipped gymnasium, clubhouse, swimming pool, kids' play area, landscaped garden, 24/7 security and surveillance, and more. The location of Godrej Nest is another major advantage, as it is well-connected to various parts of Noida, Greater Noida, and Delhi NCR. With its luxurious features, modern amenities, and prime location, Godrej Nest is an ideal destination for those seeking a perfect blend of luxury, comfort, and convenience.",
faq1:"Why to choose Godrej Nest ?",
ans1:"Posh location,Excellent Connectivity",
faq2:"What is Godrej Nest Address?",
ans2:"GH 14A, Sector 1 Noida Extension, Greater Noida ",
faq3:"What is 3 BHK Starting Price? ",
ans3:"3 BHK flat of Size 1907.00 sq.ft. Starts from Rs. 1.46 Cr onwards",
faq4:"How many flat units available in Godrej Nest ?",
ans4:"1200 flats are available in the project",
faq5:"Does Godrej Nest has kids play area?",
ans5:"Yes, there is kids playing area available",
faq6:"What is the RERA no. of Godrej Nest ?",
ans6:"Godrej Nest RERA NO. UPRERAPRJ13521",
faq7:"What is the Possession date of Godrej Nest ?",
ans7:"Expected Possession date is around December 2024 ",
faq8:"Is this Project is Bank Approved ?",
ans8:"Yes, Godrej Nest is Approved by SBI Bank and HDFC Bank and many more.",
faq9:"From where I can download Godrej Nest Brochure ?",
ans9:"You can download the brochure from the website. Download brochure link:",
faq10:"Does Godrej Nest have Penthouse ?",
ans10:"No, the Project does not have a Penthouse.",

}
        ];
      
      