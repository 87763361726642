import React, { useState } from "react";

function Accordion(props) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion">
      <div className="accordion-header" style={{display:"flex",padding:"5px"}} onClick={toggleAccordion}>
       <div style={{width:"850px",padding:"5px",height:"15px",paddingLeft:"40px"}}> <h6>{props.title}</h6></div>
        <div style={{float:"right",fontSize:"20px"}}>{isOpen ? "-" : "+"}</div>
      </div>
      {isOpen && (
        <div className="accordion-content">
          <div style={{padding:"8px",paddingLeft:"50px"}}>{props.content}</div>
        </div>
      )}
    </div>
  );
}

export default Accordion;