
let publicUrl = process.env.PUBLIC_URL + "/";

export const data = [
  {
    id: 'HT1',
    propertyname: "Ace Divino",
    logo: "https://i.imgur.com/8sv9IXM.png",
    modal: publicUrl + "assets/img/modal/ace.jpg",
    img: publicUrl + "assets/img/banner/acedivino.webp",
    floorPlan11: publicUrl + "assets/img/FloorPlan/Acedivino/11.png",
    floorPlan12: publicUrl + "assets/img/FloorPlan/Acedivino/12.png",
    floorPlan13: publicUrl + "assets/img/FloorPlan/Acedivino/13.png",
    floorPlan21: publicUrl + "assets/img/FloorPlan/Acedivino/21.png",
    floorPlan31: publicUrl + "assets/img/FloorPlan/Acedivino/31.png",
    floorPlan41: publicUrl + "assets/img/FloorPlan/Acedivino/41.png",
    floorPlan42: publicUrl + "assets/img/FloorPlan/Acedivino/42.png",
    floorPlan43: publicUrl + "assets/img/FloorPlan/Acedivino/43.png",
    floorPlan44: publicUrl + "assets/img/FloorPlan/Acedivino/44.png",
    pic1: publicUrl + "assets/img/Gallerynew/Acedivino/1.webp",
    pic2: publicUrl + "assets/img/Gallerynew/Acedivino/2.webp",
    pic3: publicUrl + "assets/img/Gallerynew/Acedivino/3.webp",
    pic4: publicUrl + "assets/img/Gallerynew/Acedivino/4.webp",
    pic5: publicUrl + "assets/img/Gallerynew/Acedivino/5.webp",
    pic6: publicUrl + "assets/img/Gallerynew/Acedivino/6.webp",
    siteplan: publicUrl + "assets/img/SitePlan/divino.png",
    thumbnail: publicUrl + "assets/img/thumbnail/divino.jpg",
    plan1: "2 BHK",
    plan2: "3 BHK",
    plan3: "4 BHK",
    plan4: "PentHouse",
    superareaplan11: "995 Sq.Ft.",
    superareaplan12: "1050 Sq.Ft.",
    superareaplan13: "1245 Sq.Ft.",
    superareaplan21: "1565 Sq.Ft.",
    superareaplan31: "1875 Sq.Ft.",
    superareaplan41: "1750 Sq.Ft.",
    superareaplan42: "1850 Sq.Ft.",
    superareaplan43: "2315 Sq.Ft.",
    superareaplan44: "2790 Sq.Ft.",
    Noofparking: "2000+",
    parkingLevel: "2",
    clubhousesize: "25000 sq.ft",
    podium: "Non Podium",
    lift: "3 & 4",
    price: "74 Lakhs-2.7 Cr ",
    address1: "Greater Noida West",
    address2: "Plot No. GH 14A, Sector 1 Noida Extension, Greater Noida",
    RERANO: "UPRERAPRJ6734",
    launchDate: "May 2017",
    status: "Ready to Move",
    projectType: "Residential ",
    projectArea: "10.5 Acres",
    sizes: "995 - 2790 Sq.ft",
    launchDate: "2019",
    possessionDate: "Mar, 2022",
    totalTowers: "11 Towers",
    totalFloors: "T(1,2)-18,T(3-11)-21",
    projectStatus: "Ready To Move",
    propertyType: "Residential",
    configurations: "2BHK, 3BHK, 4BHK",
    location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.2857262981247!2d77.43616551549425!3d28.561181594056546!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cef70fc3a9861%3A0x9ff8321e65d81a96!2sAce%20Divino!5e0!3m2!1sen!2sin!4v1674031451895!5m2!1sen!2sin",
    constructionType: "Mivan",
    totalFlats: "1502",
    greenArea: "78%",
    loading: "25%",
    ceilingHeight: "10.5 Ft.",
    mainDoorHeight: "8 Ft",
    internalDoorHeight: "8 Ft.",
    floorvsflats: "6 & 8",
    Noofparking: "2000+",
    para1: "Ace Divino is a premium residential project located in the bustling city of Noida, Uttar Pradesh. Developed by Ace Group, this project offers luxurious and comfortable living spaces that cater to the needs and preferences of modern-day families.",
    para2: "Spread over 10.5 acres of land, Ace Divino offers 2 BHK, 3 BHK, and 4 BHK apartments that range in size from 995 square feet to 2315 square feet. The apartments are spacious and well-ventilated, and they offer a range of modern amenities and facilities, including air conditioning, modular kitchens, high-speed elevators, and 24/7 security services.",
    para3: "Ace Divino also offers a wide range of recreational and lifestyle facilities, including a swimming pool, a gymnasium, a club house, a children's play area, and a dedicated sports area. The project also features beautifully landscaped gardens and green spaces that provide a serene and peaceful environment for residents to relax and unwind.",
    para4: "Located in the heart of Noida Extension, Ace Divino enjoys excellent connectivity to major business and commercial hubs in Noida and Delhi. The project is well-connected to major roads and highways, including the Noida-Greater Noida Expressway, the Yamuna Expressway, and the FNG Expressway. It is also located close to major metro stations and bus stops, making it easy for residents to commute to different parts of the city.",
    para5: "Overall, Ace Divino is a great investment for homebuyers looking for a luxurious and comfortable living experience in the bustling city of Noida.",
    text1: "Ace Divino is a residential project located in Greater Noida West, India. Developed by Ace Group, the project offers a range of apartments with modern amenities and facilities. The project features spacious and well-designed units with contemporary interiors and high-quality finishes. The development offers a host of recreational amenities such as a clubhouse, swimming pool, gymnasium, sports facilities, and landscaped gardens. Additionally, the project is well-connected to the major parts of the city and is situated in close proximity to various educational, commercial, and entertainment hubs. Ace Divino offers an ideal blend of comfort, luxury, and convenience, making it a desirable residential option for modern urban living.",
    faq1: "Why to choose Ace Divino?",
    ans1: "Posh location,Excellent Connectivity",
    faq2: "What is Ace Divino Address?",
    ans2: "GH 14A, Sector 1 Noida Extension, Greater Noida ",
    faq3: "What is 2 BHK Starting Price? ",
    ans3: "2 BHK flat of Size 995 Sq. ft. Starts from Rs. 75 lakh onwards",
    faq4: "How many flat units available in Ace Divino?",
    ans4: "1748 flats are available in the project",
    faq5: "Does Ace Divino has kids play area?",
    ans5: "Yes, there is kids playing area available",
    faq6: "What is the RERA no. of Ace Divino?",
    ans6: "Ace Divino RERA NO. UPRERAPRJ6734",
    faq7: "What is the Possession date of Ace Divino Sector 1 ?",
    ans7: "Expected Possession date is around August 2023 ",
    faq8: "Is this Project is Bank Approved ?",
    ans8: "Yes, Ace Divino is Approved by SBI Bank and HDFC Bank and many more.",
    faq9: "From where I can download Ace Divino Brochure ?",
    ans9: "You can download the brochure from the website. Download brochure link:",
    faq10: "Does Ace Divino have Penthouse ?",
    ans10: "Yes, the Project has Penthouse.",
    floorliving: "Vitrified Tiles",
    floormaster: "Vitrified Tiles",
    floorother: "Vitrified Tiles",
    fittingtoilet: "Hot and cold water mixer",
    fittingkitchen: "Granite platform with stainless steel sink",
    Doors: "UPVC/Powder Coated or Anodized Aluminium Door",
    interior: "Emulsion Paint ",
    exterior: "Superior Paint Finish",
    kitchen: "Designer Tiles Dado up to 2 Feet Height Above Platform"


  },
  {
    id: 'HT2',
    propertyname: "Ambience Tiverton ",
    modal: publicUrl + "assets/img/modal/hunting.jpg",
    logo: "https://i.imgur.com/RlMoMLc.png",
    img: publicUrl + "assets/img/banner/ambience.webp",
    plan1: "3 BHK + S",
    plan2: "4 BHK + S",
    plan3: "PentHouse",
    superareaplan11: "2548 Sq.Ft.",
    superareaplan21: "3284 Sq.Ft.",
    superareaplan31: "4800 Sq.Ft.",
    floorPlan11: publicUrl + "assets/img/FloorPlan/Ambience/1.jpg",
    floorPlan21: publicUrl + "assets/img/FloorPlan/Ambience/2.jpg",
    floorPlan31: publicUrl + "assets/img/FloorPlan/Ambience/2.jpg",
    pic1: publicUrl + "assets/img/Gallerynew/Ambience/1.webp",
    pic2: publicUrl + "assets/img/Gallerynew/Ambience/2.webp",
    pic3: publicUrl + "assets/img/Gallerynew/Ambience/3.webp",
    pic4: publicUrl + "assets/img/Gallerynew/Ambience/4.webp",
    pic5: publicUrl + "assets/img/Gallerynew/Ambience/5.webp",
    pic6: publicUrl + "assets/img/Gallerynew/Ambience/6.webp",
    siteplan: publicUrl + "assets/img/SitePlan/ambience.png",
    Noofparking: "500+",
    parkingLevel: "2",
    clubhousesize: "25000 sq.ft",
    podium: "Non Podium",
    lift: "3",
    floorvsflats: "2",
    price: "2.7 Cr- 5 Cr ",
    address1: "Sector 50, Noida",
    address2: "F-33, F Block, Sector 50, Noida",
    RERANO: "UPRERAPRJ4249",
    launchDate: "2016",
    projectArea: "3.5 Acres",
    sizes: "2548 - 4800 Sq.ft.",
    possessionDate: "2022",
    totalTowers: "7 Towers",
    totalFloors: "T(A,B,C,D)-G+24,T(E)-G+16,T(F,G)-G+21",
    status: "Ready To Move",
    propertyType: "Residential",
    configurations: "3 BHK, 4 BHK",
    location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.7506412924913!2d77.36810131549448!3d28.577249693330682!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5b84c2b5a73%3A0xaf840e835cff6c27!2sAmbience%20Tiverton!5e0!3m2!1sen!2sin!4v1674031553468!5m2!1sen!2sin",
    constructionType: "",
    totalFlats: "284",
    greenArea: "70%",
    ceilingHeight: "10.5 Ft.",
    mainDoorHeight: "8 Ft.",
    internalDoorHeight: "7 Ft.",
    text1: "Ambience Tiverton is a luxurious residential project located in Sector 50, Noida. It offers spacious 3 and 4 BHK apartments with modern amenities such as a swimming pool, gymnasium, clubhouse, and landscaped gardens. The apartments are equipped with state-of-the-art facilities such as modular kitchens with granite counters, imported marble flooring in living, dining, and bedrooms, and high-quality bathroom fittings. The project is conveniently located and offers easy access to major landmarks such as schools, hospitals, shopping centers, and entertainment zones. With its luxurious amenities and convenient location, Ambience Tiverton offers a comfortable living experience for those seeking a premium lifestyle in Noida.",
    video: "https://www.youtube.com/embed/cOjNovruLV0",
    para1: "Ambience Tiverton Noida is a luxurious residential project located in the upscale locality of Sector 50 in Noida, India. Spread across 3.5 acres of prime land, the project comprises of 3 and 4 BHK apartments that offer a perfect blend of comfort, style, and convenience. The apartments range in size from 2548 sq. ft. to 3273 sq. ft., and the project features 3 towers with a total of 248 units.",
    para2: "The project offers a wide range of amenities and facilities, including a swimming pool, gymnasium, clubhouse, landscaped gardens, and more. The apartments feature premium finishes and fittings, such as imported marble flooring, modular kitchens with high-quality appliances, and air conditioning in all rooms. The project also features advanced security systems to ensure the safety of its residents.",
    para3: "The location of Ambience Tiverton Noida is another highlight, with easy access to major commercial hubs, educational institutions, and healthcare facilities in the city. The project is located close to several prestigious schools and colleges, making it an ideal choice for families with children. The location is also well-connected to major roads and highways, making it easy for residents to travel to other parts of the city.",
    para4: "The project has been designed keeping in mind the needs and preferences of the modern-day family. The apartments are spacious, well-ventilated, and offer ample natural light, providing a serene and peaceful living environment. The project also offers a range of amenities that cater to the needs of different age groups, making it an ideal choice for families with children, seniors, and young professionals.",
    para5: "In conclusion, Ambience Tiverton Noida is a premium residential project that offers a luxurious and comfortable lifestyle to its residents. With its world-class amenities, premium finishes, and convenient location, the project is an ideal choice for those seeking a premium residential property in Noida.",
    faq1: "Why to choose Ambience Tiverton?",
    ans1: "Posh location,Excellent Connectivity",
    faq2: "What is Ambience Tiverton Address?",
    ans2: "F-33, F Block, Sector 50, Noida ",
    faq3: "What is 3 BHK Starting Price? ",
    ans3: "3 BHK flat of Size 2548 Sq.Ft.Starts from Rs. 2.5 Cr onwards",
    faq4: "How many flat units available in Ambience Tiverton ?",
    ans4: "284 flats are available in the project",
    faq5: "Does Ambience Tiverton has kids play area?",
    ans5: "Yes, there is kids playing area available",
    faq6: "What is the RERA no. of Ambience Tiverton ?",
    ans6: "Ambience Tiverton RERA NO. UPRERAPRJ4249",
    faq7: "What is the Possession date of Ambience Tiverton ?",
    ans7: "Expected Possession date is around August 2023 ",
    faq8: "Is this Project is Bank Approved ?",
    ans8: "Yes, Ambience Tiverton is Approved by SBI Bank and HDFC Bank and many more.",
    faq9: "From where I can download Ambience Tiverton Brochure ?",
    ans9: "You can download the brochure from the website. Download brochure link:",
    faq10: "Does Ambience Tiverton  have Penthouse ?",
    ans10: "Yes, the Project has Penthouse.",
    floorliving: "Marble Granite Tiles",
    floormaster: "Marble Granite Tiles",
    floorother: "Marble Granite Tiles",
    fittingtoilet: "CP fittings",
    fittingkitchen: "Double bowl stainless steel sink",
    Doors: "Flush Door",
    interior: "Plastic Emulsion Paint",
    exterior: "Texture Paint",
    kitchen: "Ceramic Tiles Dado"
  },
  {
    id: 'HT3',
    propertyname: "Godrej Woods",
    logo: "https://i.imgur.com/kyQGRyO.png",
    modal: publicUrl + "assets/img/modal/hunting.jpg",
    img: publicUrl + "assets/img/banner/woods.webp",
    floorPlan11: publicUrl + "assets/img/FloorPlan/woods/1.jpg",
    floorPlan12: publicUrl + "assets/img/FloorPlan/woods/0.jpg",
    floorPlan21: publicUrl + "assets/img/FloorPlan/woods/2.jpg",
    floorPlan22: publicUrl + "assets/img/FloorPlan/woods/0.jpg",
    floorPlan23: publicUrl + "assets/img/FloorPlan/woods/0.jpg",
    floorPlan31: publicUrl + "assets/img/FloorPlan/woods/3.jpg",
    floorPlan32: publicUrl + "assets/img/FloorPlan/woods/0.jpg",
    floorPlan41: publicUrl + "assets/img/FloorPlan/woods/0.jpg",
    pic1: publicUrl + "assets/img/Gallerynew/woods/1.webp",
    pic2: publicUrl + "assets/img/Gallerynew/woods/2.webp",
    pic3: publicUrl + "assets/img/Gallerynew/woods/3.webp",
    pic4: publicUrl + "assets/img/Gallerynew/woods/4.webp",
    pic5: publicUrl + "assets/img/Gallerynew/woods/5.webp",
    pic6: publicUrl + "assets/img/Gallerynew/woods/6.webp",
    siteplan: publicUrl + "assets/img/SitePlan/woods.png",
    plan1: "2 BHK",
    plan2: "3 BHK",
    plan3: "4 BHK",
    plan4: "5 BHK",
    superareaplan11: "1053 Sq.Ft.",
    superareaplan12: "1255 Sq.Ft.",
    superareaplan21: "1544 Sq.Ft.",
    superareaplan22: "2088/2055 Sq.Ft.",
    superareaplan23: "2563/2570 Sq.Ft.",
    superareaplan31: "3131 Sq.Ft.",
    superareaplan32: "3360 Sq.Ft.",
    superareaplan41: "3763 Sq.Ft.",
    Noofparking: "2000+",
    constructionType: "Mivan",
    parkingLevel: "2",
    clubhousesize: "25000 sq.ft",
    podium: "Podium",
    lift: "2",
    floorvsflats: "4",
    price: "1.64 Cr- 5.52 Cr ",
    projectArea: "11 Acres",
    address1: "Sector 43, Noida",
    address2: "Block A, Sector 43, Noida, Uttar Pradesh 201303",
    RERANO: "UPRERAPRJ704730",
    status: "Under Construction",
    launchDate: "May 2021",
    possessionDate: "Ready to Move",
    sizes: "1053 - 3763 Sq.ft.",
    launchDate: "Jan, 2021",
    totalTowers: "10 Towers",
    configurations: "2BHK, 3BHK, 4BHK, 5BHK",
    location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.3666738551206!2d77.34894121549411!3d28.558750094166488!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5d5046eee85%3A0x9ed7749be19b1b56!2sGodrej%20Woods%2C%20Sector%2043%2C%20Noida!5e0!3m2!1sen!2sin!4v1674031636046!5m2!1sen!2sin",
    totalFlats: "1245",
    greenArea: "70%",
    totalFloors: "T(9-10)-G+34,T(1-8)-G+27",
    ceilingHeight: "10 Ft",
    mainDoorHeight: "8 Ft.",
    internalDoorHeight: "8 Ft.",
    text: "Godrej Woods is a residential project by Godrej Properties located in Sector 43, Noida. The project offers luxurious 2, 3, and 4 BHK apartments with world-class amenities such as a clubhouse, swimming pool, gymnasium, children's play area, and landscaped gardens. The apartments are spacious and well-designed, offering residents a comfortable living experience. The project also features 24/7 security and surveillance systems, ensuring the safety of residents.Godrej Woods is strategically located and offers easy access to major landmarks such as schools, hospitals, shopping centers, and entertainment zones. The project is also well-connected to other parts of the city via major highways and roads, making it an ideal choice for those who need to commute regularly.The project is built with a focus on sustainability and environmental responsibility, with features such as rainwater harvesting, solar power generation, and waste management systems. This makes it a great choice for those who are conscious about the environment and want to contribute to a greener future.Overall, Godrej Woods is a great choice for those seeking a luxurious and comfortable living experience in Noida, with world-class amenities, convenient location, and a focus on sustainability.",
    text1: "Godrej Woods is a premium residential project located in Sector 43, Noida, offering luxurious 2, 3, and 4 BHK apartments with top-notch amenities such as a clubhouse, swimming pool, gymnasium, and landscaped gardens. The apartments are designed with modern architecture and spacious interiors, providing a comfortable living experience. The project is strategically located with easy access to major landmarks and is well-connected to other parts of the city via major highways and roads. It also incorporates sustainable features like rainwater harvesting, solar power generation, and waste management systems, making it an ideal choice for those who are environmentally conscious. With its prime location, luxurious amenities, and sustainable features, Godrej Woods is a great choice for those looking for a premium living experience in Noida.",
    video: "https://www.youtube.com/watch?v=z0avNg3bDks",
    para1: "Godrej Woods is a luxurious residential project located in Sector 43, Noida, Uttar Pradesh. Developed by Godrej Properties, this project offers well-designed 2 BHK, 3 BHK, and 4 BHK apartments that cater to the needs and preferences of modern-day families.",
    para2: "The apartments at Godrej Woods are spacious and well-ventilated, with sizes ranging from 1350 square feet to 2650 square feet. The apartments are designed to provide maximum natural light and ventilation and come equipped with a range of modern amenities and facilities, including air conditioning, modular kitchens, high-speed elevators, and 24/7 security services.",
    para3: "Godrej Woods also offers a range of recreational and lifestyle facilities, including a swimming pool, a gymnasium, a club house, a children's play area, and a dedicated sports area. The project also features beautifully landscaped gardens and green spaces that provide a serene and peaceful environment for residents to relax and unwind.",
    para4: "Located in Sector 43, Noida, Godrej Woods enjoys excellent connectivity to major business and commercial hubs in Noida and Delhi. The project is well-connected to major roads and highways, including the Noida-Greater Noida Expressway, the Yamuna Expressway, and the FNG Expressway. It is also located close to major metro stations and bus stops, making it easy for residents to commute to different parts of the city.",
    para5: "Overall, Godrej Woods is a great investment for homebuyers looking for a luxurious and comfortable living experience in the bustling city of Noida. With its range of modern amenities and facilities, excellent connectivity, and serene environment, Godrej Woods is the perfect place to call home.",
    faq1: "How far is Godrej Woods from nearest metro station ?",
    ans1: "Godrej Woods is 1.07 Km from the nearest metro station.",
    faq2: "How is Sector 43 in terms of safety and security ?",
    ans2: "Residents have rated this locality 5/5 on safety. This will help you in making the right decision if you are worried about the safety in this locality.",
    faq3: "Are there any sports amenities in Godrej Woods? ",
    ans3: "Godrej Woods has several gaming amenities such as Swimming Pool and Ches",
    faq4: "What are the safety features of Godrej Woods ?",
    ans4: "Godrej Woods has 24x7 Security and Security Cabin to ensure all the residents feel safe and secure.",
    faq5: "How far is Godrej Woods from airport?",
    ans5: "Godrej Woods is 27.9 Km away from airport.",
    faq6: "Are there are good hospitals in the vicinity of Godrej Woods?",
    ans6: "GYes, there are good hospitals in close vicinity of Godrej Woods such as Pragya Clinic Gautam Buddha Nagar, Ojjus Hospital and pet's doctor.",
    faq7: "What is the Possession date ofGodrej Woods  ?",
    ans7: "Expected Possession date is around August 2023 ",
    faq8: "what is 2 BHK Starting Price? ",
    ans8: "2 BHK flat of Size 1272 Sq.Ft. Starts from₹ 1.76 - 2.23 Cr + Govt. Charges",
    faq9: "Is this Project is Bank Approved ?",
    ans9: "Yes, Godrej Woods  is Approved by SBI Bank and HDFC Bank and many more.",
    faq10: "Does Godrej Woods has kids play area ?",
    ans10: "Yes, there is kids playing area available.",
    floorliving: "Vitrified Tiles",
    floormaster: "Vitrified Tiles",
    floorother: "Vitrified Tiles",
    fittingtoilet: "hot and cold water mixer",
    fittingkitchen: "Granite platform with stainless steel sink",
    Doors: "UPVC/Powder Coated or Anodized Aluminium Door",
    interior: "Emulsion Paint ",
    exterior: "Superior Paint Finish",
    kitchen: "Designer Tiles Dado up to 2 Feet Height Above Platform"
  },
  {
    id: 'HT4',
    propertyname: "Trident Reso",
    logo: "https://i.imgur.com/NruMeHE.png",
    img: publicUrl + "assets/img/banner/tridentembassy.webp",
    floorPlan11: publicUrl + "assets/img/FloorPlan/Trident/1.jpg",
    floorPlan12: publicUrl + "assets/img/FloorPlan/Trident/0.jpg",
    floorPlan21: publicUrl + "assets/img/FloorPlan/Trident/2.jpg",
    floorPlan22: publicUrl + "assets/img/FloorPlan/Trident/0.jpg",
    floorPlan23: publicUrl + "assets/img/FloorPlan/Trident/0.jpg",
    floorPlan24: publicUrl + "assets/img/FloorPlan/Trident/0.jpg",
    floorPlan31: publicUrl + "assets/img/FloorPlan/Trident/3.jpg",
    floorPlan31: publicUrl + "assets/img/FloorPlan/Trident/0.jpg",
    pic1: publicUrl + "assets/img/Gallerynew/reso/1.webp",
    pic2: publicUrl + "assets/img/Gallerynew/reso/2.webp",
    pic3: publicUrl + "assets/img/Gallerynew/reso/3.webp",
    pic4: publicUrl + "assets/img/Gallerynew/reso/4.webp",
    pic5: publicUrl + "assets/img/Gallerynew/reso/5.webp",
    pic6: publicUrl + "assets/img/Gallerynew/reso/6.webp",
    modal: publicUrl + "assets/img/modal/reso.jpg",
    siteplan: publicUrl + "assets/img/SitePlan/reso.png",
    plan1: "2 BHK",
    plan2: "3 BHK",
    plan3: "4 BHK",
    superareaplan11: "1272 Sq.Ft.",
    superareaplan12: "1287 Sq.Ft.",
    superareaplan21: "1375 Sq.Ft.",
    superareaplan22: "1405 Sq.Ft.",
    superareaplan23: "1625 Sq.Ft.",
    superareaplan24: "1655 Sq.Ft.",
    superareaplan31: "1892 Sq.Ft.",
    superareaplan32: "1922 Sq.Ft.",
    Noofparking: "810+",
    parkingLevel: "3",
    clubhousesize: "14000 sq.ft",
    podium: " Podium",
    lift: "3",
    floorvsflats: "T(1,5)-4 T(2,3,4)-6",
    price: "73 Lakhs- 1.2 Cr ",
    address1: "Greater Noida West",
    address2: "Plot No GH 05B, Sector 1, Noida Extension, Greater Noida West, UP",
    RERANO: "UPRERAPRJ785019",
    constructionType: "Mivan",
    status: "Under Construction",
    launchDate: "Jan 2021",
    projectType: "Residential",
    projectArea: "3.25 Acres",
    sizes: "1272 - 1922 Sq.ft.",
    launchDate: "Feb, 2021",
    possessionDate: "Dec, 2025",
    totalTowers: "5 Towers",
    totalFloors: "22 Floors",
    configurations: "2BHK, 3BHK, 4BHK ",
    location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.0129681049725!2d77.43277193488767!3d28.56937330000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ceec4a05c4e9b%3A0x7696629e81633ef!2sTRIDENT%20EMBASSY%20RESO!5e0!3m2!1sen!2sin!4v1674031721198!5m2!1sen!2sin",
    totalFlats: "572",
    greenArea: "70%",
    totalFloors: "22",
    ceilingHeight: "10 Ft.",
    mainDoorHeight: "9 Ft.",
    internalDoorHeight: "7 Ft.",
    para1: "Trident Embassy Reso is a premium residential project located in Sector 1, Greater Noida West. Developed by Trident Realty, this project offers luxurious and comfortable living spaces that cater to the needs and preferences of modern-day families.",
    para2: "The apartments at Trident Embassy Reso are well-designed and come in 2 BHK, 3 BHK, and 4 BHK configurations, with sizes ranging from 990 square feet to 2285 square feet. The apartments are spacious and well-ventilated and come equipped with a range of modern amenities and facilities, including air conditioning, modular kitchens, high-speed elevators, and 24/7 security services.",
    para3: "Trident Embassy Reso also offers a wide range of recreational and lifestyle facilities, including a swimming pool, a gymnasium, a club house, a children's play area, and a dedicated sports area. The project also features beautifully landscaped gardens and green spaces that provide a serene and peaceful environment for residents to relax and unwind.",
    para4: "Located in Sector 1, Greater Noida West, Trident Embassy Reso enjoys excellent connectivity to major business and commercial hubs in Noida and Delhi. The project is well-connected to major roads and highways, including the Noida-Greater Noida Expressway, the Yamuna Expressway, and the FNG Expressway. It is also located close to major metro stations and bus stops, making it easy for residents to commute to different parts of the city.",
    para5: "Overall, Trident Embassy Reso is a great investment for homebuyers looking for a luxurious and comfortable living experience in Greater Noida West. With its range of modern amenities and facilities, excellent connectivity, and serene environment, Trident Embassy Reso is the perfect place to call home.",
    text1: "Trident Embassy Reso is a premium residential project located in Sector 1, Greater Noida West, offering luxurious 2, 3, and 4 BHK apartments with world-class amenities such as a clubhouse, swimming pool, gymnasium, and landscaped gardens. The apartments are designed with modern architecture and spacious interiors, providing a comfortable and elegant living experience. The project is located in a prime location with easy access to major landmarks and is well-connected to other parts of the city via major highways and roads. It also incorporates sustainable features like rainwater harvesting, solar power generation, and waste management systems, making it an ideal choice for those who are environmentally conscious. With its prime location, luxurious amenities, and sustainable features, Trident Embassy Reso is an excellent choice for those looking for a premium living experience in Greater Noida West.",
    faq1: "Why to choose Trident Reso ?",
    ans1: "Posh location,Excellent Connectivity",
    faq2: "What is Trident Reso Address?",
    ans2: "Plot No GH 05B, Sector 1, Noida Extension, Greater Noida West, UP",
    faq3: "What is 2 BHK Starting Price? ",
    ans3: "2 BHK flat of Size 1272 - 1287 sq.ft. Starts from 1.08 - 1.14 Cr+ Govt. Charges",
    faq4: "How many flat units available in Trident Reso ?",
    ans4: "572 flats are available in the project",
    faq5: "Does Trident Reso has kids play area?",
    ans5: "Yes, there is kids playing area available",
    faq6: "What is the RERA no. of Trident Reso?",
    ans6: "Trident Reso RERA NO.UPRERAPRJ785019 ",
    faq7: "What is the Possession date of Trident Reso  ?",
    ans7: "Expected Possession date is around Dec, 2025 ",
    faq8: "Which is the nearby metro station from Trident Embassy Reso ?",
    ans8: "Noida Sector 101 Metro Station is the nearest metro station from Trident Embassy Reso.",
    faq9: "Are there any safety related concerns in Sector 1 Greater Noida West?",
    ans9: "Former and existing residents have rated this locality 4/5 on safety. This means, this is one of the good localities where safety is not a concern.   ",
    faq10: "What are the security features of Trident Embassy Reso ?",
    ans10: "Trident Embassy Reso offers Video Door Security, 24x7 Security, Security Cabin and InterCom to ensure all the residents feel safe and secure.",
    floorliving: "Vitrified Tiles",
    floormaster: "Vitrified Tiles",
    floorother: "Vitrified Tiles",
    fittingtoilet: "Provision for Geyser, Standard Chinaware Fixture",
    fittingkitchen: "Granite platform with stainless steel sink",
    Doors: "UPVC/Powder Coated or Anodized Aluminium Door",
    interior: "Emulsion Paint ",
    exterior: "Superior Paint Finish",
    kitchen: "Designer Ceramic Tiles above the Centre Level"
  }
  , {
    id: 'HT5',
    propertyname: "Ace Startlit",
    img: publicUrl + "assets/img/banner/Star.jpg",
    logo: "https://i.imgur.com/fLlQilH.png",
    modal: publicUrl + "assets/img/modal/hunting.jpg",
    floorPlan11: publicUrl + "assets/img/FloorPlan/Starlit/1.jpg",
    floorPlan21: publicUrl + "assets/img/FloorPlan/Starlit/2.jpg",
    pic1: publicUrl + "assets/img/Gallerynew/starlit/1.webp",
    pic2: publicUrl + "assets/img/Gallerynew/starlit/2.webp",
    pic3: publicUrl + "assets/img/Gallerynew/starlit/3.webp",
    pic4: publicUrl + "assets/img/Gallerynew/starlit/4.webp",
    pic5: publicUrl + "assets/img/Gallerynew/starlit/5.webp",
    pic6: publicUrl + "assets/img/Gallerynew/starlit/6.webp",
    siteplan: publicUrl + "assets/img/SitePlan/starlit.png",
    plan1: "2 BHK",
    plan2: "3 BHK",
    superareaplan11: "1350 Sq.Ft.",
    superareaplan21: "1775 Sq.Ft.",
    Noofparking: "1500+",
    parkingLevel: "2",
    constructionType: "Mivan",
    clubhousesize: "100000  sq.ft",
    podium: "Non-Podium",
    lift: "2 & 3",
    floorvsflats: "6/4",
    price: "1 Cr- 1.4 Cr ",
    address1: "Sector 152, Noida",
    address2: "SC-01/09, Sector 152, Noida, Uttar Pradesh 201303",
    RERANO: "UPRERAPRJ677294",
    status: "Under Construction",
    projectType: "Residential",
    projectArea: "7 Acres",
    sizes: "1350 - 1775 Sq.ft.",
    projectUnits: "496 Flats",
    launchDate: "May 2021",
    possessionDate: "April 2025",
    totalTowers: "5 Towers",
    totalFloors: "28",
    projectStatus: "Under Construction",
    configurations: "2BHK, 3BHK",
    location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.052801603713!2d77.46963881829703!3d28.447824651381527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cc1e91a98d78b%3A0xa19520e37654e5d5!2sACE%20Starlit!5e0!3m2!1sen!2sin!4v1679739244588!5m2!1sen!2sin",
    totalFlats: "492",
    greenArea: "70%",
    parkingLevel: "",
    totalFloors: "",
    ceilingHeight: "10 Ft.",
    mainDoorHeight: "9 Ft.",
    internalDoorHeight: "7 Ft.",
    para1: "Ace Starlit is a premium residential project located in Sector 152, Noida, Uttar Pradesh. Developed by Ace Group, this project offers luxurious and comfortable living spaces that cater to the needs and preferences of modern-day families.",
    para2: "The apartments at Ace Starlit are well-designed and come in 2 BHK, 3 BHK, and 4 BHK configurations, with sizes ranging from 1085 square feet to 3220 square feet. The apartments are spacious and well-ventilated, and come equipped with a range of modern amenities and facilities, including air conditioning, modular kitchens, high-speed elevators, and 24/7 security services.",
    para3: "Ace Starlit also offers a range of recreational and lifestyle facilities, including a swimming pool, a gymnasium, a club house, a children's play area, and a dedicated sports area. The project also features beautifully landscaped gardens and green spaces that provide a serene and peaceful environment for residents to relax and unwind.",
    para4: "Located in Sector 152, Noida, Ace Starlit enjoys excellent connectivity to major business and commercial hubs in Noida and Delhi. The project is well-connected to major roads and highways, including the Noida-Greater Noida Expressway, the Yamuna Expressway, and the FNG Expressway. It is also located close to major metro stations and bus stops, making it easy for residents to commute to different parts of the city.",
    para5: "Overall, Ace Starlit is a great investment for homebuyers looking for a luxurious and comfortable living experience in the bustling city of Noida. With its range of modern amenities and facilities, excellent connectivity, and serene environment, Ace Starlit is the perfect place to call home.",
    text1: "Ace Starlit is a premium residential project located in Sector 152, Noida, offering luxurious 2, 3, and 4 BHK apartments with world-class amenities such as a clubhouse, swimming pool, gymnasium, and landscaped gardens. The apartments are designed with modern architecture and spacious interiors, providing a comfortable and elegant living experience. The project is located in a prime location with easy access to major landmarks and is well-connected to other parts of the city via major highways and roads. It also incorporates sustainable features like rainwater harvesting, solar power generation, and waste management systems, making it an ideal choice for those who are environmentally conscious. With its prime location, luxurious amenities, and sustainable features, Ace Starlit is an excellent choice for those looking for a premium living experience in Noida.",
    faq1: "Why to choose Ace Startlit ?",
    ans1: "Posh location,Excellent Connectivity",
    faq2: "What is Ace Startlit Address?",
    ans2: "SC-01/09, Sector 152, Noida",
    faq3: "What is 2 BHK Starting Price? ",
    ans3: "2 BHK flat of Size 1350 Sq.Ft. sq.ft Starts from ₹ 1 Cr + Govt. Charges",
    faq4: "How many flat units available in Ace Startlit ?",
    ans4: "492 flats are available in the project",
    faq5: "Does Ace Startlit has kids play area?",
    ans5: "Yes, there is kids playing area available",
    faq6: "What is the RERA no. of Ace Startlit ?",
    ans6: "Ace Startlit RERA NO.UPRERAPRJ677294",
    faq7: "What is the Possession date of Ace Startlit ?",
    ans7: "Expected Possession date is around April 2025 ",
    faq8: "Is this Project is Bank Approved ?",
    ans8: "Yes, Ace Startlit is Approved by SBI Bank and HDFC Bank and many more.",
    faq9: "From where I can download Ace Startlit Brochure ?",
    ans9: "You can download the brochure from the website. Download brochure link:",
    faq10: "Does Ace Startlit have Penthouse ?",
    ans10: "No, the Project does not have Penthouse.",
    floorliving: "Vitrified Tiles",
    floormaster: "Vitrified Tiles",
    floorother: "Vitrified Tiles",
    fittingtoilet: "hot and cold water mixer",
    fittingkitchen: "Granite platform with stainless steel sink",
    Doors: "UPVC/Powder Coated or Anodized Aluminium Door",
    interior: "Emulsion Paint ",
    exterior: "Superior Paint Finish",
    kitchen: "Designer Tiles Dado up to 2 Feet Height Above Platform"

  },
  {
    id: 'HT6',
    propertyname: "Kalpataru Vista ",
    logo: "https://i.imgur.com/heCGt8u.png",
    modal: publicUrl + "assets/img/modal/hunting.jpg",
    img: publicUrl + "assets/img/banner/kalpataruvista.webp",
    floorPlan11: publicUrl + "assets/img/FloorPlan/Vista/1.jpg",
    floorPlan21: publicUrl + "assets/img/FloorPlan/Vista/2.jpg",
    floorPlan22: publicUrl + "assets/img/FloorPlan/Vista/0.jpg",
    pic1: publicUrl + "assets/img/Gallerynew/Kalpatru/1.webp",
    pic2: publicUrl + "assets/img/Gallerynew/Kalpatru/2.webp",
    pic3: publicUrl + "assets/img/Gallerynew/Kalpatru/3.webp",
    pic4: publicUrl + "assets/img/Gallerynew/Kalpatru/4.webp",
    pic5: publicUrl + "assets/img/Gallerynew/Kalpatru/5.webp",
    pic6: publicUrl + "assets/img/Gallerynew/Kalpatru/6.webp",
    siteplan: publicUrl + "assets/img/SitePlan/vista.png",
    plan1: "2 BHK",
    plan2: "4 BHK",
    superareaplan11: "3047 Sq.Ft.",
    superareaplan21: "4145 Sq.Ft.",
    superareaplan22: "3995 Sq.Ft.",
    Noofparking: "250+",
    parkingLevel: "2",
    clubhousesize: "25000 Sq.Ft",
    constructionType: "Mivan",
    podium: "Podium",
    lift: "3",
    floorvsflats: "2",
    price: "3.4 Cr- 4.7 Cr ",
    address1: "Sector 128 Noida",
    address2: "Kalpataru Vista, Plot B 24 C Wish Town, Sector 128 Noida, Uttar Pradesh 201304",
    RERANO: " UPRERAPRJ14980",
    projectType: "Residential",
    projectArea: "3 Acres",
    sizes: "3011 - 4145 Sq.ft.",
    launchDate: "May, 2018",
    possessionDate: "April 2024",
    totalTowers: "2",
    totalFloors: "34 Floors",
    status: "Under Construction",
    configurations: "3BHK, 4BHK",
    location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.655759661275!2d77.35810201455693!3d28.520002995917014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce7cfaa61f5b7%3A0xeee1789eb2eac629!2sKalpataru%20Vista!5e0!3m2!1sen!2sin!4v1679739330826!5m2!1sen!2sin",
    totalFlats: "250 Flats",
    greenArea: "78%",
    ceilingHeight: "10 Ft.",
    mainDoorHeight: "9 Ft.",
    internalDoorHeight: "7 Ft.",
    para1: "Kalpataru Vista is a luxurious residential project located in Sector 128, Noida, Uttar Pradesh. Developed by Kalpataru Group, this project offers premium living spaces that cater to the needs and preferences of modern-day families.",
    para2: "The apartments at Kalpataru Vista are well-designed and come in 3 BHK and 4 BHK configurations, with sizes ranging from 2007 square feet to 2763 square feet. The apartments are spacious and well-ventilated, and come equipped with a range of modern amenities and facilities, including air conditioning, modular kitchens, high-speed elevators, and 24/7 security services.",
    para3: "Kalpataru Vista also offers a range of recreational and lifestyle facilities, including a swimming pool, a gymnasium, a club house, a children's play area, and a dedicated sports area. The project also features beautifully landscaped gardens and green spaces that provide a serene and peaceful environment for residents to relax and unwind.",
    para4: "Located in Sector 128, Noida, Kalpataru Vista enjoys excellent connectivity to major business and commercial hubs in Noida and Delhi. The project is well-connected to major roads and highways, including the Noida-Greater Noida Expressway, the Yamuna Expressway, and the FNG Expressway. It is also located close to major metro stations and bus stops, making it easy for residents to commute to different parts of the city.",
    para5: "Overall, Kalpataru Vista is a great investment for homebuyers looking for a luxurious and comfortable living experience in the bustling city of Noida. With its range of modern amenities and facilities, excellent connectivity, and serene environment, Kalpataru Vista is the perfect place to call home.",
    text1: "Kalpataru Vista is a luxurious residential project located in Sector 128, Noida. The project offers premium 3 and 4 BHK apartments with contemporary design and world-class amenities such as a clubhouse, swimming pool, gymnasium, and landscaped gardens. The apartments are designed to provide a comfortable and luxurious living experience with spacious interiors and elegant finishes. The project is located in a prime location with excellent connectivity to major landmarks and is well-connected to other parts of the city via major highways and roads. It also incorporates sustainable features like rainwater harvesting, solar power generation, and waste management systems, making it an ideal choice for those who are environmentally conscious. With its prime location, luxurious amenities, and sustainable features, Kalpataru Vista is an excellent choice for those looking for a premium living experience in Noida.",
    faq1: "Why to choose Kalpataru Vista ?",
    ans1: "Posh location,Excellent Connectivity",
    faq2: "What is Kalpataru Vista Address?",
    ans2: "Kalpataru Vista, Plot B 24 C Wish Town, Sector 128 Noida ",
    faq3: "What is 3 BHK Apartment Starting Price? ",
    ans3: "3 BHK flat of Size 1608 - 3000 sq.ft Starts from ₹ 4.4 - 8.58 Cr + Govt. Charges",
    faq4: "How many flat units available in Kalpataru Vista ?",
    ans4: "1748 flats are available in the project",
    faq5: "Does Kalpataru Vista has kids play area?",
    ans5: "Yes, there is kids playing area available",
    faq6: "What is the RERA no. of Kalpataru Vista ?",
    ans6: "Kalpataru Vista RERA NO. UPRERAPRJ14980",
    faq7: "What is the Possession date of Kalpataru Vista ?",
    ans7: "Expected Possession date is around June 2024 ",
    faq8: "Is this Project is Bank Approved ?",
    ans8: "Yes, Kalpataru Vista is Approved by SBI Bank and HDFC Bank and many more.",
    faq9: "From where I can download Kalpataru Vista Brochure ?",
    ans9: "You can download the brochure from the website. Download brochure link:",
    faq10: "What are the security features of Kalpataru Vista ?",
    ans10: "Kalpataru Vista offers Video Door Security, 24x7 Security, Security Cabin and InterCom to ensure all the residents feel safe and secure.",
    floorliving: "Vitrified Tiles",
    floormaster: "Vitrified Tiles",
    floorother: "Vitrified Tiles",
    fittingtoilet: "hot and cold water mixer",
    fittingkitchen: "Granite platform with stainless steel sink",
    Doors: "UPVC/Powder Coated or Anodized Aluminium Door",
    interior: "Emulsion Paint ",
    exterior: "Superior Paint Finish",
    kitchen: "Designer Tiles Dado up to 2 Feet Height Above Platform"
  },
  {
    id: 'HT7',
    propertyname: "Parx Laureate",
    img: publicUrl + "assets/img/banner/parx.jpg",
    modal: publicUrl + "assets/img/modal/parx.jpg",
    logo: "https://i.imgur.com/kjYdfkt.png",
    floorPlan11: publicUrl + "assets/img/FloorPlan/Parx/1.png",
    floorPlan12: publicUrl + "assets/img/FloorPlan/Parx/2.png",
    floorPlan21: publicUrl + "assets/img/FloorPlan/Parx/3.png",
    floorPlan22: publicUrl + "assets/img/FloorPlan/Parx/4.png",
    floorPlan31: publicUrl + "assets/img/FloorPlan/Parx/5.png",
    floorPlan32: publicUrl + "assets/img/FloorPlan/Parx/6.png",
    floorPlan33: publicUrl + "assets/img/FloorPlan/Parx/7.png",
    pic1: publicUrl + "assets/img/Gallerynew/Parx/1.webp",
    pic2: publicUrl + "assets/img/Gallerynew/Parx/2.webp",
    pic3: publicUrl + "assets/img/Gallerynew/Parx/3.webp",
    pic4: publicUrl + "assets/img/Gallerynew/Parx/4.webp",
    pic5: publicUrl + "assets/img/Gallerynew/Parx/5.webp",
    pic6: publicUrl + "assets/img/Gallerynew/Parx/6.webp",
    siteplan: publicUrl + "assets/img/SitePlan/parx.png",
    plan1: "3 BHK",
    plan2: "4 BHK",
    plan3: "PentHouse ",
    superareaplan11: "3195 Sq.Ft.",
    superareaplan12: "3205 Sq.Ft.",
    superareaplan21: "3850 Sq.Ft.",
    superareaplan22: "4545 Sq.Ft.",
    superareaplan31: "4730 Sq.Ft.",
    superareaplan32: "5220 Sq.Ft.",
    superareaplan33: "5930 Sq.Ft.",
    Noofparking: "1200+",
    parkingLevel: "2",
    clubhousesize: "20000 + 75000 Sq.Ft",
    constructionType: "Mivan",
    podium: "Non-Podium",
    lift: "3",
    floorvsflats: "3",
    price: "3.3 Cr- 4.8 Cr ",
    address1: "Sector 108, Noida",
    address2: "H-01 Sector 108, Noida Expressway, Noida, 201304.",
    RERANO: " UPRERAPRJ5998",
    status: "Ready to Move",
    launchDate: "March 2018",
    possessionDate: "Ready to Move",
    projectType: "Residential",
    projectArea: "11 Acres",
    sizes: "3302- 4874 Sq.ft.",
    totalTowers: "9 Towers",
    totalFloors: "21 Floors",
    configurations: "3BHK, 4BHK",
    location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7011.020716489376!2d77.37025807334001!3d28.524375856372878!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce618d3158e8d%3A0x6b798b0b2bfa005b!2sParx%20Laureate%2C%20Sector%20108%2C%20Noida%2C%20Uttar%20Pradesh!5e0!3m2!1sen!2sin!4v1679739378623!5m2!1sen!2sin",
    totalFlats: "560 Flats",
    greenArea: "78%",
    ceilingHeight: "10.5 Ft.",
    mainDoorHeight: "8 Ft.",
    internalDoorHeight: "8 Ft.",
    para1: "Parx Laureate is a premium residential project located in Sector 108, Noida, Uttar Pradesh. Developed by Laureate Buildwell Pvt. Ltd., this project offers luxurious and comfortable living spaces that cater to the needs and preferences of modern-day families.",
    para2: "The apartments at Parx Laureate are well-designed and come in 3 BHK and 4 BHK configurations, with sizes ranging from 3195 square feet to 3800 square feet. The apartments are spacious and well-ventilated, and come equipped with a range of modern amenities and facilities, including air conditioning, modular kitchens, high-speed elevators, and 24/7 security services.",
    para3: "Parx Laureate also offers a range of recreational and lifestyle facilities, including a swimming pool, a gymnasium, a club house, a children's play area, and a dedicated sports area. The project also features beautifully landscaped gardens and green spaces that provide a serene and peaceful environment for residents to relax and unwind.",
    para4: "Located in Sector 108, Noida, Parx Laureate enjoys excellent connectivity to major business and commercial hubs in Noida and Delhi. The project is well-connected to major roads and highways, including the Noida-Greater Noida Expressway, the Yamuna Expressway, and the FNG Expressway. It is also located close to major metro stations and bus stops, making it easy for residents to commute to different parts of the city.",
    para5: "Overall, Parx Laureate is a great investment for homebuyers looking for a luxurious and comfortable living experience in the bustling city of Noida. With its range of modern amenities and facilities, excellent connectivity, and serene environment, Parx Laureate is the perfect place to call home.",
    text1: "Parx Laureate is a premium residential project located in Sector 108, Noida. The project offers luxurious 3 and 4 BHK apartments with contemporary design and world-class amenities such as a clubhouse, swimming pool, gymnasium, and landscaped gardens. The apartments are designed to provide a comfortable and luxurious living experience with spacious interiors and elegant finishes. The project is located in a prime location with excellent connectivity to major landmarks and is well-connected to other parts of the city via major highways and roads. It also incorporates sustainable features like rainwater harvesting, solar power generation, and waste management systems, making it an ideal choice for those who are environmentally conscious. With its prime location, luxurious amenities, and sustainable features, Parx Laureate is an excellent choice for those looking for a premium living experience in Noida.",
    faq1: "Why to choose Parx Laureate ?",
    ans1: "Posh location,Excellent Connectivity",
    faq2: "What is Parx Laureate Address?",
    ans2: "H-01 Sector 108, Noida Expressway, Noida ",
    faq3: "What is 3 BHK Starting Price? ",
    ans3: "3 BHK flat of Size 3302 Sq. ft. Starts from Rs. 3.3 Cr onwards",
    faq4: "How many flat units available in Parx Laureate ?",
    ans4: "560  flats are available in the project",
    faq5: "Does Parx Laureate has kids play area?",
    ans5: "Yes, there is kids playing area available",
    faq6: "What is the RERA no. of Parx Laureate ?",
    ans6: "Parx Laureate RERA NO. UPRERAPRJ5998",
    faq7: "What is the Possession date of Parx Laureate ?",
    ans7: "Parx Laureate is Ready to Move ",
    faq8: "Is this Project is Bank Approved ?",
    ans8: "Yes, Parx Laureate is Approved by SBI Bank and HDFC Bank and many more.",
    faq9: "From where I can download Parx Laureate Brochure ?",
    ans9: "You can download the brochure from the website. Download brochure link:",
    faq10: "Does Parx Laureate have Penthouse ?",
    ans10: "Yes, the Project has Penthouse.",
    video: "https://www.youtube.com/embed/CJoKqDDMEww",
    floorliving: "Marble Granite Tiles",
    floormaster: "Wooden flooring",
    floorother: "Vitrified Tiles",
    fittingtoilet: "hot and cold water mixer",
    fittingkitchen: "Granite platform with stainless steel sink",
    Doors: "Powder Coated Aluminium Sliding",
    interior: "Emulsion Paint ",
    exterior: "Superior Paint Finish",
    kitchen: "Moduler Kitchen with Chimney & HOB"
  },
  {
    id: 'HT8',
    propertyname: "Tata Eureka",
    logo: "https://i.imgur.com/e1xDJjr.png",
    modal: publicUrl + "assets/img/modal/hunting.jpg",
    img: publicUrl + "assets/img/banner/tataeureka.jpg",
    floorPlan11: publicUrl + "assets/img/FloorPlan/Tataeureka/1.jpg",
    floorPlan21: publicUrl + "assets/img/FloorPlan/Tataeureka/1.jpg",
    floorPlan22: publicUrl + "assets/img/FloorPlan/Tataeureka/1.jpg",
    pic1: publicUrl + "assets/img/Gallerynew/TataEureka/1.webp",
    pic2: publicUrl + "assets/img/Gallerynew/TataEureka/2.webp",
    pic3: publicUrl + "assets/img/Gallerynew/TataEureka/3.webp",
    pic4: publicUrl + "assets/img/Gallerynew/TataEureka/4.webp",
    pic5: publicUrl + "assets/img/Gallerynew/TataEureka/5.webp",
    pic6: publicUrl + "assets/img/Gallerynew/TataEureka/6.webp",
    lift: "2",
    plan1: "2 BHK",
    plan2: "3 BHK",
    superareaplan11: "995 Sq.Ft.",
    superareaplan21: "1285 Sq.Ft.",
    superareaplan22: "1575 Sq.Ft.",
    siteplan: publicUrl + "assets/img/SitePlan/eureka.png",
    price: "88 Lakhs- 1.26 Cr ",
    address1: "Sector 150 Noida",
    address2: "SC-01 A1, Sector 150, Noida, Uttar Pradesh 201310",
    RERANO: "UPRERAPRJ469983",
    status: "Under Construction",
    launchDate: "May 2017",
    projectType: "Residential",
    projectArea: "20 Acres",
    sizes: "1100 - 1575 Sq.ft.",
    projectUnits: "1160 Flats",
    possessionDate: "Sep, 2023",
    totalTowers: "20 Towers",
    totalFloors: "28 Floors",
    configurations: "2BHK, 3BHK",
    constructionType: "Mivan",
    floorvsflats: "4 & 6",
    location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1754.496229486344!2d77.48419290799964!3d28.419484404594524!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cc1fd2779cc97%3A0x13b2293706a249e3!2sTata%20Eureka%20Park!5e0!3m2!1sen!2sin!4v1679739473260!5m2!1sen!2sin",
    greenArea: "80%",
    ceilingHeight: "10 Ft.",
    mainDoorHeight: "9 Ft.",
    internalDoorHeight: "7 Ft.",
    para1: "Tata Eureka Park is a premium residential project located in Sector 150, Noida, Uttar Pradesh. Developed by Tata Value Homes, this project offers luxurious and comfortable living spaces that cater to the needs and preferences of modern-day families.",
    para2: "The apartments at Tata Eureka Park are well-designed and come in 2 BHK and 3 BHK configurations, with sizes ranging from 1100 square feet to 1575 square feet. The apartments are spacious and well-ventilated, and come equipped with a range of modern amenities and facilities, including air conditioning, modular kitchens, high-speed elevators, and 24/7 security services.",
    para3: "Tata Eureka Park also offers a range of recreational and lifestyle facilities, including a swimming pool, a gymnasium, a club house, a children's play area, and a dedicated sports area. The project also features beautifully landscaped gardens and green spaces that provide a serene and peaceful environment for residents to relax and unwind.",
    para4: "Located in Sector 150, Noida, Tata Eureka Park enjoys excellent connectivity to major business and commercial hubs in Noida and Delhi. The project is well-connected to major roads and highways, including the Noida-Greater Noida Expressway, the Yamuna Expressway, and the FNG Expressway. It is also located close to major metro stations and bus stops, making it easy for residents to commute to different parts of the city.",
    para5: "Overall, Tata Eureka Park is a great investment for homebuyers looking for a luxurious and comfortable living experience in the bustling city of Noida. With its range of modern amenities and facilities, excellent connectivity, and serene environment, Tata Eureka Park is the perfect place to call home.",
    text1: "Tata Eureka Park is a premium residential project located in Sector 150, Noida. Spread across 20 acres, the project offers 2 and 3 BHK apartments in high-rise towers with contemporary design and world-class amenities. The apartments are designed to provide a comfortable and luxurious living experience with spacious interiors and elegant finishes. The project also incorporates several green features like rainwater harvesting, solar power generation, and waste management systems, making it an ideal choice for those who are environmentally conscious. The project is located in a prime location with excellent connectivity to major landmarks and is well-connected to other parts of the city via major highways and roads. With its prime location, luxurious amenities, and sustainable features, Tata Eureka Park is an excellent choice for those looking for a premium living experience in Noida.",
    faq1: "Why to choose Tata Eureka?",
    ans1: "Posh location,Excellent Connectivity",
    faq2: "What is Tata Eureka Address?",
    ans2: "SC-01 A1, Sector 150, Noida",
    faq3: "What is 2 BHK Starting Price? ",
    ans3: "2 BHK flat of Size 995 Sq. ft. Starts from Rs. 88 Lakhs onwards",
    faq4: "How many flat units available in Tata Eureka ?",
    ans4: "1160 flats are available in the project",
    faq5: "Does Tata Eureka has kids play area?",
    ans5: "Yes, there is kids playing area available",
    faq6: "What is the RERA no. of Tata Eureka ?",
    ans6: "Tata EurekaRERA NO.UPRERAPRJ469983",
    faq7: "What is the Possession date of Tata Eureka  ?",
    ans7: "Expected Possession date is around March 2023 ",
    faq8: "Is this Project is Bank Approved ?",
    ans8: "Yes, Tata Eureka is Approved by SBI Bank and HDFC Bank and many more.",
    faq9: "From where I can download Tata Eureka Brochure ?",
    ans9: "You can download the brochure from the website. Download brochure link:",
    faq10: "Does Tata Eureka have Penthouse ?",
    ans10: "Yes, the Project has Penthouse.",
    floorliving: "Vitrified Tiles",
    floormaster: "Vitrified Tiles",
    floorother: "Vitrified Tiles",
    fittingtoilet: "hot and cold water mixer",
    fittingkitchen: "Granite platform with stainless steel sink",
    Doors: "UPVC/Powder Coated or Anodized Aluminium Door",
    interior: "Emulsion Paint ",
    exterior: "Superior Paint Finish",
    kitchen: "Designer Tiles Dado up to 2 Feet Height Above Platform"

  },
  {
    id: 'HT9',
    propertyname: "County 107",
    logo: "https://i.imgur.com/QyzeTlu.png",
    modal: publicUrl + "assets/img/modal/hunting.jpg",
    img: publicUrl + "assets/img/banner/county.webp",
    floorPlan11: publicUrl + "assets/img/FloorPlan/county107/1.jpg",
    floorPlan12: publicUrl + "assets/img/FloorPlan/county107/0.jpg",
    floorPlan21: publicUrl + "assets/img/FloorPlan/county107/2.jpg",
    pic1: publicUrl + "assets/img/Gallerynew/County/1.webp",
    pic2: publicUrl + "assets/img/Gallerynew/County/2.webp",
    pic3: publicUrl + "assets/img/Gallerynew/County/3.webp",
    pic4: publicUrl + "assets/img/Gallerynew/County/4.webp",
    pic5: publicUrl + "assets/img/Gallerynew/County/5.webp",
    pic6: publicUrl + "assets/img/Gallerynew/County/6.webp",
    modal: publicUrl + "assets/img/modal/107.jpg",
    siteplan: publicUrl + "assets/img/SitePlan/107.png",
    plan1: "4 BHK",
    plan2: "5 BHK",
    constructionType: "Mivan",
    superareaplan11: "3501 Sq.Ft.",
    superareaplan12: "4482 Sq.Ft.",
    superareaplan21: "6570 Sq.Ft.",
    Noofparking: "500+",
    parkingLevel: "2",
    clubhousesize: "30000 Sq.Ft",
    podium: "Non Podium",
    lift: "3",
    floorvsflats: "4",
    price: "4.9 Cr - 9.19 Cr ",
    address1: "Sector 107, Noida",
    address2: "GH 01A, B ALPHA, Sector 107, Noida, Uttar Pradesh 201301",
    RERANO: "UPRERAPRJ837374",
    status: "Under Construction",
    launchDate: "Dec 2019",
    projectArea: "5 Acres",
    totalFlats: "224 Flats",
    sizes: "3501- 6570 Sq.ft.",
    projectUnits: "245 Flats",
    possessionDate: "Dec, 2023",
    totalTowers: "4 Towers",
    totalFloors: "31 Floors",
    propertyType: "Residential",
    configurations: "4BHK, 5BHK",
    location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.7486617908994!2d77.37307671455761!3d28.547273394686542!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce555ac338307%3A0x7a7d098909002d22!2sCounty%20107!5e0!3m2!1sen!2sin!4v1679740437344!5m2!1sen!2sin",
    greenArea: "78%",
    ceilingHeight: "10.5 Ft.",
    mainDoorHeight: "8 Ft.",
    internalDoorHeight: "8 Ft.",
    para1: "County 107 is a premium residential project located in Sector 107, Noida, Uttar Pradesh. Developed by ABA Corp, this project offers luxurious and comfortable living spaces that cater to the needs and preferences of modern-day families.",
    para2: "The apartments at County 107 are well-designed and come in 3 BHK and 4 BHK configurations, with sizes ranging from 1895 square feet to 2720 square feet. The apartments are spacious and well-ventilated, and come equipped with a range of modern amenities and facilities, including air conditioning, modular kitchens, high-speed elevators, and 24/7 security services.",
    para3: "County 107 also offers a range of recreational and lifestyle facilities, including a swimming pool, a gymnasium, a club house, a children's play area, and a dedicated sports area. The project also features beautifully landscaped gardens and green spaces that provide a serene and peaceful environment for residents to relax and unwind.",
    para4: "Located in Sector 107, Noida, County 107 enjoys excellent connectivity to major business and commercial hubs in Noida and Delhi. The project is well-connected to major roads and highways, including the Noida-Greater Noida Expressway, the Yamuna Expressway, and the FNG Expressway. It is also located close to major metro stations and bus stops, making it easy for residents to commute to different parts of the city.",
    para5: "Overall, County 107 is a great investment for homebuyers looking for a luxurious and comfortable living experience in the bustling city of Noida. With its range of modern amenities and facilities, excellent connectivity, and serene environment, County 107 is the perfect place to call home.",
    text1: "County 107 is a luxurious residential project located in Sector 107, Noida. The project offers 3 and 4 BHK apartments with premium amenities and elegant design. The apartments are designed to provide a comfortable and luxurious living experience with spacious interiors and modern finishes. The project also incorporates several lifestyle amenities like a clubhouse, swimming pool, gymnasium, and more, making it an ideal choice for those who enjoy an active lifestyle. The project is located in a prime location with excellent connectivity to major landmarks and is well-connected to other parts of the city via major highways and roads. With its prime location, luxurious amenities, and elegant design, County 107 is an excellent choice for those looking for a premium living experience in Noida.",
    faq1: "Why to choose County 107 ?",
    ans1: "Posh location,Excellent Connectivity",
    faq2: "What is County 107 Address?",
    ans2: "GH 01A, B ALPHA, Sector 107, Noida",
    faq3: "What is 4 BHK Starting Price? ",
    ans3: "4 BHK flat of Size 3501 Sq. ft. Starts from Rs. 4.9 Cr  onwards",
    faq4: "How many flat units available in County 107 ?",
    ans4: "224 flats are available in the project",
    faq5: "Does County 107 has kids play area?",
    ans5: "Yes, there is kids playing area available",
    faq6: "What is the RERA no. of County 107 ?",
    ans6: "County 107 RERA NO. UPRERAPRJ837374",
    faq7: "What is the Possession date of County 107 Sector 1 ?",
    ans7: "Expected Possession date is around June 2023 ",
    faq8: "Is this Project is Bank Approved ?",
    ans8: "Yes, County 107 is Approved by SBI Bank and HDFC Bank and many more.",
    faq9: "From where I can download County 107 Brochure ?",
    ans9: "You can download the brochure from the website. Download brochure link:",
    faq10: "Does County 107 have Penthouse ?",
    ans10: "Yes, the Project has Penthouse.",
    floorliving: "Vitrified Tiles",
    floormaster: "Vitrified Tiles",
    floorother: "Vitrified Tiles",
    fittingtoilet: "hot and cold water mixer",
    fittingkitchen: "Granite platform with stainless steel sink",
    Doors: "UPVC/Powder Coated or Anodized Aluminium Door",
    interior: "Emulsion Paint ",
    exterior: "Superior Paint Finish",
    kitchen: "Designer Tiles Dado up to 2 Feet Height Above Platform"

  },
  {
    id: 'HT10',
    propertyname: "Eldeco Live By The Greens",
    modal: publicUrl + "assets/img/modal/hunting.jpg",
    logo: "https://i.imgur.com/6TVnhPU.png",
    img: publicUrl + "assets/img/banner/Eldeco.jpg",
    floorPlan11: publicUrl + "assets/img/FloorPlan/Eldeco/1.jpg",
    floorPlan12: publicUrl + "assets/img/FloorPlan/Eldeco/2.jpg",
    floorPlan21: publicUrl + "assets/img/FloorPlan/Eldeco/3.jpg",
    pic1: publicUrl + "assets/img/Gallerynew/Eldeco/1.webp",
    pic2: publicUrl + "assets/img/Gallerynew/Eldeco/2.webp",
    pic3: publicUrl + "assets/img/Gallerynew/Eldeco/3.webp",
    pic4: publicUrl + "assets/img/Gallerynew/Eldeco/4.webp",
    pic5: publicUrl + "assets/img/Gallerynew/Eldeco/5.webp",
    pic6: publicUrl + "assets/img/Gallerynew/Eldeco/6.webp",
    siteplan: publicUrl + "assets/img/SitePlan/eldeco.png",
    plan1: "2 BHK",
    plan2: "3 BHK",
    superareaplan11: "1137 Sq.Ft.",
    superareaplan12: "1155 Sq.Ft.",
    superareaplan21: "1404 Sq.Ft.",
    parkingLevel: "2",
    clubhousesize: "35,000 Sq.Ft",
    constructionType: "Block",
    podium: " Non Podium",
    lift: "2",
    floorvsflats: "5",
    price: "85 Lakhs - 1 Cr ",
    address1: "Sector 150, Noida",
    address2: "SC-02/A9,Sec-150,Noida",
    RERANO: "UPRERAPRJ15172",
    status: "Under Construction",
    launchDate: "May 2017",
    projectType: "Residential",
    projectArea: "20 Acres",
    sizes: "1137 - 1404 Sq.ft.",
    launchDate: "Feb, 2018",
    possessionDate: "Dec 2023",
    totalTowers: "17 Towers",
    totalFloors: "G + 22 Floors",
    configurations: "2BHK, 3BHK",
    location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.034572277574!2d77.4832939182916!3d28.418213652745607!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cc175e7bce8df%3A0xa38445461590a6!2sEldeco%20Live%20by%20the%20Greens!5e0!3m2!1sen!2sin!4v1679740492839!5m2!1sen!2sin",
    totalFlats: "1700 Flats",
    greenArea: "80%",
    ceilingHeight: "10 Ft.",
    mainDoorHeight: "9 Ft.",
    internalDoorHeight: "7 Ft.",
    para1: "Eldeco Live by the Greens is a premium residential project located in Sector 150, Noida, Uttar Pradesh. Developed by Eldeco Group, this project offers luxurious and comfortable living spaces that cater to the needs and preferences of modern-day families.",
    para2: "The apartments at Eldeco Live by the Greens are well-designed and come in 2 BHK, 3 BHK and 4 BHK configurations, with sizes ranging from 1137 square feet to 2025 square feet. The apartments are spacious and well-ventilated, and come equipped with a range of modern amenities and facilities, including air conditioning, modular kitchens, high-speed elevators, and 24/7 security services.",
    para3: "Eldeco Live by the Greens also offers a range of recreational and lifestyle facilities, including a swimming pool, a gymnasium, a club house, a children's play area, and a dedicated sports area. The project also features beautifully landscaped gardens and green spaces that provide a serene and peaceful environment for residents to relax and unwind.",
    para4: "Located in Sector 150, Noida, Eldeco Live by the Greens enjoys excellent connectivity to major business and commercial hubs in Noida and Delhi. The project is well-connected to major roads and highways, including the Noida-Greater Noida Expressway, the Yamuna Expressway, and the FNG Expressway. It is also located close to major metro stations and bus stops, making it easy for residents to commute to different parts of the city.",
    para5: "Overall, Eldeco Live by the Greens is a great investment for homebuyers looking for a luxurious and comfortable living experience in the bustling city of Noida. With its range of modern amenities and facilities, excellent connectivity, and serene environment, Eldeco Live by the Greens is the perfect place to call home.",
    text1: "Eldeco Live By The Greens is a premium residential project located in Sector 150, Noida. Spread across a vast area of lush greenery, the project offers luxurious 2, 3, and 4 BHK apartments that are designed to offer a comfortable and modern living experience. The apartments are spacious and well-ventilated, with large windows that allow natural light and fresh air to enter. The project also offers a wide range of amenities including a swimming pool, a fully equipped gymnasium, a jogging track, a clubhouse, a kids' play area, and more. With its strategic location, top-notch amenities, and exquisite design, Eldeco Live By The Greens is an ideal destination for those seeking a premium lifestyle in Noida.",
    faq1: "Why to choose Eldeco Live By The Greens ?",
    ans1: "Posh location,Excellent Connectivity",
    faq2: "What is Eldeco Live By The Greens Address?",
    ans2: "SC-02/A9,Sec-150,Noida",
    faq3: "What is 2 BHK Starting Price? ",
    ans3: "2 BHK flat of Size 995 Sq. ft. Starts from Rs. 85 Lakhs onwards",
    faq4: "How many flat units available inEldeco Live By The Greens?",
    ans4: "1748 flats are available in the project",
    faq5: "Does Eldeco Live By The Greens has kids play area?",
    ans5: "Yes, there is kids playing area available",
    faq6: "What is the RERA no. of Eldeco Live By The Greens ?",
    ans6: "Eldeco Live By The Greens RERA NO.UPRERAPRJ15172",
    faq7: "What is the Possession date of Eldeco Live By The Greens ?",
    ans7: "Expected Possession date is around August 2023 ",
    faq8: "Is this Project is Bank Approved ?",
    ans8: "Yes, Eldeco Live By The Greens is Approved by SBI Bank and HDFC Bank and many more.",
    faq9: "From where I can download Eldeco Live By The Greens Brochure ?",
    ans9: "You can download the brochure from the website. Download brochure link:",
    faq10: "Does Eldeco Live By The Greens have Penthouse ?",
    ans10: "No, Eldeco Live By The Greens doesn not have a Penthouse",
    floorliving: "Vitrified Tiles",
    floormaster: "Vitrified Tiles",
    floorother: "Vitrified Tiles",
    fittingtoilet: "hot and cold water mixer",
    fittingkitchen: "Granite platform with stainless steel sink",
    Doors: "UPVC/Powder Coated or Anodized Aluminium Door",
    interior: "Emulsion Paint ",
    exterior: "Superior Paint Finish",
    kitchen: "Designer Tiles Dado up to 2 Feet Height Above Platform"

  },
  {
    id: 'HT11',
    propertyname: "Purvanchal Royal City",
    modal: publicUrl + "assets/img/modal/hunting.jpg",
    logo: "https://i.imgur.com/yHSD1xQ.png",
    img: publicUrl + "assets/img/banner/purvanchal.jpg",
    floorPlan11: publicUrl + "assets/img/FloorPlan/Purvanchal/1.jpg",
    floorPlan12: publicUrl + "assets/img/FloorPlan/Purvanchal/0.jpg",
    floorPlan13: publicUrl + "assets/img/FloorPlan/Purvanchal/0.jpg",
    floorPlan14: publicUrl + "assets/img/FloorPlan/Purvanchal/0.jpg",
    floorPlan21: publicUrl + "assets/img/FloorPlan/Purvanchal/3.jpg",
    pic1: publicUrl + "assets/img/Gallerynew/Purvanchal/1.webp",
    pic2: publicUrl + "assets/img/Gallerynew/Purvanchal/2.webp",
    pic3: publicUrl + "assets/img/Gallerynew/Purvanchal/3.webp",
    pic4: publicUrl + "assets/img/Gallerynew/Purvanchal/4.webp",
    pic5: publicUrl + "assets/img/Gallerynew/Purvanchal/5.webp",
    pic6: publicUrl + "assets/img/Gallerynew/Purvanchal/6.webp",
    modal: publicUrl + "assets/img/modal/purvanchal.jpg",
    siteplan: publicUrl + "assets/img/SitePlan/Purvanchal.png",
    plan1: "3 BHK",
    plan2: "PentHouse",
    superareaplan11: "1815 Sq.Ft.",
    superareaplan12: "1825 Sq.Ft.",
    superareaplan13: "2075 Sq.Ft.",
    superareaplan14: "1725 Sq.Ft.",
    superareaplan21: "4700 Sq.Ft.",
    Noofparking: "5000",
    parkingLevel: "2",
    clubhousesize: "100,000 Sq.Ft",
    constructionType: "Mivan",
    podium: " Non Podium",
    lift: "4",
    floorvsflats: "8",
    price: "1 Cr - 1.25 Cr ",
    address1: "Greater Noida West",
    address2: "Plot No GH 05B, Sector Chi V, Greater Noida , UP",
    RERANO: "UPRERAPRJ3137",
    status: "Ready to Move",
    launchDate: "May 2017",
    possessionDate: "Ready to Move",
    projectType: "Residential",
    projectArea: "22 Acres",
    sizes: "1725-4700 Sq.Ft.",
    launchDate: "Jan, 2019",
    possessionDate: "Sep, 2023",
    totalTowers: "17",
    totalFloors: "19",
    configurations: "3 BHK",
    location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.6307493996155!2d77.50384631455489!3d28.430396699952638!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cc174c16fd017%3A0x1a4677eabd34b0bc!2sPurvanchal%20Royal%20City!5e0!3m2!1sen!2sin!4v1679740669055!5m2!1sen!2sin",
    totalFlats: "1240",
    greenArea: "80%",
    ceilingHeight: "10 Ft",
    mainDoorHeight: "9 Ft",
    internalDoorHeight: "9 Ft",
    para1: "Purvanchal Royal City is a premium residential project located in Sector 137, Noida, Uttar Pradesh. Developed by Purvanchal Group, this project offers luxurious and comfortable living spaces that cater to the needs and preferences of modern-day families.",
    para2: "The apartments at Purvanchal Royal City are well-designed and come in 3 BHK and 4 BHK configurations, with sizes ranging from 1725 square feet to 3210 square feet. The apartments are spacious and well-ventilated, and come equipped with a range of modern amenities and facilities, including air conditioning, modular kitchens, high-speed elevators, and 24/7 security services.",
    para3: "Purvanchal Royal City also offers a range of recreational and lifestyle facilities, including a swimming pool, a gymnasium, a club house, a children's play area, and a dedicated sports area. The project also features beautifully landscaped gardens and green spaces that provide a serene and peaceful environment for residents to relax and unwind.",
    para4: "Located in Sector 137, Noida, Purvanchal Royal City enjoys excellent connectivity to major business and commercial hubs in Noida and Delhi. The project is well-connected to major roads and highways, including the Noida-Greater Noida Expressway, the Yamuna Expressway, and the FNG Expressway. It is also located close to major metro stations and bus stops, making it easy for residents to commute to different parts of the city.",
    para5: "Overall, Purvanchal Royal City is a great investment for homebuyers looking for a luxurious and comfortable living experience in the bustling city of Noida. With its range of modern amenities and facilities, excellent connectivity, and serene environment, Purvanchal Royal City is the perfect place to call home.",
    text1: "Purvanchal Royal City is a luxurious residential complex located in Sector CHI V of Greater Noida. The project offers a range of 3 and 4 BHK apartments that are built with the highest standards of quality and design. The apartments are spacious and well-ventilated, providing a comfortable and luxurious living experience. The project is spread over 22 acres of land and offers a wide range of amenities, including a clubhouse, swimming pool, gymnasium, and landscaped gardens. The project is strategically located, with easy access to major highways and metro stations, making it an ideal choice for those looking for a comfortable and convenient lifestyle. The project is built by Purvanchal Group, a well-known real estate developer with a reputation for delivering high-quality projects on time.",
    faq1: "Why to choosePurvanchal Royal City ?",
    ans1: "Posh location,Excellent Connectivity",
    faq2: "What is Purvanchal Royal City Address?",
    ans2: "GH 14A, Sector 1 Noida Extension, Greater Noida ",
    faq3: "What is 2 BHK Starting Price? ",
    ans3: "2 BHK flat of Size 995 Sq. ft. Starts from Rs. 75 lakh onwards",
    faq4: "How many flat units available in Purvanchal Royal City?",
    ans4: "1748 flats are available in the project",
    faq5: "Does Purvanchal Royal City has kids play area?",
    ans5: "Yes, there is kids playing area available",
    faq6: "What is the RERA no. of Purvanchal Royal City ?",
    ans6: "Purvanchal Royal City RERA NO. UPRERAPRJ3137",
    faq7: "What is the Possession date of Purvanchal Royal City  ?",
    ans7: "Expected Possession date is around Sep 2023 ",
    faq8: "Is this Project is Bank Approved ?",
    ans8: "Yes, Purvanchal Royal City is Approved by SBI Bank and HDFC Bank and many more.",
    faq9: "From where I can download Purvanchal Royal City Brochure ?",
    ans9: "You can download the brochure from the website. Download brochure link:",
    faq10: "Does Purvanchal Royal City have Penthouse ?",
    ans10: "Yes, the Project has Penthouse.",
    video: "https://www.youtube.com/embed/Mr6lWeAO2y8",
    floorliving: "Vitrified Tiles",
    floormaster: "Vitrified Tiles",
    floorother: "Vitrified Tiles",
    fittingtoilet: "hot and cold water mixer",
    fittingkitchen: "Granite platform with stainless steel sink",
    Doors: "UPVC/Powder Coated or Anodized Aluminium Door",
    interior: "Emulsion Paint ",
    exterior: "Superior Paint Finish",
    kitchen: "Designer Tiles Dado up to 2 Feet Height Above Platform",
    floorliving: "Vitrified Tiles",
    floormaster: "Vitrified Tiles",
    floorother: "Vitrified Tiles",
    fittingtoilet: "hot and cold water mixer",
    fittingkitchen: "Granite platform with stainless steel sink",
    Doors: "UPVC/Powder Coated or Anodized Aluminium Door",
    interior: "Emulsion Paint ",
    exterior: "Superior Paint Finish",
    kitchen: "Designer Tiles Dado up to 2 Feet Height Above Platform"
  },
  {
    id: 'HT12',
    propertyname: "Dasnac Burj Noida",
    img: publicUrl + "assets/img/banner/burj.jpg",
    modal: publicUrl + "assets/img/modal/burj.jpg",
    floorPlan11: publicUrl + "assets/img/FloorPlan/Burj/1.jpg",
    floorPlan21: publicUrl + "assets/img/FloorPlan/Burj/2.jpg",
    floorPlan22: publicUrl + "assets/img/FloorPlan/Burj/0.jpg",
    floorPlan31: publicUrl + "assets/img/FloorPlan/Burj/3.jpg",
    floorPlan32: publicUrl + "assets/img/FloorPlan/Burj/0.jpg",
    pic1: publicUrl + "assets/img/Gallerynew/BURJ/1.webp",
    pic2: publicUrl + "assets/img/Gallerynew/BURJ/2.webp",
    pic3: publicUrl + "assets/img/Gallerynew/BURJ/3.webp",
    pic4: publicUrl + "assets/img/Gallerynew/BURJ/4.webp",
    pic5: publicUrl + "assets/img/Gallerynew/BURJ/5.webp",
    pic6: publicUrl + "assets/img/Gallerynew/BURJ/6.webp",
    siteplan: publicUrl + "assets/img/SitePlan/Burj.png",
    logo: "https://i.imgur.com/4fFrbLp.png",
    plan1: "3 BHK",
    plan2: "4 BHK",
    plan3: "5.5 BHK Duplex",
    superareaplan11: "2510 Sq.Ft.",
    superareaplan21: "3030 Sq.Ft.",
    superareaplan22: "3300 Sq.Ft.",
    superareaplan31: "5300 Sq.Ft.",
    superareaplan32: "6300 Sq.Ft.",
    Noofparking: "1000+",
    parkingLevel: "2",
    clubhousesize: "25000 Sq.Ft",
    podium: " Non Podium",
    lift: "4",
    floorvsflats: "4",
    constructionType: "Mivan",
    price: "2.89 Cr - 7.25 Cr ",
    address1: "Sector 75, Noida",
    address2: "Sector 75, Noida",
    RERANO: "UPRERAPRJ498423",
    status: "Under Construction",
    launchDate: "April 2019",
    possessionDate: "Ready to Move",
    projectType: "Residential",
    projectArea: "1 Acres",
    sizes: "2510.00 sq.ft. - 6300.00 sq.ft.",
    projectUnits: "131 units",
    possessionDate: "Dec, 2023",
    totalTowers: "1",
    configurations: "3, 4, 5.5 BHK Apartment, Duplexes",
    location: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14015.233447054106!2d77.3819281!3d28.5755168!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cef0bb7b93bd9%3A0x723ac9083147a601!2sDASNAC%20Burj%20Noida%20(Official)!5e0!3m2!1sen!2sin!4v1679295825659!5m2!1sen!2sin",
    totalFlats: "131",
    greenArea: "80%",
    totalFloors: "35",
    ceilingHeight: "11.5 ft.",
    mainDoorHeight: "9 Ft.",
    internalDoorHeight: "8 Ft.",
    para1: "Dasnac Burj Noida is a luxurious residential project located in Sector 75, Noida, Uttar Pradesh. Developed by Dasnac Design Arch, this project offers spacious and comfortable living spaces that cater to the needs and preferences of modern-day families.",
    para2: "The apartments at Dasnac Burj Noida come in 3 BHK and 4 BHK configurations, with sizes ranging from 1950 square feet to 3950 square feet. The apartments are well-designed and come equipped with a range of modern amenities and facilities, including air conditioning, modular kitchens, high-speed elevators, and 24/7 security services.",
    para3: "Dasnac Burj Noida also offers a range of recreational and lifestyle facilities, including a swimming pool, a gymnasium, a club house, a children's play area, and a dedicated sports area. The project also features beautifully landscaped gardens and green spaces that provide a serene and peaceful environment for residents to relax and unwind.",
    para4: "Located in Sector 75, Noida, Dasnac Burj Noida enjoys excellent connectivity to major business and commercial hubs in Noida and Delhi. The project is well-connected to major roads and highways, including the Noida-Greater Noida Expressway, the Yamuna Expressway, and the FNG Expressway. It is also located close to major metro stations and bus stops, making it easy for residents to commute to different parts of the city.",
    para5: "Overall, Dasnac Burj Noida is a great investment for homebuyers looking for a luxurious and comfortable living experience in the bustling city of Noida. With its range of modern amenities and facilities, excellent connectivity, and serene environment, Dasnac Burj Noida is the perfect place to call home.",
    text1: "Dasnac Burj Noida is a luxurious residential project located in the prime location of Sector 75, Noida. The project offers a wide range of spacious and beautifully designed apartments that are equipped with modern amenities to provide a comfortable lifestyle. The apartments are available in 3BHK and 4BHK configurations with sizes ranging from 1950 sq. ft. to 3450 sq. ft. The project boasts of world-class amenities such as a state-of-the-art clubhouse, swimming pool, landscaped gardens, children's play area, gymnasium, and many more. The project is strategically located with easy access to major highways and is in close proximity to reputed educational institutions, hospitals, shopping centers, and entertainment hubs, making it an ideal place to call home. With its luxurious amenities, prime location, and exquisite design, Dasnac Burj Noida is a perfect place for those looking to enjoy a sophisticated and comfortable lifestyle.",
    faq1: "Why to choose Dasnac Burj ?",
    ans1: "Posh location,Excellent Connectivity",
    faq2: "What is Dasnac Burj Address?",
    ans2: "Sector 75, Noida ",
    faq3: "What is 3 BHKStarting Price? ",
    ans3: "3 BHK flat of Size 2510 Sq.Ft. Starts from Rs. 2.89 Cr onwards",
    faq4: "How many flat units available in Dasnac Burj ?",
    ans4: "131 flats are available in the project",
    faq5: "Does Dasnac Burj has kids play area?",
    ans5: "Yes, there is kids playing area available",
    faq6: "What is the RERA no. of Dasnac Burj ?",
    ans6: "Dasnac Burj RERA NO. UPRERAPRJ498423",
    faq7: "What is the Possession date of Dasnac Burj  ?",
    ans7: "Expected Possession date is around December 2023 ",
    faq8: "Is this Project is Bank Approved ?",
    ans8: "Yes, Dasnac Burj is Approved by SBI Bank and HDFC Bank and many more.",
    faq9: "From where I can download Dasnac Burj Brochure ?",
    ans9: "You can download the brochure from the website. Download brochure link:",
    faq10: "Does Dasnac Burj have Duplex ?",
    ans10: "Yes, the Project has Duplex.",
    video: "https://www.youtube.com/embed/tvSpvquWZ8E",
    floorliving: "Vitrified Tiles",
    floormaster: "Vitrified Tiles",
    floorother: "Vitrified Tiles",
    fittingtoilet: "hot and cold water mixer",
    fittingkitchen: "Granite platform with stainless steel sink",
    Doors: "UPVC/Powder Coated or Anodized Aluminium Door",
    interior: "Emulsion Paint ",
    exterior: "Superior Paint Finish",
    kitchen: "Designer Tiles Dado up to 2 Feet Height Above Platform"
  },
  {
    id: 'HT13',
    propertyname: "Gulshan Dynasty",
    img: publicUrl + "assets/img/banner/gulshandynasty.webp",
    modal: publicUrl + "assets/img/modal/hunting.jpg",
    floorPlan11: publicUrl + "assets/img/FloorPlan/Gulshan/1.png",
    floorPlan21: publicUrl + "assets/img/FloorPlan/Gulshan/0.jpg",
    logo: "https://i.imgur.com/bmjO953.png",
    plan1: "4 BHK",
    plan2: "PentHouse(Duplex)",
    superareaplan11: "4700 Sq.Ft.",
    superareaplan21: "8000 Sq.Ft.",
    pic1: publicUrl + "assets/img/Gallerynew/gulshan/1.webp",
    pic2: publicUrl + "assets/img/Gallerynew/gulshan/2.webp",
    pic3: publicUrl + "assets/img/Gallerynew/gulshan/3.webp",
    pic4: publicUrl + "assets/img/Gallerynew/gulshan/4.webp",
    pic5: publicUrl + "assets/img/Gallerynew/gulshan/5.webp",
    pic6: publicUrl + "assets/img/Gallerynew/gulshan/6.webp",
    siteplan: publicUrl + "assets/img/SitePlan/gulshan.png",
    superarea1: "4700 Sq.Ft.",
    type1: "4 BHK",
    Noofparking: "500+",
    parkingLevel: "2",
    clubhousesize: "45000 Sq.Ft",
    podium: " Non Podium",
    constructionType: "Mivan",
    lift: "3",
    floorvsflats: "2",
    price: "7.5 Cr ",
    address1: "Sector 144",
    address2: "Sector 144, Noida-Greater Noida Expressway, Noida",
    RERANO: "UPRERAPRJ950870",
    PropertyID: "HZ29",
    status: "Ready to Move",
    launchDate: "May 2017",
    possessionDate: "Ready to Move",
    projectType: "Residential",
    projectArea: "5.8 Acres",
    totalTowers: "3",
    sizes: "4700.00 sq.ft.",
    projectUnits: "204 units",
    launchDate: "Nov, 2019",
    possessionDate: "Nov, 2024",
    configurations: "4 BHK Apartment",
    location: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14025.547583075506!2d77.4395886!3d28.4980052!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce9293106346f%3A0xcfd1e8b39fc76e0f!2sGulshan%20Dynasty!5e0!3m2!1sen!2sin!4v1679302667536!5m2!1sen!2sin",
    totalFlats: "204 units",
    greenArea: "76 %",
    totalFloors: "34",
    ceilingHeight: "10 Ft.",
    mainDoorHeight: "9 Ft.",
    internalDoorHeight: "7 Ft.",
    text: "Gulshan Dynasty is a luxury residential project located in the heart of Noida. The project offers spacious and well-designed apartments in various configurations ranging from 3 BHK to 4 BHK. The apartments are equipped with modern amenities and facilities such as a clubhouse, swimming pool, gymnasium, kids play area, landscaped gardens, and more. The project is strategically located with excellent connectivity to major highways, metro stations, and other essential conveniences like schools, hospitals, and shopping centers. Gulshan Dynasty is designed to provide a luxurious lifestyle with world-class amenities and features. The project is also designed to offer a sustainable living experience with eco-friendly practices like rainwater harvesting, waste management, and more. The project is developed by Gulshan Homz, a reputed real estate developer known for its quality and timely delivery. With Gulshan Dynasty, residents can enjoy a luxurious and comfortable lifestyle in the heart of Noida.",
    text1: "Gulshan Dynasty is a premium residential project located in Sector 144, Noida. The project offers 2, 3, and 4 BHK apartments ranging from 1,315 square feet to 2,795 square feet. The apartments are designed with modern architecture and are well-ventilated, spacious, and equipped with all necessary amenities. The project is spread across a vast expanse of land and offers lush green surroundings, ensuring a comfortable and healthy lifestyle for residents. Some of the amenities available in Gulshan Dynasty include a swimming pool, gymnasium, landscaped garden, clubhouse, and kids play area, among others. The location of Gulshan Dynasty is also a major advantage as it is well-connected to major highways and offers easy access to schools, hospitals, and commercial areas. Overall, Gulshan Dynasty offers a luxurious and comfortable living experience for those looking to invest in a premium residential project in Noida.",
    para1: "Gulshan Dynasty is a premium residential project located in Sector 144, Noida, Uttar Pradesh. Developed by Gulshan Homz, this project offers luxurious and comfortable living spaces that cater to the needs and preferences of modern-day families.",
    para2: "The apartments at Gulshan Dynasty are well-designed and come in 3 BHK and 4 BHK configurations, with sizes ranging from 1500 square feet to 2400 square feet. The apartments are spacious and well-ventilated, and come equipped with a range of modern amenities and facilities, including air conditioning, modular kitchens, high-speed elevators, and 24/7 security services.",
    para3: "Gulshan Dynasty also offers a range of recreational and lifestyle facilities, including a swimming pool, a gymnasium, a club house, a children's play area, and a dedicated sports area. The project also features beautifully landscaped gardens and green spaces that provide a serene and peaceful environment for residents to relax and unwind.",
    para4: "Located in Sector 144, Noida, Gulshan Dynasty enjoys excellent connectivity to major business and commercial hubs in Noida and Delhi. The project is well-connected to major roads and highways, including the Noida-Greater Noida Expressway, the Yamuna Expressway, and the FNG Expressway. It is also located close to major metro stations and bus stops, making it easy for residents to commute to different parts of the city.",
    para5: "Overall, Gulshan Dynasty is a great investment for homebuyers looking for a luxurious and comfortable living experience in the bustling city of Noida. With its range of modern amenities and facilities, excellent connectivity, and serene environment, Gulshan Dynasty is the perfect place to call home.",
    faq1: "Why to choose Gulshan Dynasty ?",
    ans1: "Posh location,Excellent Connectivity",
    faq2: "What is Gulshan Dynasty Address?",
    ans2: "GH 14A, Sector 1 Noida Extension, Greater Noida ",
    faq3: "What is 4 BHK Starting Price? ",
    ans3: "4 BHK flat of Size 4700 Sq.Ft. Starts from Rs. 7.5 Cr onwards",
    faq4: "How many flat units available in Gulshan Dynasty ?",
    ans4: "204 flats are available in the project",
    faq5: "Does Gulshan Dynasty has kids play area?",
    ans5: "Yes, there is kids playing area available",
    faq6: "What is the RERA no. of Gulshan Dynasty ?",
    ans6: "Gulshan Dynasty RERA NO. UPRERAPRJ950870",
    faq7: "What is the Possession date of Gulshan Dynasty  ?",
    ans7: "Expected Possession date is around Nov, 2024 ",
    faq8: "Is this Project is Bank Approved ?",
    ans8: "Yes, Gulshan Dynasty is Approved by SBI Bank and HDFC Bank and many more.",
    faq9: "From where I can download Gulshan Dynasty Brochure ?",
    ans9: "You can download the brochure from the website. Download brochure link:",
    faq10: "Does Gulshan Dynasty have Penthouse ?",
    ans10: "Yes, the Project has Penthouse.",
    floorliving: "Vitrified Tiles",
    floormaster: "Vitrified Tiles",
    floorother: "Vitrified Tiles",
    fittingtoilet: "hot and cold water mixer",
    fittingkitchen: "Granite platform with stainless steel sink",
    Doors: "UPVC/Powder Coated or Anodized Aluminium Door",
    interior: "Emulsion Paint ",
    exterior: "Superior Paint Finish",
    kitchen: "Designer Tiles Dado up to 2 Feet Height Above Platform"
  },
  {
    id: 'HT14',
    propertyname: "Godrej Nest",
    logo: "https://i.imgur.com/YzVM0SB.png",
    modal: publicUrl + "assets/img/modal/hunting.jpg",
    img: publicUrl + "assets/img/banner/Nest.jpg",
    floorPlan11: publicUrl + "assets/img/FloorPlan/Nest/1.jpg",
    floorPlan12: publicUrl + "assets/img/FloorPlan/Nest/0.jpg",
    floorPlan13: publicUrl + "assets/img/FloorPlan/Nest/0.jpg",
    floorPlan14: publicUrl + "assets/img/FloorPlan/Nest/0.jpg",
    floorPlan21: publicUrl + "assets/img/FloorPlan/Nest/2.jpg",
    floorPlan22: publicUrl + "assets/img/FloorPlan/Nest/0.jpg",
    pic1: publicUrl + "assets/img/Gallerynew/NEST/1.webp",
    pic2: publicUrl + "assets/img/Gallerynew/NEST/2.webp",
    pic3: publicUrl + "assets/img/Gallerynew/NEST/3.webp",
    pic4: publicUrl + "assets/img/Gallerynew/NEST/4.webp",
    pic5: publicUrl + "assets/img/Gallerynew/NEST/5.webp",
    pic6: publicUrl + "assets/img/Gallerynew/NEST/6.webp",
    siteplan: publicUrl + "assets/img/SitePlan/nest.png",
    plan1: "3 BHK",
    plan2: "4 BHK",
    superareaplan11: "1907 Sq.Ft.",
    superareaplan12: "1476 Sq.Ft.",
    superareaplan13: "2062 Sq.Ft.",
    superareaplan14: "2330 Sq.Ft.",
    superareaplan21: "2700 Sq.Ft.",
    superareaplan22: "3027 Sq.Ft.",
    Noofparking: "",
    parkingLevel: "",
    constructionType: "Mivan",
    clubhousesize: "45000 Sq.Ft",
    podium: "Non Podium",
    lift: "2",
    floorvsflats: "4",
    price: "1.46 Cr - 2.39 Cr ",
    address1: "Sector 150",
    address2: "Sector 150, Noida-Greater Noida Expressway, Noida",
    RERANO: "UPRERAPRJ13521",
    PropertyID: "HZ29",
    status: "Under Construction",
    launchDate: "Nov, 2017",
    possessionDate: "Dec-2024",
    projectType: "Residential",
    projectArea: "18 Acres",
    sizes: "1907.00 sq.ft. - 3027.00 sq.ft.",
    launchDate: "Nov, 2017",
    possessionDate: "Mar, 2025",
    totalTowers: "14",
    totalFloors: "31",
    configurations: "3, 4 BHK Apartments",
    location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.961647329656!2d77.49184201455466!3d28.420414100401555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cc17b07d6cb7f%3A0xe77057bdd6c21349!2sGodrej%20Nest%2C%20Noida!5e0!3m2!1sen!2sin!4v1679740775899!5m2!1sen!2sin",
    totalFlats: "1200",
    greenArea: "70%",
    ceilingHeight: "10 Ft.",
    mainDoorHeight: "9 Ft.",
    internalDoorHeight: "7 Ft.",
    para1: "Godrej Nest is a luxurious residential project located in Sector 150, Noida, Uttar Pradesh. Developed by Godrej Properties, this project offers spacious and comfortable living spaces that cater to the needs and preferences of modern-day families.",
    para2: "The apartments at Godrej Nest come in 2 BHK, 3 BHK, and 4 BHK configurations, with sizes ranging from 1250 square feet to 3050 square feet. The apartments are well-designed and come equipped with a range of modern amenities and facilities, including air conditioning, modular kitchens, high-speed elevators, and 24/7 security services.",
    para3: "Godrej Nest also offers a range of recreational and lifestyle facilities, including a swimming pool, a gymnasium, a club house, a children's play area, and a dedicated sports area. The project also features beautifully landscaped gardens and green spaces that provide a serene and peaceful environment for residents to relax and unwind.",
    para4: "Located in Sector 150, Noida, Godrej Nest enjoys excellent connectivity to major business and commercial hubs in Noida and Delhi. The project is well-connected to major roads and highways, including the Noida-Greater Noida Expressway, the Yamuna Expressway, and the FNG Expressway. It is also located close to major metro stations and bus stops, making it easy for residents to commute to different parts of the city.",
    para5: "Overall, Godrej Nest is a great investment for homebuyers looking for a luxurious and comfortable living experience in the bustling city of Noida. With its range of modern amenities and facilities, excellent connectivity, and serene environment, Godrej Nest is the perfect place to call home.",
    text1: "Godrej Nest is a luxurious residential project located in the prime location of Sector 150 in Noida. Spread across 9 acres of land, it offers a perfect blend of comfortable living and contemporary architecture. The project comprises of 7 towers and 926 units of 1 BHK, 2 BHK, 3 BHK, and 4 BHK apartments with sizes ranging from 712 square feet to 2,347 square feet. The apartments are equipped with modern amenities like a fully equipped gymnasium, clubhouse, swimming pool, kids' play area, landscaped garden, 24/7 security and surveillance, and more. The location of Godrej Nest is another major advantage, as it is well-connected to various parts of Noida, Greater Noida, and Delhi NCR. With its luxurious features, modern amenities, and prime location, Godrej Nest is an ideal destination for those seeking a perfect blend of luxury, comfort, and convenience.",
    faq1: "Why to choose Godrej Nest ?",
    ans1: "Posh location,Excellent Connectivity",
    faq2: "What is Godrej Nest Address?",
    ans2: "GH 14A, Sector 1 Noida Extension, Greater Noida ",
    faq3: "What is 3 BHK Starting Price? ",
    ans3: "3 BHK flat of Size 1907.00 sq.ft. Starts from Rs. 1.46 Cr onwards",
    faq4: "How many flat units available in Godrej Nest ?",
    ans4: "1200 flats are available in the project",
    faq5: "Does Godrej Nest has kids play area?",
    ans5: "Yes, there is kids playing area available",
    faq6: "What is the RERA no. of Godrej Nest ?",
    ans6: "Godrej Nest RERA NO. UPRERAPRJ13521",
    faq7: "What is the Possession date of Godrej Nest ?",
    ans7: "Expected Possession date is around December 2024 ",
    faq8: "Is this Project is Bank Approved ?",
    ans8: "Yes, Godrej Nest is Approved by SBI Bank and HDFC Bank and many more.",
    faq9: "From where I can download Godrej Nest Brochure ?",
    ans9: "You can download the brochure from the website. Download brochure link:",
    faq10: "Does Godrej Nest have Penthouse ?",
    ans10: "No, the Project does not have a Penthouse.",
    floorliving: "Vitrified Tiles",
    floormaster: "Vitrified Tiles",
    floorother: "Vitrified Tiles",
    fittingtoilet: "hot and cold water mixer",
    fittingkitchen: "Granite platform with stainless steel sink",
    Doors: "UPVC/Powder Coated or Anodized Aluminium Door",
    interior: "Emulsion Paint ",
    exterior: "Superior Paint Finish",
    kitchen: "Designer Tiles Dado up to 2 Feet Height Above Platform"
  },
  {
    id: 'HT15',
    propertyname: "Ivy County",
    logo: "https://i.imgur.com/CfQ14tR.png",
    modal: "https://i.imgur.com/5nTo04i.jpg",
    img: publicUrl + "assets/img/banner/ivycounty.jpg",
    floorPlan11: publicUrl + "assets/img/FloorPlan/Ivy/3.png",
    floorPlan12: publicUrl + "assets/img/FloorPlan/Ivy/2.png",
    floorPlan21: publicUrl + "assets/img/FloorPlan/Ivy/1.png",
    siteplan: publicUrl + "assets/img/SitePlan/ivy.png",
    plan1: "3 BHK",
    plan2: "4 BHK",
    superareaplan11: "1656 Sq.Ft.",
    superareaplan12: "2124 Sq.Ft.",
    superareaplan21: "2511 Sq.Ft.",
    pic1: publicUrl + "assets/img/Gallery/Ivy/1.jpg",
    pic2: publicUrl + "assets/img/Gallery/Ivy/2.jpg",
    pic3: publicUrl + "assets/img/Gallery/Ivy/1.jpg",
    pic4: publicUrl + "assets/img/Gallery/Ivy/2.jpg",
    pic5: publicUrl + "assets/img/Gallery/Ivy/1.jpg",
    pic6: publicUrl + "assets/img/Gallery/Ivy/2.jpg",
    Noofparking: "1000+",
    parkingLevel: "2",
    clubhousesize: "35000 Sq.Ft",
    constructionType: "Mivan",
    podium: "Non Podium",
    lift: "3",
    floorvsflats: "4",
    price: "1.49 Cr - 2.26 Cr",
    address1: "Sector 75",
    address2: "Sector 75",
    RERANO: "546",
    status: "Under Construction",
    launchDate: "May 2017",
    projectType: "Residential",
    projectArea: "5 Acres",
    sizes: "1656-2511 Sq.Ft.",
    possessionDate: "Jul 23",
    totalTowers: "5",
    totalFloors: "27",
    configurations: "3 BHK,4 BHK",
    location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.7794870494117!2d77.38371981455833!3d28.57638369337195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cef1b8a9d800f%3A0x23a44f04ca87dec3!2sIVY%20County!5e0!3m2!1sen!2sin!4v1679740834376!5m2!1sen!2sin",
    totalFlats: "546",
    greenArea: "78%",
    ceilingHeight: "11 Ft.",
    mainDoorHeight: "8 Ft",
    internalDoorHeight: "8 Ft.",
    para1: "Ivy County is a premium residential project located in Sector 75, Noida, Uttar Pradesh. Developed by ABA Corp, this project offers luxurious and comfortable living spaces that cater to the needs and preferences of modern-day families.",
    para2: "The apartments at Ivy County are well-designed and come in 2 BHK, 3 BHK, and 4 BHK configurations, with sizes ranging from 1195 square feet to 2595 square feet. The apartments are spacious and well-ventilated, and come equipped with a range of modern amenities and facilities, including air conditioning, modular kitchens, high-speed elevators, and 24/7 security services.",
    para3: "Ivy County also offers a range of recreational and lifestyle facilities, including a swimming pool, a gymnasium, a club house, a children's play area, and a dedicated sports area. The project also features beautifully landscaped gardens and green spaces that provide a serene and peaceful environment for residents to relax and unwind.",
    para4: "Located in Sector 75, Noida, Ivy County enjoys excellent connectivity to major business and commercial hubs in Noida and Delhi. The project is well-connected to major roads and highways, including the Noida-Greater Noida Expressway, the Yamuna Expressway, and the FNG Expressway. It is also located close to major metro stations and bus stops, making it easy for residents to commute to different parts of the city.",
    para5: "Overall, Ivy County is a great investment for homebuyers looking for a luxurious and comfortable living experience in the bustling city of Noida. With its range of modern amenities and facilities, excellent connectivity, and serene environment, Ivy County is the perfect place to call home.",
    text1: "Ivy County Noida is a premium residential complex located in one of the most sought-after areas of Noida. The complex offers a luxurious living experience with modern amenities and state-of-the-art architecture. It is designed to cater to the needs and preferences of modern families, providing spacious and well-designed apartments with premium finishes and fittings.Residents of Ivy County Noida can enjoy a range of modern amenities, including a swimming pool, gymnasium, landscaped gardens, clubhouse, and sports facilities. It is a great opportunity to invest in the growing real estate market of Noida and secure a premium property in a prime location.Overall, Ivy County Noida is a premium residential complex that offers a modern and luxurious living experience in the heart of Noida. Its modern amenities, prime location, and investment potential make it an ideal choice for those looking for a comfortable and convenient living space or a smart investment opportunity. ",
    faq1: "Why to choose Ivy County ?",
    ans1: "Posh location,Excellent Connectivity",
    faq2: "What is Ivy County  Address?",
    ans2: "Sector 75,Noida ",
    faq3: "What is 3 BHK Starting Price? ",
    ans3: "3 BHK flat of Size 1656 Sq. ft. Starts from Rs 1.49 Cr onwards",
    faq4: "How many flat units available in Ivy County ?",
    ans4: "546 flats are available in the project",
    faq5: "Does Ivy County  has kids play area?",
    ans5: "Yes, there is kids playing area available",
    faq6: "What is the RERA no. of Ivy County ?",
    ans6: "Ivy County  RERA NO. UPRERAPRJ6734",
    faq7: "What is the Possession date of Ivy County ?",
    ans7: "Expected Possession date is around August 2023 ",
    faq8: "Is this Project is Bank Approved ?",
    ans8: "Yes, Ivy County  is Approved by SBI Bank and HDFC Bank and many more.",
    faq9: "From where I can download Ivy County  Brochure ?",
    ans9: "You can download the brochure from the website. Download brochure link:",
    faq10: "Does Ivy County have Penthouse ?",
    ans10: "No, the Project does not have a Penthouse.",
    floorliving: "Vitrified Tiles",
    floormaster: "Vitrified Tiles",
    floorother: "Vitrified Tiles",
    fittingtoilet: "Hot and cold water mixer",
    fittingkitchen: "Granite platform with stainless steel sink",
    Doors: "UPVC/Powder Coated or Anodized Aluminium Door",
    interior: "Emulsion Paint ",
    exterior: "Superior Paint Finish",
    kitchen: "Designer Tiles Dado up to 2 Feet Height Above Platform"
  }

];

