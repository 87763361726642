import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import Download from '../Pageview/Downloadbrochure'
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import 'react-awesome-slider/dist/custom-animations/fall-animation.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay'; 


const AutoplaySlider = withAutoplay(AwesomeSlider);

const Related = () => {
	let publicUrl = process.env.PUBLIC_URL+'/'

  return (
	<div>
	  <div className="ltn__upcoming-project-area " >
			  <div className="container">
			   
				<AutoplaySlider
    play={true}
    cancelOnInteraction={false} // should stop playing on user interaction
    interval={1000}
  animation="cubeAnimation"
  mobileTouch={true}
  >
			    
			      {/* upcoming-project-item */}
			      <div className="col-lg-12" >
			        <div className="ltn__upcoming-project-item">
			          <div className="row">
			            <div className="col-lg-7">
			              <div className="ltn__upcoming-project-img">
			                <img src={publicUrl+"assets/img/upa.png"} alt="#" />
			              </div>
			            </div>
			            <div className="col-lg-5 section-bg-1 notvisible" >
			              <div className="ltn__upcoming-project-info ltn__menu-widget">
			                <h6 className="section-subtitle ltn__secondary-color mb-0">About L&T</h6>
			                <p className="mt-30">L&T's premium residential project offers luxurious living in Noida sector 128.The project is being developed by Larsen & Toubro, a leading construction and engineering company in India</p>
			                <ul className="mt">
			                  <li> Project Name: <span>L&T</span></li>
			                  <li> Project Type: <span>Residential</span></li>
			                  <li> Project Location: <span> SECTOR 128</span></li>
			                  <li> Configurations: <span>2 BHK, 3 BHK and 4 BHK </span></li>
			                  
			                </ul>
			                <div className="btn-wrapper animated go-top">
			                  <div className="theme-btn-1 btn btn-effect-1"><Download/></div>
			                </div>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			
			      <div className="col-lg-12">
			        <div className="ltn__upcoming-project-item">
			          <div className="row">
			            <div className="col-lg-7">
			              <div className="ltn__upcoming-project-img">
			                <img src={publicUrl+"assets/img/upb.jpg"} alt="#" />
			              </div>
			            </div>
			            <div className="col-lg-5 section-bg-1 notvisible">
			              <div className="ltn__upcoming-project-info ltn__menu-widget">
			                <h6 className="ltn__secondary-color">About M3M</h6>
			     
							<p className="mt-30">M3M Noida a prominent player in the NCR region. The company has a portfolio of premium residential and commercial projects in prime locations in Noida. M3M Noida's properties are known for their quality construction, modern design, and luxurious amenities.</p>
			                <ul>
			                  <li> Project Name: <span>M3M</span></li>
			                  <li> Project Type: <span>Residential</span></li>
			                  <li> Project Location: <span>Sector 94</span></li>
			                  <li> Configurations: <span>3 BHK,4 BHK,5 BHK</span></li>
			                  
			                </ul>
			                <div className="btn-wrapper animated go-top">
							<div className="theme-btn-1 btn btn-effect-1"><Download/></div>
			                </div>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			 
			      <div className="col-lg-12">
			        <div className="ltn__upcoming-project-item">
			          <div className="row">
			            <div className="col-lg-7">
			              <div className="ltn__upcoming-project-img">
			                <img src={publicUrl+"assets/img/upc.png"} alt="#" />
			              </div>
			            </div>
			            <div className="col-lg-5 section-bg-1 notvisible">
			              <div className="ltn__upcoming-project-info ltn__menu-widget">
			                <h6 className="ltn__secondary-color">About Max Estate</h6>
							<p className="mt-30">Max Estate Noida is Coming Soon 2 BHK and 3 BHK luxurious Residential apartments with all kind of ultra-modern specifications and facilities.nestled with massive 73 acres of lush greens golf course lies in the majestic towers of Max Sector 128 Noida.</p>
			                
			                <ul>
			                  <li> Project Name: <span>Max Estate</span></li>
			                  <li> Project Type: <span>Residential</span></li>
			                  <li> Project Location: <span>Sector 128</span></li>
			                  <li> Configurations: <span> 3 BHK,4 BHK and Duplexes.</span></li>
			                
			                </ul>
			                <div className="btn-wrapper animated go-top">
							<div className="theme-btn-1 btn btn-effect-1"><Download/></div>
			                </div>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
				  </AutoplaySlider>
			  </div>
			</div>
	</div>
  )
}

export default Related
