import React, { useState } from 'react';

function ImageContainer(props) {
  const [showAll, setShowAll] = useState(false);
  
  let publicUrl = process.env.PUBLIC_URL + "/";
  const maxVisible = 12; // initially show only 10 images
  const images = [
    { id: 1, src: publicUrl+"assets/img/icon/1.png" , alt: 'Image 1' },
    { id: 2, src: publicUrl+"assets/img/icon/2.png", alt: 'Image 2' },
    { id: 3, src: publicUrl+"assets/img/icon/3.png", alt: 'Image 1' },
    { id: 4, src: publicUrl+"assets/img/icon/4.png", alt: 'Image 1' },
    { id: 5, src: publicUrl+"assets/img/icon/5.png", alt: 'Image 1' },
    { id: 6, src: publicUrl+"assets/img/icon/6.png", alt: 'Image 1' },
    { id: 7, src: publicUrl+"assets/img/icon/7.png", alt: 'Image 1' },
    { id: 8, src: publicUrl+"assets/img/icon/8.png", alt: 'Image 1' },
    { id: 9, src: publicUrl+"assets/img/icon/9.png", alt: 'Image 1' },
    { id: 10, src:publicUrl+"assets/img/icon/10.png", alt: 'Image 1' },
    { id: 11, src: publicUrl+"assets/img/icon/11.png", alt: 'Image 1' },
    { id: 12, src:publicUrl+"assets/img/icon/12.png", alt: 'Image 1' },
    { id: 13, src:publicUrl+"assets/img/icon/13.png", alt: 'Image 1' },
    { id: 14, src:publicUrl+"assets/img/icon/14.png", alt: 'Image 1' },
    { id: 15, src: publicUrl+"assets/img/icon/15.png", alt: 'Image 1' },
    { id: 16, src:publicUrl+"assets/img/icon/16.png", alt: 'Image 1' },
    { id: 17, src:publicUrl+"assets/img/icon/17.png", alt: 'Image 1' },
    { id: 18, src: publicUrl+"assets/img/icon/18.png", alt: 'Image 1' },
    { id: 19, src: publicUrl+"assets/img/icon/19.png", alt: 'Image 1' },
    { id: 20, src: publicUrl+"assets/img/icon/20.png", alt: 'Image 1' },
    { id: 21, src: publicUrl+"assets/img/icon/21.png", alt: 'Image 1' },
    { id: 22, src: publicUrl+"assets/img/icon/22.png", alt: 'Image 1' },
    { id: 23, src: publicUrl+"assets/img/icon/23.png", alt: 'Image 1' },
    { id: 24, src: publicUrl+"assets/img/icon/24.png", alt: 'Image 1' },
   
  
   
    
  ];
 

  return (
    <div className={`image-container ${showAll ? 'expanded' : 'collapsed'}`}>
      {images.slice(0, showAll ? images.length : maxVisible).map(image => (
        <img src={image.src} alt={image.alt} key={image.id}  className='iconsize iconbox' />
      ))}
      <div style={{width:"525px",textAlign:"center"}}>
      {!showAll && images.length > maxVisible && (
        <button onClick={() => setShowAll(true)} className='showmore btn-1'>
          + {images.length} More 
        </button>
      )}
      {showAll && (
        <button onClick={() => setShowAll(false)}  className='showless btn-1'>
          Show Less
        </button>
      )}
      </div>
    </div>
  );
}

export default ImageContainer;
