import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import {featured} from '../Pageview/Featured';

class Featured extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div>
	
			<div className="ltn__product-slider-area ltn__product-gutter pt-115 plr--7">
			  <div className="container-fluid">
			  
			    <div className="row ltn__product-slider-item-four-active-full-width slick-arrow-1">
			      {/* ltn__product-item */}
				  {featured.map((data) => (
			      <div className="col-lg-12" >
			        <div className="ltn__product-item ltn__product-item-4 text-center---">
			          <div className="product-img go-top">
					  <Link to={`/detailview/${data.id}`}><img src={data.img} alt="#" /></Link>
			            <div className="product-badge">
			              <ul>
			                <li className="sale-badge " style={{fontWeight:"400"}}>Trending</li>
			              </ul>
			            </div>
			            <div className="product-img-location-gallery">
			              <div className="product-img-location">
			                <ul>
			                  <li>
							  <Link to={`/detailview/${data.id}`}><i className="flaticon-pin" style={{color:"white"}} /><span style={{color:"white"}}> {data.address1}</span> </Link>
			                  </li>
			                </ul>
			              </div>
			              
			            </div>
			          </div>
			          <div className="product-info">
			            <div className="product-price">
			              <span>₹ {data.price}<label></label></span>
			            </div>
			            <h2 className="product-title go-top"><Link to={`/detailview/${data.id}`}><div className='fontsizeslider'>{data.propertyname} </div> </Link></h2>
			            
			            
			          </div>
			          <div >
			           
                       <div style={{textAlign:"center"}}>
			            <div className="product-hover-action">
						<div className="btn-wrapper animated go-top">
						<Link to={`/detailview/${data.id}`}  className="theme-btn-1 btn btn-effect-1 " >View Project Details</Link>
			                  </div>
			            </div>
                        </div>
			          </div>
			        </div>
			      </div>
				  ))}
			   
			    </div>
			  </div>
			</div>

			
		   </div>
        }
}

export default Featured;