import React from 'react';
import styled from 'styled-components';
import { Link } from "react-scroll";

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  li {
    padding: 1px 1px;
  }
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: #000000;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    li {
      color: #fff;
    }
  }
`;


const RightNav = ({ open }) => {
  return (
    <Ul open={open}>
      <Link to="section11" smooth={true} duration={500}><li>Home</li></Link>
      <Link to="section22" smooth={true} duration={500}><li>Overview</li></Link>
      <Link to="section33" smooth={true} duration={500}><li>About</li></Link>
      <Link to="section44" smooth={true} duration={500}><li>Specifications</li></Link>
      <Link to="section55" smooth={true} duration={500}><li>Price and Floor Plan</li></Link>
      <Link to="section66" smooth={true} duration={500}><li>Features</li></Link>
      <Link to="section77" smooth={true} duration={500}><li>Amenities</li></Link>
      <Link to="section88" smooth={true} duration={500}><li>Gallery</li></Link>
      <Link to="section99" smooth={true} duration={500}><li>Faq's</li></Link>
      
    </Ul>
  )
}

export default RightNav;