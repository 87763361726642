
let publicUrl = process.env.PUBLIC_URL + "/";
export const data =[
  { id:'HT9', 
  propertyname:"County 107",
  logo:"https://i.imgur.com/QyzeTlu.png",
  modal:publicUrl+"assets/img/modal/hunting.jpg",
  img:publicUrl+"assets/img/banner/county.webp",
  floorPlan11:publicUrl+"assets/img/FloorPlan/county107/1.jpg",
  floorPlan12:publicUrl+"assets/img/FloorPlan/county107/0.jpg",
  floorPlan21:publicUrl+"assets/img/FloorPlan/county107/2.jpg",
  pic1:publicUrl+"assets/img/Gallerynew/County/1.webp",
  pic2:publicUrl+"assets/img/Gallerynew/County/2.webp",
  pic3:publicUrl+"assets/img/Gallerynew/County/3.webp",
  pic4:publicUrl+"assets/img/Gallerynew/County/4.webp",
  pic5:publicUrl+"assets/img/Gallerynew/County/5.webp",
  pic6:publicUrl+"assets/img/Gallerynew/County/6.webp",
  modal:publicUrl+"assets/img/modal/107.jpg",
  siteplan:publicUrl+"assets/img/SitePlan/107.png",
  plan1:"4 BHK",
  plan2:"5 BHK",
  constructionType:"Mivan",
  superareaplan11:"3501 Sq.Ft.",
  superareaplan12:"4482 Sq.Ft.",
  superareaplan21:"6570 Sq.Ft.",
  Noofparking:"500+",
  parkingLevel:"2",
  clubhousesize:"30000 Sq.Ft",
  podium:"Non Podium",
  lift:"3",
  floorvsflats:"4",
  price:"13,500 ",
  address1:"Sector 107, Noida",
  address2:"GH 01A, B ALPHA, Sector 107, Noida, Uttar Pradesh 201301",
  RERANO:"UPRERAPRJ837374",
  status:"Under Construction",
  launchDate:"Dec 2019",
  projectArea:"5 Acres",
  totalFlats:"224 Flats",
  sizes:"3501- 6570 Sq.ft.",
  projectUnits:"245 Flats",
  possessionDate:"Dec, 2023",
  totalTowers:"4 Towers",
  totalFloors:"31 Floors",
  propertyType:"Residential",
  configurations:"4BHK, 5BHK",
  location:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.7486617908994!2d77.37307671455761!3d28.547273394686542!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce555ac338307%3A0x7a7d098909002d22!2sCounty%20107!5e0!3m2!1sen!2sin!4v1679740437344!5m2!1sen!2sin",
  greenArea:"78%",
  ceilingHeight:"10.5 Ft.",
  mainDoorHeight:"8 Ft.",
  internalDoorHeight:"8 Ft.",
  para1:"County 107 is a premium residential project located in Sector 107, Noida, Uttar Pradesh. Developed by ABA Corp, this project offers luxurious and comfortable living spaces that cater to the needs and preferences of modern-day families.",
  para2:"The apartments at County 107 are well-designed and come in 3 BHK and 4 BHK configurations, with sizes ranging from 1895 square feet to 2720 square feet. The apartments are spacious and well-ventilated, and come equipped with a range of modern amenities and facilities, including air conditioning, modular kitchens, high-speed elevators, and 24/7 security services.",
  para3:"County 107 also offers a range of recreational and lifestyle facilities, including a swimming pool, a gymnasium, a club house, a children's play area, and a dedicated sports area. The project also features beautifully landscaped gardens and green spaces that provide a serene and peaceful environment for residents to relax and unwind.",
  para4:"Located in Sector 107, Noida, County 107 enjoys excellent connectivity to major business and commercial hubs in Noida and Delhi. The project is well-connected to major roads and highways, including the Noida-Greater Noida Expressway, the Yamuna Expressway, and the FNG Expressway. It is also located close to major metro stations and bus stops, making it easy for residents to commute to different parts of the city.",
  para5:"Overall, County 107 is a great investment for homebuyers looking for a luxurious and comfortable living experience in the bustling city of Noida. With its range of modern amenities and facilities, excellent connectivity, and serene environment, County 107 is the perfect place to call home.",
  text1:"County 107 is a luxurious residential project located in Sector 107, Noida. The project offers 3 and 4 BHK apartments with premium amenities and elegant design. The apartments are designed to provide a comfortable and luxurious living experience with spacious interiors and modern finishes. The project also incorporates several lifestyle amenities like a clubhouse, swimming pool, gymnasium, and more, making it an ideal choice for those who enjoy an active lifestyle. The project is located in a prime location with excellent connectivity to major landmarks and is well-connected to other parts of the city via major highways and roads. With its prime location, luxurious amenities, and elegant design, County 107 is an excellent choice for those looking for a premium living experience in Noida.",
  faq1:"Why to choose County 107 ?",
  ans1:"Posh location,Excellent Connectivity",
  faq2:"What is County 107 Address?",
  ans2:"GH 01A, B ALPHA, Sector 107, Noida",
  faq3:"What is 4 BHK Starting Price? ",
  ans3:"4 BHK flat of Size 3501 Sq. ft. Starts from Rs. 4.9 Cr  onwards",
  faq4:"How many flat units available in County 107 ?",
  ans4:"224 flats are available in the project",
  faq5:"Does County 107 has kids play area?",
  ans5:"Yes, there is kids playing area available",
  faq6:"What is the RERA no. of County 107 ?",
  ans6:"County 107 RERA NO. UPRERAPRJ837374",
  faq7:"What is the Possession date of County 107 Sector 1 ?",
  ans7:"Expected Possession date is around June 2023 ",
  faq8:"Is this Project is Bank Approved ?",
  ans8:"Yes, County 107 is Approved by SBI Bank and HDFC Bank and many more.",
  faq9:"From where I can download County 107 Brochure ?",
  ans9:"You can download the brochure from the website. Download brochure link:",
  faq10:"Does County 107 have Penthouse ?",
  ans10:"Yes, the Project has Penthouse.",
  
  },
    
   
    
      ];
    
    