import React, { useRef, useState } from "react";
import { Tabs, Tab } from 'react-tabs-scrollable';
import { Link } from 'react-scroll';
import "react-tabs-scrollable/dist/rts.css";

const sections = [
  { id: 'section0', label: 'Overview' },
  { id: 'section1', label: 'About' },
  { id: 'section2', label: 'Specifications' },
  { id: 'section3', label: 'Price and Floor Plan' },
  { id: 'section4', label: 'Features' },
  { id: 'section5', label: 'Amenities' },
  { id: 'section6', label: 'Gallery' },
  { id: 'section7', label: 'FAQs' },
 
  
 
];

const TabMenu = () => {
    const [activeTab, setActiveTab] = React.useState(1);
    
  const handleTabClick = (tabId) => {
    const section = document.getElementById(tabId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
 


  return (
    <Tabs  >
      {sections.map((section) => (
        <Tab key={section.id} tab={section.label}  >
          <Link
            to={section.id}
            spy={true}
            smooth={true}
            duration={500}
            
          >
            {section.label}
          </Link>
        </Tab>
      ))}
    </Tabs>
  );
};

export default TabMenu;