import React from 'react'

const Banner = () => {
  return (
    <div  className='navbarimage'>
      <img src="assets/img/noidaextensionbg.jpg"/>
    </div>
  )
}

export default Banner;
