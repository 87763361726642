import React from 'react'

const Banner = () => {
  return (
    <div style={{height:"350px",marginBottom:"30px"}}>
      <img src="assets/img/sector150bg.jpg"/>
    </div>
  )
}

export default Banner;
