import React from 'react';
import Navbar from '../../components/global-components/navbar-v3';
import ShogGrid from './ExpressWayShop';
import CallToActionV1 from '../../components/section-components/call-to-action-v1';

import Banner from './Bannerexpressway';
import Navbarmobile from '../../design/Navbarmobile';
const ExpresswayRtm = () => {
    return <div>
         <div className='navbarmobile'>
        <Navbarmobile/>
        </div>
        <Navbar />
        <Banner/>
        <ShogGrid />
        <CallToActionV1 />
       
    </div>
}

export default ExpresswayRtm;
