import React from 'react';
import { NavLink } from "react-router-dom";



        let publicUrl = process.env.PUBLIC_URL+'/'




const Gallery = () => {
  return (
<div className="ltn__img-slider-area" style={{position:"relative",top:"-80px",zIndex:"0",marginBottom:"-20px"}}>
				<div className="container-fluid">
				<div className="row ltn__image-slider-4-active slick-arrow-1 slick-arrow-1-inner ltn__no-gutter-all">
					
					<div className="col-lg-12">
					<div className="ltn__img-slide-item-4">
						

						<NavLink to="/detailview/HT3" style={({ isActive }) => ({ 
                            color: isActive ? 'greenyellow' : 'white' })}>
                          <img src={publicUrl+"assets/img/img-slide/13.webp"} alt="Image" />
                        </NavLink>
						<div className="ltn__img-slide-info">
						<div className="ltn__img-slide-info-brief gp-top">
							<h6>The luxury Crib, Sector 43</h6>
							<h1>Godrej Woods</h1>
						</div>
						<div className="btn-wrapper go-top">
							<NavLink to="/detailview/HT3" className="btn theme-btn-1 btn-effect-1 text-uppercase">Details</NavLink>
						</div>
						</div>
					</div>
					</div>
					<div className="col-lg-12">
					<div className="ltn__img-slide-item-4">
					
						<NavLink to="/detailview/HT12"><img src={publicUrl+"assets/img/img-slide/23.webp"} alt="Image" /></NavLink>
						
						<div className="ltn__img-slide-info">
						<div className="ltn__img-slide-info-brief gp-top">
							<h6>Central City Gem, Sector 75</h6>
							<h1>Burj Noida</h1>
						</div>
						<div className="btn-wrapper go-top">
							<NavLink to="/detailview/HT12" className="btn theme-btn-1 btn-effect-1 text-uppercase">Details</NavLink>
						</div>
						</div>
					</div>
					</div>
					<div className="col-lg-12">
					<div className="ltn__img-slide-item-4">
						
					<img src={publicUrl+"assets/img/img-slide/24.webp"} alt="Image" />
					
						<div className="ltn__img-slide-info">
						<div className="ltn__img-slide-info-brief gp-top">
							<h6>Paradise Towards your Joy, Sector 94</h6>
							<h1>M3M</h1>
						</div>
					
						</div>
					</div>
					</div>
					<div className="col-lg-12">
					<div className="ltn__img-slide-item-4">
						
						<NavLink to="/detailview/HT13">		<img src={publicUrl+"assets/img/img-slide/22.webp"} alt="Image" /></NavLink>
					
						<div className="ltn__img-slide-info">
						<div className="ltn__img-slide-info-brief gp-top">
							<h6>Dynasty Living, Sector 144</h6>
							<h1>Gulshan Dynasty</h1>
						</div>
						<div className="btn-wrapper go-top">
							<NavLink to="/detailview/HT13" className="btn theme-btn-1 btn-effect-1 text-uppercase">Details</NavLink>
						</div>
						</div>
						
					</div>
					
					</div>
					
					<div className="col-lg-12">
					<div className="ltn__img-slide-item-4">
						
						<NavLink to="/detailview/HT9"><img src={publicUrl+"assets/img/img-slide/21.jpg"} alt="Image" /></NavLink>
						
						<div className="ltn__img-slide-info">
						<div className="ltn__img-slide-info-brief gp-top">
							<h6>Heart of Sector 107</h6>
							<h1>County 107</h1>
						</div>
						<div className="btn-wrapper go-top">
							<NavLink to="/detailview/HT9"  className="btn theme-btn-1 btn-effect-1 text-uppercase">Details</NavLink>
						</div>
						</div>
					</div>
					</div>
					
				</div>
				</div>
			</div>
  )
}

export default Gallery
