import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Social from '../section-components/social';
import Copyright from './copyright';

class Footer_v1 extends Component {

    componentDidMount() {

    	const $ = window.$;
    	
        let publicUrl = process.env.PUBLIC_URL+'/'
        const minscript = document.createElement("script");
        minscript.async = true;
        minscript.src = publicUrl + "assets/js/main.js";

        document.body.appendChild(minscript);

         $('.go-top').find('a').on('click', function () {

			$(".quarter-overlay").fadeIn(1);

				$(window).scrollTop(0);

			setTimeout(function(){
			    	$(".quarter-overlay").fadeOut(300);
				}, 800);

        });


		$(document).on('click','.theme-btn-1 ', function(){ 
            $( 'div' ).removeClass( 'modal-backdrop' );
            $( 'div' ).removeClass( 'show' );
            $( 'div' ).removeClass( 'fade' );
			$('body').attr("style", "");
        });
    }

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = "Footer logo"

        return (
				<footer className="ltn__footer-area  ">
				  <div className="footer-top-area  section-bg-2 plr--5">
				    <div className="container-fluid">
				      <div className="row">
				        <div className="col-xl-3 col-md-6 col-sm-6 col-12">
				          <div className="footer-widget footer-about-widget footwidth">
				            <div className="footer-logo">
				              <div className="site-logo">
				                <img src={publicUrl+"assets/img/Houselogo.png"} alt="Logo" style={{width:"200px",padding:"15px"}} />
				              </div>
				            </div>
				            <h6 style={{fontSize:"14px",fontWeight:"400",fontFamily:"inherit"}}>HouseThat is closely associated with real estate marketplace to provide lucrative and exclusive buy and sell options. We find and share vital information about real estate so that it turns your life full with happiness and prosperity.</h6>
				            <div className="footer-address" style={{display:"block",marginTop:"20px"}}>
				              <div style={{display:"flex",height:"30px"}}>
				                  <div >
				                    <i className="icon-placeholder" style={{fontSize:"12px",verticalAlign:"bottom"}} />
				                  </div>
				                  <div  >
				                    <p style={{fontFamily:"poppins",color:"#dda612"}}>HouseThat, B-34, 3rd Floor, Sector 67, NOIDA</p>
				                  </div>
								  </div>
								  <div style={{display:"flex",height:"30px"}} className="footmargin">
				                  <div >
				                    <i className="icon-call" style={{fontSize:"12px",verticalAlign:"bottom"}} />
				                  </div>
				                  <div >
				                    <p style={{fontFamily:"poppins"}} ><a href="tel:+0123-456789" style={{color:"#dda612",fontWeight:"500"}}>+91-9313-388-388</a></p>
				                  </div>
								  </div>
								  <div style={{display:"flex",height:"30px"}}>
				                  <div >
				                    <i className="icon-mail" style={{fontSize:"12px",verticalAlign:"bottom"}} />
				                  </div>
				                  <div >
				                    <p style={{fontFamily:"poppins"}}><a href="mailto:example@example.com" style={{color:"#dda612",fontWeight:"500"}}>info@luxuryapartment.co.in</a></p>
				                  </div>
								  </div>
				               
				             
				            </div>
				         
				          </div>
				        </div>
				        <div className="col-xl-2 col-md-6 col-sm-6 col-12">
				          <div className="footer-widget footer-menu-widget clearfix">
				            <h4 className="footer-title">Quick Links</h4>
				            <div className="footer-menu go-top">
				              <ul>
				                <li><Link to="/"  style={{color:"white"}}>About</Link></li>
				                <li><Link to="/" style={{color:"white"}}>Blog</Link></li>
				                <li><Link to="/all" style={{color:"white"}}>All Properties</Link></li>
				                <li><Link to="/" style={{color:"white"}}>Contact Us</Link></li>
								<li><Link to="/" style={{color:"white"}}>Privacy Policy</Link></li>	                
				              </ul>

				            </div>
							<div className="ltn__social-media mt-20">
						    	<Social />
				            </div>
				          </div>
				        </div>
				       
				       
				       
				      </div>
				    </div>
				  </div>
				  <Copyright />
				</footer>
        )
    }
}


export default Footer_v1