import React, { useState, useEffect } from 'react';
import './App.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ImageContainer from "./ImageContainer";
import Navbar from "./Navbar";
import Download from '../Pageview/Downloadbrochure';
import Related from './Related'
import Slider from './Slider';
import Accordion from './Accordion';
import Gallery from './Gallery';
import { useParams } from "react-router-dom";
import { data } from "../Pageview/data";
import Schedule from '../Pageview/Schedule';
import EnquireNow from './EnquireNow';
const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text.slice(0, 350) : text}
      <span style={{ fontSize: "16px", fontWeight: "600", color: "#3a3a3a" }} onClick={toggleReadMore} className="read-or-hide">

        {isReadMore ? "......Read More" : " Show Less"}

      </span>
    </p>
  );
};

function App() {

  const [isFixed, setIsFixed] = useState(false);


  let publicUrl = process.env.PUBLIC_URL + "/";

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const div = document.querySelector('.my-div');
    const divTop = div.offsetTop;
    const scrollPosition = window.scrollY;
    if (scrollPosition > window.innerHeight * 0.7) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  const divStyle = isFixed ? { position: "fixed", top: "100px", background: "#303030", boxShadow: "rgb(48 48 48) 0px 3px 9px 0px", width: "100%", zIndex: "999", height: "80px" } : { display: "none" };

  const { id } = useParams();

  console.log(id);

  const filteredData = data.filter((item) => {
    return Object.values(item).includes(id);
  });

  console.log(filteredData);

  return (



    <div className="mobile ">
      <div className="my-div" style={divStyle}>
        <Navbar />
      </div>

      <div class="wrapper element maincontainer"  >
        {filteredData.map((data) => (

          <div class="left-column">
            <div class="content">

              <div class="content">


                <div class="wrappercontainer" id="section0"  >
                  <div class="heading" >
                    {data.propertyname} Overview
                  </div>
                  <div class="flex-container">

                    <div class="box">
                      <div class="icon" ><img src="https://i.imgur.com/bk8IsgS.png" style={{ width: "25px" }} /></div>
                      <div class="text">
                        <div class="title">Project Area</div>
                        <div class="description">{data.projectArea}</div>
                      </div>
                    </div>
                    <div class="box">
                      <div class="icon"><img src="https://i.imgur.com/41k43FW.png" style={{ width: "25px" }} /><i class=""></i></div>
                      <div class="text">
                        <div class="title">Sizes</div>
                        <div class="description">{data.sizes}</div>
                      </div>
                    </div>
                    <div class="box">
                      <div class="icon" ><img src="https://i.imgur.com/rvSlGhS.png" style={{ width: "25px" }} /></div>
                      <div class="text">
                        <div class="title">Project Units</div>
                        <div class="description">{data.totalFlats}</div>
                      </div>
                    </div>
                    <div class="box">
                      <div class="icon" ><img src="https://i.imgur.com/LC2PBFe.png" style={{ width: "25px" }} /></div>
                      <div class="text">
                        <div class="title">Price Range</div>
                        <div class="description">{data.price}
                        </div>
                      </div>
                    </div>
                    <div class="box">
                      <div class="icon" ><img src="https://i.imgur.com/S3Alxfp.png" style={{ width: "25px" }} /></div>
                      <div class="text">
                        <div class="title">Possession Starts</div>
                        <div class="description">{data.possessionDate}</div>
                      </div>
                    </div>
                    <div class="box">
                      <div class="icon" ><img src="https://i.imgur.com/iGCD7vO.png" style={{ width: "25px" }} /></div>
                      <div class="text">
                        <div class="title">Configurations</div>
                        <div class="description">{data.configurations}</div>
                      </div>
                    </div>
                    <div class="box">
                      <div class="icon" ><img src="https://i.imgur.com/OIjc1dH.png" style={{ width: "25px" }} /></div>
                      <div class="text">
                        <div class="title">Rera Id</div>
                        <div class="description">{data.RERANO}</div>
                      </div>
                    </div>

                  </div>
                  <div style={{ height: "80px", display: "flex", justifyContent: "center" }}>
                    <button class="btn-1" style={{ width: "18%", position: "relative", bottom: "22px", fontSize: "12px" }}><a href="tel:9313388388" style={{ fontWeight: "550px" }}><img src={publicUrl + "assets/img/whatsapp.png"} style={{ width: "25px", fontWeight: "550px" }} /> WhatsApp</a></button>

                    <button class="btn-1" style={{ width: "22%", position: "relative", bottom: "22px", right: "221px", fontSize: "12px" }}>
                      <div style={{ display: "flex" }}>
                        <div><img src={publicUrl + "assets/img/gps.png"} style={{ width: "25px", marginLeft: "10px" }} /></div>
                        <div style={{ marginLeft: "5px", fontSize: "14px", color: "black" }}> <Schedule /></div>
                      </div> </button>
                  </div>
                </div>


                <div>

                </div>

                <div class="wrappercontainer" id="section1">
                  <div class="heading" >
                    About {data.propertyname}
                  </div>
                  <p>
                    <ReadMore>
                      {data.text1}
                    </ReadMore>
                  </p>
                </div>

                <div class="wrappercontainer" id="section2">
                  <div class="heading" >
                    {data.propertyname} Specifications
                  </div>

                  <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                    <div>
                      <div className='propertywise' >

                        <div style={{ width: "200px" }} className='propertywiseleft'> <img src={publicUrl + "assets/img/changingcircle.png"} style={{ width: "25px" }} /> &nbsp;Status : </div>
                        <div style={{ width: "200px" }} className='propertywiseright' >{data.status}</div>
                      </div>
                      <div className='propertywise'>
                        <div style={{ width: "200px" }} className='propertywiseleft'><img src={publicUrl + "assets/img/newproduct.png"} style={{ width: "25px" }} /> &nbsp;Launch Date : </div>
                        <div style={{ width: "200px" }} className='propertywiseright'>{data.launchDate}</div>
                      </div>
                      <div className='propertywise'>
                        <div style={{ width: "200px" }} className='propertywiseleft'><img src={publicUrl + "assets/img/key.png"} style={{ width: "25px" }} /> &nbsp;Possession Date :</div>
                        <div style={{ width: "200px" }} className='propertywiseright'>{data.possessionDate}</div>
                      </div>
                      <div className='propertywise'>
                        <div style={{ width: "200px" }} className='propertywiseleft'><img src={publicUrl + "assets/img/rera.png"} style={{ width: "25px" }} /> &nbsp;Rera ID :</div>
                        <div style={{ width: "200px" }} className='propertywiseright'>{data.RERANO}</div>
                      </div>
                      <div className='propertywise'>
                        <div style={{ width: "200px" }} className='propertywiseleft'><img src={publicUrl + "assets/img/area.png"} style={{ width: "25px" }} /> &nbsp;Project Plot Area :</div>
                        <div style={{ width: "200px" }} className='propertywiseright'>{data.projectArea}</div>
                      </div>
                      <div className='propertywise'>
                        <div style={{ width: "200px" }} className='propertywiseleft'><img src={publicUrl + "assets/img/savetheplanet.png"} style={{ width: "25px" }} /> &nbsp;Green Area : </div>
                        <div style={{ width: "200px" }} className='propertywiseright'>{data.greenArea}</div>
                      </div>
                      <div className='propertywise'>
                        <div style={{ width: "200px" }} className='propertywiseleft'><img src={publicUrl + "assets/img/elevator.png"} style={{ width: "25px" }} /> &nbsp;Lift Per Tower :</div>
                        <div style={{ width: "200px" }} className='propertywiseright'>{data.liftpertower}</div>
                      </div>
                      <div className='propertywise'>
                        <div style={{ width: "200px" }} className='propertywiseleft'><img src={publicUrl + "assets/img/hallway.png"} style={{ width: "25px" }} /> &nbsp;Floor v/s Flats :</div>
                        <div style={{ width: "200px" }} className='propertywiseright'>{data.floorvsflats}</div>
                      </div>
                    </div>
                    <div>

                      <div className='propertywise'>
                        <div style={{ width: "200px" }} className='propertywiseleft'><img src={publicUrl + "assets/img/podium.png"} style={{ width: "25px" }} /> &nbsp;Podium / Non-Podium :</div>
                        <div style={{ width: "200px" }} className='propertywiseright'>{data.podium}</div>
                      </div>
                      <div className='propertywise'>
                        <div style={{ width: "200px", textAlign: "left" }} className='propertywiseleft'><img src={publicUrl + "assets/img/club.png"} style={{ width: "25px" }} /> &nbsp;Club House Size :</div>
                        <div style={{ width: "200px", textAlign: "left" }} className='propertywiseright'>{data.clubhousesize}</div>
                      </div>
                      <div className='propertywise'>
                        <div style={{ width: "200px" }} className='propertywiseleft'><img src={publicUrl + "assets/img/building.png"} style={{ width: "25px" }} /> &nbsp;Total Floors :</div>
                        <div style={{ width: "200px" }} className='propertywiseright'>{data.totalFloors}</div>
                      </div>
                      <div className='propertywise'>
                        <div style={{ width: "200px" }} className='propertywiseleft'><img src={publicUrl + "assets/img/hook.png"} style={{ width: "25px" }} /> &nbsp;Construction Type :</div>
                        <div style={{ width: "200px" }} className='propertywiseright'>{data.constructionType}</div>
                      </div>
                      <div className='propertywise'>
                        <div style={{ width: "200px" }} className='propertywiseleft'><img src={publicUrl + "assets/img/tower.png"} style={{ width: "25px" }} /> &nbsp;Total Towers :</div>
                        <div style={{ width: "200px" }} className='propertywiseright'>{data.totalTowers}</div>
                      </div>
                      <div className='propertywise'>
                        <div style={{ width: "200px" }} className='propertywiseleft'><img src={publicUrl + "assets/img/apartment.png"} style={{ width: "25px" }} /> &nbsp;Total Flats :</div>
                        <div style={{ width: "200px" }} className='propertywiseright'>{data.totalFlats}</div>
                      </div>
                      <div className='propertywise'>
                        <div style={{ width: "200px" }} className='propertywiseleft'><img src={publicUrl + "assets/img/parking.png"} style={{ width: "25px" }} /> &nbsp;Parking Levels :</div>
                        <div style={{ width: "200px" }} className='propertywiseright'>{data.parkingLevel}</div>
                      </div>

                    </div>
                  </div>

                </div>



                <div class="wrappercontainer -gallery" id="section3">
                  <div class="heading" >
                    {data.propertyname} Price & Floor Plan
                    <EnquireNow />
                  </div>

                  <Tabs style={{ width: '500px' }}>
                    <TabList style={{
                      fontSize: '16px',
                      margin: '20px',
                      color: "#000",
                      borderRadius: '10px',
                    }}>
                      {data.plan1 ? <>
                        <Tab style={{
                          background: 'white',
                          borderRadius: '5px', textAlign: "center"
                        }}>{data.plan1}</Tab>
                      </> : <></>}

                      {data.plan2 ? <>
                        <Tab style={{
                          background: 'white',
                          borderRadius: '5px', textAlign: "center"
                        }}>{data.plan2}</Tab>
                      </> : <></>}
                      {data.plan3 ? <>
                        <Tab style={{
                          background: 'white',
                          borderRadius: '5px', textAlign: "center"
                        }}>{data.plan3}</Tab>
                      </> : <></>}
                      {data.plan4 ? <>
                        <Tab style={{
                          background: 'white',
                          borderRadius: '5px', textAlign: "center"
                        }}>{data.plan4}</Tab>
                      </> : <></>}
                    </TabList>
                    <TabPanel style={{
                      fontSize: '14px',
                      margin: '20px'
                    }}>
                      <Tabs defaultIndex={0}>
                        <TabList>
                          {data.superareaplan11 ? <>
                            <Tab style={{
                              background: 'white',
                              borderRadius: '5px'
                            }}>{data.superareaplan11}</Tab>
                          </> : <></>}

                          {data.superareaplan12 ? <>
                            <Tab style={{
                              background: 'white',
                              borderRadius: '5px'
                            }}>{data.superareaplan12}</Tab>
                          </> : <></>}
                          {data.superareaplan13 ? <>
                            <Tab style={{
                              background: 'white',
                              borderRadius: '5px'
                            }}>{data.superareaplan13}</Tab>
                          </> : <></>}
                          {data.superareaplan14 ? <>
                            <Tab style={{
                              background: 'white',
                              borderRadius: '5px'
                            }}>{data.superareaplan14}.</Tab>
                          </> : <></>}
                        </TabList>
                        <TabPanel>


                          <div style={{ textAlign: "center", height: "280px", width: "800px", padding: "30px" }}>
                            <a href={data.floorPlan11} data-rel="lightcase:myCollection">
                              <img src={data.floorPlan11} style={{ width: "250px", border: "2px solid #b48811" }} />
                            </a>

                          </div>

                        </TabPanel>
                        <TabPanel>


                          <div style={{ textAlign: "center", height: "280px", width: "800px", padding: "30px" }}>
                            <a href={data.floorPlan12} data-rel="lightcase:myCollection">
                              <img src={data.floorPlan12} style={{ width: "250px", border: "2px solid #b48811" }} />
                            </a>

                          </div>

                        </TabPanel>
                        <TabPanel>


                          <div style={{ textAlign: "center", height: "280px", width: "800px", padding: "30px" }}>
                            <a href={data.floorPlan13} data-rel="lightcase:myCollection">
                              <img src={data.floorPlan13} style={{ width: "250px", border: "2px solid #b48811" }} />
                            </a>

                          </div>
                        </TabPanel>
                        <TabPanel>


                          <div style={{ textAlign: "center", height: "280px", width: "800px", padding: "30px" }}>
                            <a href={data.floorPlan14} data-rel="lightcase:myCollection">
                              <img src={data.floorPlan14} style={{ width: "250px", border: "2px solid #b48811" }} />
                            </a>

                          </div>
                        </TabPanel>
                      </Tabs>
                    </TabPanel>
                    <TabPanel style={{
                      fontSize: '20px',
                      margin: '20px',
                      width: "800px"
                    }}>
                      <Tabs>
                        <TabList>
                          {data.superareaplan21 ? <>
                            <Tab style={{
                              background: 'white',
                              borderRadius: '5px'
                            }}>{data.superareaplan21}</Tab>
                          </> : <></>}
                          {data.superareaplan22 ? <>
                            <Tab style={{
                              background: 'white',
                              borderRadius: '5px'
                            }}>{data.superareaplan22}</Tab>
                          </> : <></>}
                          {data.superareaplan23 ? <>
                            <Tab style={{
                              background: 'white',
                              borderRadius: '5px'
                            }}>{data.superareaplan23}</Tab>
                          </> : <></>}
                          {data.superareaplan24 ? <>
                            <Tab style={{
                              background: 'white',
                              borderRadius: '5px'
                            }}>{data.superareaplan24}</Tab>
                          </> : <></>}

                        </TabList>
                        <TabPanel>


                          <div style={{ textAlign: "center", height: "280px", width: "800px", padding: "30px" }}>
                            <a href={data.floorPlan21} data-rel="lightcase:myCollection">
                              <img src={data.floorPlan21} style={{ width: "250px", border: "2px solid #b48811" }} />
                            </a>

                          </div>
                        </TabPanel>
                        <TabPanel>


                          <div style={{ textAlign: "center", height: "280px", width: "800px", padding: "30px" }}>
                            <a href={data.floorPlan22} data-rel="lightcase:myCollection">
                              <img src={data.floorPlan22} style={{ width: "250px", border: "2px solid #b48811" }} />
                            </a>

                          </div>
                        </TabPanel>
                        <TabPanel>


                          <div style={{ textAlign: "center", height: "280px", width: "800px", padding: "30px" }}>
                            <a href={data.floorPlan23} data-rel="lightcase:myCollection">
                              <img src={data.floorPlan23} style={{ width: "250px", border: "2px solid #b48811" }} />
                            </a>

                          </div>
                        </TabPanel>
                        <TabPanel>


                          <div style={{ textAlign: "center", height: "280px", width: "800px", padding: "30px" }}>
                            <a href={data.floorPlan24} data-rel="lightcase:myCollection">
                              <img src={data.floorPlan24} style={{ width: "250px", border: "2px solid #b48811" }} />
                            </a>

                          </div>
                        </TabPanel>

                      </Tabs>

                    </TabPanel>
                    <TabPanel style={{
                      fontSize: '20px',
                      margin: '20px'
                    }}>
                      <Tabs>
                        <TabList>
                          {data.superareaplan31 ? <>
                            <Tab style={{
                              background: 'white',
                              borderRadius: '5px'
                            }}>{data.superareaplan31}</Tab>
                          </> : <></>}
                          {data.superareaplan32 ? <>
                            <Tab style={{
                              background: 'white',
                              borderRadius: '5px'
                            }}>{data.superareaplan32}</Tab>
                          </> : <></>}
                          {data.superareaplan33 ? <>
                            <Tab style={{
                              background: 'white',
                              borderRadius: '5px'
                            }}>{data.superareaplan33}</Tab>
                          </> : <></>}

                        </TabList>
                        <TabPanel>


                          <div style={{ textAlign: "center", height: "280px", width: "800px", padding: "30px" }}>
                            <a href={data.floorPlan31} data-rel="lightcase:myCollection">
                              <img src={data.floorPlan31} style={{ width: "250px", border: "2px solid #b48811" }} />
                            </a>

                          </div>
                        </TabPanel>
                        <TabPanel>


                          <div style={{ textAlign: "center", height: "280px", width: "800px", padding: "30px" }}>
                            <a href={data.floorPlan32} data-rel="lightcase:myCollection">
                              <img src={data.floorPlan32} style={{ width: "250px", border: "2px solid #b48811" }} />
                            </a>

                          </div>
                        </TabPanel>
                        <TabPanel>


                          <div style={{ textAlign: "center", height: "280px", width: "800px", padding: "30px" }}>
                            <a href={data.floorPlan33} data-rel="lightcase:myCollection">
                              <img src={data.floorPlan33} style={{ width: "250px", border: "2px solid #b48811" }} />
                            </a>

                          </div>
                        </TabPanel>
                        <TabPanel>


                          <div style={{ textAlign: "center", height: "280px", width: "800px", padding: "30px" }}>
                            <a href={data.floorPlan34} data-rel="lightcase:myCollection">
                              <img src={data.floorPlan34} style={{ width: "250px", border: "2px solid #b48811" }} />
                            </a>

                          </div>
                        </TabPanel>
                        <TabPanel>


                          <div style={{ textAlign: "center", height: "280px", width: "800px", padding: "30px" }}>
                            <a href={data.floorPlan31} data-rel="lightcase:myCollection">
                              <img src={data.floorPlan31} style={{ width: "250px", border: "2px solid #b48811" }} />
                            </a>

                          </div>
                        </TabPanel>

                      </Tabs>

                    </TabPanel>
                    <TabPanel style={{
                      fontSize: '20px',
                      margin: '20px',
                      width: "1000px"
                    }}>
                      <Tabs>
                        <TabList>
                          {data.superareaplan41 ? <>
                            <Tab style={{
                              background: 'white',
                              borderRadius: '5px'
                            }}>{data.superareaplan41}</Tab>
                          </> : <></>}
                          {data.superareaplan42 ? <>
                            <Tab style={{
                              background: 'white',
                              borderRadius: '5px'
                            }}>{data.superareaplan42}</Tab>
                          </> : <></>}
                          {data.superareaplan43 ? <>
                            <Tab style={{
                              background: 'white',
                              borderRadius: '5px'
                            }}>{data.superareaplan43}</Tab>
                          </> : <></>}
                          {data.superareaplan44 ? <>
                            <Tab style={{
                              background: 'white',
                              borderRadius: '5px'
                            }}>{data.superareaplan44}</Tab>
                          </> : <></>}

                        </TabList>
                        <TabPanel>


                          <div style={{ textAlign: "center", height: "280px", width: "800px", padding: "30px" }}>
                            <a href={data.floorPlan41} data-rel="lightcase:myCollection">
                              <img src={data.floorPlan41} style={{ width: "250px", border: "2px solid #b48811" }} />
                            </a>
                            <div style={{ diplay: "flex" }}>
                              <div style={{ width: "700px" }}> Price</div>
                              <div style={{ width: "700px" }}></div>
                            </div>
                          </div>
                        </TabPanel>
                        <TabPanel>


                          <div style={{ textAlign: "center", height: "280px", width: "800px", padding: "30px" }}>
                            <a href={data.floorPlan42} data-rel="lightcase:myCollection">
                              <img src={data.floorPlan42} style={{ width: "250px", border: "2px solid #b48811" }} />
                            </a>

                          </div>
                        </TabPanel>
                        <TabPanel>


                          <div style={{ textAlign: "center", height: "280px", width: "800px", padding: "30px" }}>
                            <a href={data.floorPlan43} data-rel="lightcase:myCollection">
                              <img src={data.floorPlan43} style={{ width: "250px", border: "2px solid #b48811" }} />
                            </a>

                          </div>
                        </TabPanel>
                        <TabPanel>


                          <div style={{ textAlign: "center", height: "280px", width: "800px", padding: "30px" }}>
                            <a href={data.floorPlan44} data-rel="lightcase:myCollection">
                              <img src={data.floorPlan44} style={{ width: "250px", border: "2px solid #b48811" }} />
                            </a>

                          </div>
                        </TabPanel>

                      </Tabs>

                    </TabPanel>
                  </Tabs>




                </div>





                <div class="wrappercontainer" id="section4">
                  <div class="heading" >
                    {data.propertyname} Features
                    <EnquireNow />
                  </div>

                  <Tabs style={{ width: '800px' }}>
                    <TabList style={{
                      fontSize: '16px',
                      margin: '20px',
                      color: "#000",
                      borderRadius: '10px',

                    }}>
                      <Tab style={{
                        background: 'white',
                        borderRadius: '5px'
                      }}><div style={{ display: "flex" }}>
                          <div><img src="https://c.housingcdn.com/demand/s/client/common/assets/homeIconTabActive.322f4b34.svg" /></div>
                          <div>  &nbsp; All</div>
                        </div> </Tab>
                      <Tab style={{
                        background: 'white',
                        borderRadius: '5px'
                      }}>
                        <div style={{ display: "flex" }}>
                          <div><img src="https://c.housingcdn.com/demand/s/client/common/assets/floorCounterTab.7b7647c5.svg" /></div>
                          <div>  &nbsp;   Floor and Counter</div>
                        </div>
                      </Tab>
                      <Tab style={{
                        background: 'white',
                        borderRadius: '5px'
                      }}>
                        <div style={{ display: "flex" }}>
                          <div><img src="https://c.housingcdn.com/demand/s/client/common/assets/fittingTab.3c99916b.svg" style={{ width: "20px" }} /></div>
                          <div>  &nbsp;    Fitting</div>
                        </div>
                      </Tab>
                      <Tab style={{
                        background: 'white',
                        borderRadius: '5px'
                      }}>
                        <div style={{ display: "flex" }}>
                          <div> <img src="https://c.housingcdn.com/demand/s/client/common/assets/wallsCeilingTab.f6b47feb.svg" style={{ width: "20px" }} /></div>
                          <div>  &nbsp;    Wall and Ceiling</div>
                        </div>

                      </Tab>
                    </TabList>
                    <TabPanel style={{
                      fontSize: '14px',
                      margin: '20px'
                    }}>
                      <Tabs defaultIndex={0}>

                        <TabPanel>
                          <div className="csss-rewqt">
                            <div className="css-erp5gv "> Floor & Counter</div>
                            <div style={{ display: "flex" }} className="css-12145tgb">
                              <div style={{ width: "33%", display: "block" }}>
                                <div className='css-1tzoggr '>Living/Dining</div>
                                <div >Imported Marble</div>
                              </div>

                              <div style={{ width: "33%", display: "block" }}>
                                <div className='css-1tzoggr '>Master Bedroom</div>
                                <div >Laminated Wooden</div>
                              </div>
                              <div style={{ width: "33%", display: "block" }}>
                                <div className='css-1tzoggr '>Other Bedroom</div>
                                <div >Laminated Wooden</div>
                              </div>
                            </div>
                          </div>
                          <div className="csss-rewqt">
                            <div className="css-erp5gv ">Fitting</div>
                            <div style={{ display: "flex" }} className="css-12145tgb">
                              <div style={{ width: "33%", display: "block" }}>
                                <div className='css-1tzoggr '>Electrical</div>
                                <div >Concealed Fire Resistant Copper Wiring</div>
                              </div>

                              <div style={{ width: "33%", display: "block" }}>
                                <div className='css-1tzoggr '>Toilets</div>
                                <div >Wash Basin, GI / CPVC / PPR Pipes</div>
                              </div>
                              <div style={{ width: "33%", display: "block" }}>
                                <div className='css-1tzoggr '>Kitchen</div>
                                <div className='divheight'>Granite platform with stainless steel sink, Exhaust Fan</div>
                              </div>
                            </div>
                          </div>
                          <div className="csss-rewqt">
                            <div className="css-erp5gv ">Wall & Ceiling</div>
                            <div style={{ display: "flex" }} className="css-12145tgb">
                              <div style={{ width: "33%", display: "block" }}>
                                <div className='css-1tzoggr '>Interior</div>
                                <div >Oil Bound Distemper</div>
                              </div>

                              <div style={{ width: "33%", display: "block" }}>
                                <div className='css-1tzoggr '>Exterior</div>
                                <div >Texture Paint</div>
                              </div>
                              <div style={{ width: "33%", display: "block" }}>
                                <div className='css-1tzoggr '>Kitchen</div>
                                <div className='divheight'>Designer Tiles Dado up to 2 Feet Height Above Platform</div>
                              </div>
                            </div>
                          </div>

                        </TabPanel>

                      </Tabs>
                    </TabPanel>
                    <TabPanel style={{
                      fontSize: '14px',
                      margin: '20px'
                    }}>
                      <Tabs defaultIndex={0}>

                        <TabPanel>
                          <div className="csss-rewqt">
                            <div className="css-erp5gv "> </div>
                            <div style={{ display: "flex" }} className="css-12145tgb">
                              <div style={{ width: "33%", display: "block" }}>
                                <div className='css-1tzoggr '>Electrical</div>
                                <div >Imported Marble</div>
                              </div>

                              <div style={{ width: "33%", display: "block" }}>
                                <div className='css-1tzoggr '>Master Bedroom</div>
                                <div >Laminated Wooden</div>
                              </div>
                              <div style={{ width: "33%", display: "block" }}>
                                <div className='css-1tzoggr '>Other Bedroom</div>
                                <div >Laminated Wooden</div>
                              </div>
                            </div>
                          </div>
                          <div className="csss-rewqt">
                            <div className="css-erp5gv "> </div>
                            <div style={{ display: "flex" }} className="css-12145tgb">
                              <div style={{ width: "33%", display: "block" }}>
                                <div className='css-1tzoggr '>Kitchen</div>
                                <div >Vitrified Tiles</div>
                              </div>

                              <div style={{ width: "33%", display: "block" }}>
                                <div className='css-1tzoggr '>Toilets</div>
                                <div >Vitrified Tiles</div>
                              </div>
                              <div style={{ width: "33%", display: "block" }}>
                                <div className='css-1tzoggr '>Balcony</div>
                                <div >Anti Skid Tiles</div>
                              </div>
                            </div>
                          </div>

                        </TabPanel>

                      </Tabs>
                    </TabPanel>
                    <TabPanel style={{
                      fontSize: '14px',
                      margin: '20px'
                    }}>
                      <Tabs defaultIndex={0}>

                        <TabPanel>
                          <div className="csss-rewqt">
                            <div className="css-erp5gv "> </div>
                            <div style={{ display: "flex" }} className="css-12145tgb">
                              <div style={{ width: "33%", display: "block" }}>
                                <div className='css-1tzoggr '>Living/Dining</div>
                                <div >Imported Marble</div>
                              </div>

                              <div style={{ width: "33%", display: "block" }}>
                                <div className='css-1tzoggr '>Master Bedroom</div>
                                <div >Laminated Wooden</div>
                              </div>
                              <div style={{ width: "33%", display: "block" }}>
                                <div className='css-1tzoggr '>Other Bedroom</div>
                                <div >Laminated Wooden</div>
                              </div>
                            </div>
                          </div>
                          <div className="csss-rewqt">
                            <div className="css-erp5gv "> </div>
                            <div style={{ display: "flex" }} className="css-12145tgb">
                              <div style={{ width: "33%", display: "block" }}>
                                <div className='css-1tzoggr '>Kitchen</div>
                                <div >Vitrified Tiles</div>
                              </div>

                              <div style={{ width: "33%", display: "block" }}>
                                <div className='css-1tzoggr '>Toilets</div>
                                <div >Vitrified Tiles</div>
                              </div>
                              <div style={{ width: "33%", display: "block" }}>
                                <div className='css-1tzoggr '>Balcony</div>
                                <div >Anti Skid Tiles</div>
                              </div>
                            </div>
                          </div>

                        </TabPanel>

                      </Tabs>
                    </TabPanel>
                    <TabPanel style={{
                      fontSize: '14px',
                      margin: '20px'
                    }}>
                      <Tabs defaultIndex={0}>

                        <TabPanel>
                          <div className="csss-rewqt">
                            <div className="css-erp5gv "> </div>
                            <div style={{ display: "flex" }} className="css-12145tgb">
                              <div style={{ width: "33%", display: "block" }}>
                                <div className='css-1tzoggr '>Living/Dining</div>
                                <div >Imported Marble</div>
                              </div>

                              <div style={{ width: "33%", display: "block" }}>
                                <div className='css-1tzoggr '>Master Bedroom</div>
                                <div >Laminated Wooden</div>
                              </div>
                              <div style={{ width: "33%", display: "block" }}>
                                <div className='css-1tzoggr '>Other Bedroom</div>
                                <div >Laminated Wooden</div>
                              </div>
                            </div>
                          </div>
                          <div className="csss-rewqt">
                            <div className="css-erp5gv "> </div>
                            <div style={{ display: "flex" }} className="css-12145tgb">
                              <div style={{ width: "33%", display: "block" }}>
                                <div className='css-1tzoggr '>Kitchen</div>
                                <div >Vitrified Tiles</div>
                              </div>

                              <div style={{ width: "33%", display: "block" }}>
                                <div className='css-1tzoggr '>Toilets</div>
                                <div >Vitrified Tiles</div>
                              </div>
                              <div style={{ width: "33%", display: "block" }}>
                                <div className='css-1tzoggr '>Balcony</div>
                                <div >Anti Skid Tiles</div>
                              </div>
                            </div>
                          </div>

                        </TabPanel>

                      </Tabs>
                    </TabPanel>
                  </Tabs>




                </div>



                <div class="wrappercontainer" id="section5" style={{ minHeight: "200px" }}>
                  <div class="heading" >
                    {data.propertyname} Amenities
                    <EnquireNow />
                  </div>
                  <ImageContainer />
                </div>


                <div class="wrappercontainer" id="section6" >
                  <div class="heading" >
                    Gallery
                  </div>
                  <div className="ltn__search-by-place-area -gallery before-bg-top--- bg-image-top--- pt-115 " data-bs-bg={publicUrl + "assets/img/bg/20.jpg"} >
                    <div className="container" style={{ position: "relative", zIndex: "0", minWidth: '84%' }}>
                      <div className="row">
                        <div className="col-sm-12">

                        </div>
                      </div>
                      <div className="row ltn__search-by-place-slider-1-active slick-arrow-1 go-top">
                        <div className="col-sm-2" >
                          <div className="ltn__search-by-place-item" >
                            <div className="search-by-place-img">
                              <a href={data.pic1} data-rel="lightcase:myCollection">
                                <div><img src={data.pic1} alt="#" style={{ height: "300px", width: "400px" }} /></div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="ltn__search-by-place-item">
                            <div className="search-by-place-img">
                              <a href={data.pic2} data-rel="lightcase:myCollection">
                                <div><img src={data.pic2} alt="#" style={{ height: "300px", width: "400px" }} /></div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="ltn__search-by-place-item">
                            <div className="search-by-place-img">
                              <a href={data.pic3} data-rel="lightcase:myCollection">
                                <div to="/product-details"><img src={data.pic3} alt="#" style={{ height: "300px", width: "400px" }} /></div>
                              </a>


                            </div>
                          </div>
                        </div>


                      </div>
                    </div>

                  </div>
                </div>

                <div class="wrappercontainer" style={{ width: "912px" }}>
                  <Related />
                </div>


                <div class="wrappercontainer" style={{ width: "912px" }}>
                  <div class="heading" >
                    Related Properties
                  </div>
                  <Slider />
                </div>


                <div class="wrappercontainer" id="section7" >
                  <div class="heading" >
                    {data.propertyname} Video Tour
                  </div>

                  <div className="ltn__video-bg-img ltn__video-popup-height-80 bg-overlay-black-50 bg-image " data-bs-bg={data.thumbnail}>
                    <a className="ltn__video-icon-2 ltn__video-icon-2-border---" href={data.video} data-rel="lightcase:myCollection">
                      <i className="fa fa-play" />
                    </a>
                  </div>


                </div>


              </div>


            </div>
            <div className="faq-page wrappercontainer" id="section8" style={{ marginLeft: "55px", marginTop: "10px", height: "560px", marginBottom: "50px", padding: "20px" }}>
              <div class="heading" >
                {data.propertyname} Location
              </div>

              <div className="property-details-google-map">
                <iframe
                  src={data.location}
                  width="100%"
                  height="400px"
                  frameBorder={0}
                  allowFullScreen
                  aria-hidden="false"
                  tabIndex={0}
                />
              </div>
            </div>

            <div className="faq-page wrappercontainer" id="section8" style={{ marginLeft: "55px", marginTop: "10px", height: "500px", marginBottom: "50px" }}>
              <div class="heading" >
                Frequent Asked Questions
              </div>
              <Accordion title={"Q1." + data.faq1} content={data.ans1} />
              <Accordion title={"Q2." + data.faq2} content={data.ans2} />
              <Accordion title={"Q3." + data.faq3} content={data.ans3} />
              <Accordion title={"Q4." + data.faq4} content={data.ans4} />
              <Accordion title={"Q5." + data.faq5} content={data.ans5} />
              <Accordion title={"Q6." + data.faq6} content={data.ans6} />
              <Accordion title={"Q7." + data.faq7} content={data.ans7} />
              <Accordion title={"Q8." + data.faq8} content={data.ans8} />
              <Accordion title={"Q9." + data.faq9} content={data.ans9} />
              <Accordion title={"Q10." + data.faq10} content={data.ans10} />


            </div>



          </div>
        ))}




      </div>


      <div className='sidebar' >

        <div style={{ position: "fixed", right: "0", top: "180px", zIndex: "0" }}>
          <div style={{ border: "3px solid #b48811" }}>
            <img src="https://i.imgur.com/HP0o7Wm.png" style={{ width: "320px", marginTop: "px" }} />

            <div class="container2 snipcss-r95te snipcss-fjbEg">
              <div class="brand-title">
                Booking Open Now!
              </div>

              <div class="inputs">
                <input type="email" placeholder="Enter Your Name" />
                <input type="password" placeholder="Enter Your Contact No." />
                <button type="submit" class="btn-1">
                  Register Now
                </button>
              </div>
            </div>
          </div>
          <div class="brochure snipcss-WApG7">
            <p class="download_pdf_p">

            </p>
            <main id="btnmain">
              <section class="btnsection">
                <button class="btnbutton" id="download-btn" onclick="brochurePopup()">
                  <i class="fas fa-download fa-lg" aria-label="download brochurePopup">
                  </i>
                  &nbsp;<Download />
                </button>
                <div class="bar-container" id="bar-container">
                  <div class="progress-bar" id="progress-bar">
                  </div>
                </div>
                <i class="repeat-btn fas fa-redo-alt fa-lg" id="repeat-btn" onclick="reset()">
                </i>
              </section>
            </main>
          </div>

        </div>


        <div >



        </div>
      </div>
    </div>
  );
}

export default App;
