import React from 'react';
import styled from 'styled-components';
import {Link} from "react-router-dom";
const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  li {
    padding: 1px 1px;
  }
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: #000000;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    li {
      color: #fff;
    }
  }
`;

const RightNav = ({ open }) => {
  return (
    <Ul open={open}>
      <Link to="/"><li>Home</li></Link>
      <Link to="/all"><li>All Projects</li></Link>
      <Link to="/rtm"> <li>Ready To Move </li></Link>
      <Link to="/uc"><li>Under Construction</li></Link>
      <Link to="/Central"> <li>Central Noida</li></Link>
      <Link to="/extension"><li>Noida Extesnion</li></Link>
      <Link to="/expressway"><li>Noida Expressway</li></Link>
      <Link to="/sector150"><li>Sector 150</li></Link>
    </Ul>
  )
}

export default RightNav;