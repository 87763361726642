import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import Enquiry from '../../Pageview/Enquiry';

class Trending extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__upcoming-project-area section-bg-1--- bg-image-top pt-115 pb-65" data-bs-bg={publicUrl+"assets/img/pagebg.jpg"} style={{backgroundSize:"Cover"}}>
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center---">
			          <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color--- white-color" style={{paddingTop:"20px"}}>Trending Projects</h6>
			          <h1 className="section-title  white-color">Luxury high-rise condos 
<br />with Royal Lifestyle.
			            </h1>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__upcoming-project-slider-1-active slick-arrow-3">
			      
			      <div className="col-lg-12">
			        <div className="ltn__upcoming-project-item">
			          <div className="row">
			            <div className="col-lg-7">
			              <div className="ltn__upcoming-project-img">
			                <img src={publicUrl+"assets/img/trending1.jpg"} alt="#" />
			              </div>
			            </div>
			            <div className="col-lg-5 section-bg-1">
			              <div className="ltn__upcoming-project-info ltn__menu-widget">
			                <h6 className="section-subtitle ltn__secondary-color mb-0">About Dasnac Burj</h6>
			              
							<p className="mt-30">Dasnac Burj is a luxury residential project located in Sector 75, Noida, India. The project is developed by the Dasnac Group, a renowned real estate developer in India. Dasnac Burj offers a range of spacious and well-designed apartments with modern amenities such as a clubhouse, swimming pool, gymnasium, landscaped gardens, and more. The project is strategically located with easy access to major highways, business hubs, and commercial centers in the Noida region. It is designed to offer a luxurious lifestyle to its residents with its high-end features and facilities.</p>
			                <ul className="mt">
			                  <li>1. Project Name: <span>Dasnac Burj</span></li>
			                  <li>2. Project Type: <span>Residential</span></li>
			                  <li>3. Project Location: <span> SECTOR 75</span></li>
			                  <li>4. Configurations: <span>3 BHK, 4 BHK and 5 BHK </span></li>
			                  
			                </ul>
						
			                <div className="btn-wrapper animated go-top">
							<div className="theme-btn-1 btn btn-effect-1 bottommargin"><Enquiry/></div>
			                </div>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			      {/* upcoming-project-item */}
			      <div className="col-lg-12">
			        <div className="ltn__upcoming-project-item">
			          <div className="row">
			            <div className="col-lg-7">
			              <div className="ltn__upcoming-project-img">
			                <img src={publicUrl+"assets/img/trending3.jpg"} alt="#" />
			              </div>
			            </div>
			            <div className="col-lg-5 section-bg-1">
			              <div className="ltn__upcoming-project-info ltn__menu-widget">
			                <h6 className="ltn__secondary-color">About Parx Laureate</h6>
							<p className="mt-30">Parx Laureate is a premium residential project located in Sector 108, Noida, India. The project is developed by Laureate Buildwell Private Limited, a leading real estate developer in India. Parx Laureate offers spacious and luxurious apartments with modern amenities such as a clubhouse, swimming pool, gymnasium, landscaped gardens, and more. The project is strategically located with easy access to major highways, business hubs, and commercial centers in the Noida region. It is designed to offer a comfortable and luxurious lifestyle to its residents with its high-end features and facilities.</p>
			                <ul>
			                  <li>1. Project Name: <span>Parx Laureate</span></li>
			                  <li>2. Project Type: <span>Residential</span></li>
			                  <li>3. Project Location: <span>Sector 108</span></li>
			                  <li>4. Configurations: <span>4 BHK</span></li>
			                  
			                </ul>
			                <div className="btn-wrapper animated go-top">
							<div className="theme-btn-1 btn btn-effect-1 bottommargin"><Enquiry/></div>
			                </div>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			      {/* upcoming-project-item */}
			      <div className="col-lg-12">
			        <div className="ltn__upcoming-project-item">
			          <div className="row">
			            <div className="col-lg-7">
			              <div className="ltn__upcoming-project-img">
			                <img src={publicUrl+"assets/img/trending2.jpg"} alt="#" />
			              </div>
			            </div>
			            <div className="col-lg-5 section-bg-1">
			              <div className="ltn__upcoming-project-info ltn__menu-widget">
			                <h6 className="ltn__secondary-color">About Mahagun Medalleo</h6>
			                <p className="mt-30">Mahagun Medalleo is a premium residential project located in Sector 150, Noida, India. The project is developed by Mahagun Group, a well-known real estate developer in India. Mahagun Medalleo offers spacious and luxurious apartments with modern amenities such as a clubhouse, swimming pool, gymnasium, landscaped gardens, and more. The project is strategically located with easy access to major highways, business hubs, and commercial centers in the Noida region. It is designed to offer a comfortable and luxurious lifestyle to its residents with its high-end features and facilities. The project is also equipped with advanced security systems to ensure the safety and security of its residents.</p>
			                <ul>
			                  <li>1. Project Name: <span>Mahagun Medalleo</span></li>
			                  <li>2. Project Type: <span>Residential</span></li>
			                  <li>3. Project Location: <span> Sector 107, Noida</span></li>
			                  <li>4. Configurations: <span> 3 BHK, 4 BHK, 5BHK .</span></li>
			                
			                </ul>
			                <div className="btn-wrapper animated go-top">
							<div className="theme-btn-1 btn btn-effect-1 bottommargin"><Enquiry/></div>
			                </div>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			      {/*  */}
			    </div>
			  </div>
			</div>
        }
}

export default Trending ;