import React from 'react';
import { useState,useEffect } from 'react';

const Footer = () => {
    const [isFixed, setIsFixed] = useState(false);


    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    const handleScroll = () => {

        const div = document.querySelector('.my-div');
        const divTop = div.offsetTop;
        const scrollPosition = window.scrollY;
        if (scrollPosition > window.innerHeight * 0.5) {
          setIsFixed(true);
        } else {
          setIsFixed(false);
        }
      };
    
  
    
      const divStyle = isFixed ? {height:"50px",position:"fixed",width:"100%",background:"black",bottom:"0px",display:"flex"} : {display:"none"};

    let publicUrl = process.env.PUBLIC_URL + "/";
  return (
    <div className='mobilefoot'>
    <div  className="my-div" style={divStyle} >

      <div style={{width:"50%"}}><button class="" style={{width:"100%",height:"100%",background:"linear-gradient(rgb(255, 238, 170), rgb(221, 204, 136) 49%, rgb(221, 204, 136))"}} ><a href="https://wa.me/9313388388" style={{ fontWeight: "550px",fontSize:"24px" }}><img src={publicUrl + "assets/img/whatsapp.png"} style={{ width: "40px", fontWeight: "550px" }} /> WhatsApp</a></button></div>
      <div  style={{width:"50%"}}><button class="" style={{width:"100%",height:"100%",background:"linear-gradient(rgb(255, 238, 170), rgb(221, 204, 136) 49%, rgb(221, 204, 136))"}} ><a href="tel:9313388388" style={{ fontWeight: "550px",fontSize:"24px" }}><img src="https://i.imgur.com/PKeWY16.png" style={{ width: "40px", fontWeight: "550px" }} /> Call Us</a></button></div>

    </div>
    </div>
  )
}

export default Footer;
