import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

import Enquiry from '../../Pageview/Enquiry';

class UpcomingProductV1 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__upcoming-project-area section-bg-1--- bg-image-top pt-115 pb-65" data-bs-bg={publicUrl+"assets/img/pagebg.jpg"} style={{backgroundSize:"Cover"}}>
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center---">
			          <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color--- white-color" style={{paddingTop:"15px"}}>Upcoming Projects</h6>
			          <h5 className="section-title  white-color">Introducing the Newest Addition
<br /> to Our Collection  
			            </h5>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__upcoming-project-slider-1-active slick-arrow-3">
			      {/* upcoming-project-item */}
			      <div className="col-lg-12">
			        <div className="ltn__upcoming-project-item">
			          <div className="row">
			            <div className="col-lg-7">
			              <div>
			                <img src={publicUrl+"assets/img/upa.png"} alt="#" />
			              </div>
			            </div>
			            <div className="col-lg-5 section-bg-1">
			              <div className="ltn__upcoming-project-info ltn__menu-widget">
			                <h6 className="section-subtitle ltn__secondary-color mb-0">About L&T</h6>
			                <p className="mt-30">L&T's premium residential project offers luxurious living in Noida sector 128.The project is being developed by Larsen & Toubro, a leading construction and engineering company in India</p>
			                <ul className="mt">
			                  <li>Project Name: <span>L&T</span></li>
			                  <li>Project Type: <span>Residential</span></li>
			                  <li>Project Location: <span> SECTOR 128</span></li>
			                  <li>Configurations: <span>2 BHK, 3 BHK and 4 BHK </span></li>
			                  
			                </ul>
			                <div className="btn-wrapper animated go-top">
							<div className="theme-btn-1 btn btn-effect-1 bottommargin"><Enquiry/></div>
			                </div>
				
			              </div>
						  
			            </div>
						
			          </div>
			        </div>
			      </div>
			
			      <div className="col-lg-12">
			        <div className="ltn__upcoming-project-item">
			          <div className="row">
			            <div className="col-lg-7">
			              <div >
			                <img src={publicUrl+"assets/img/upb.jpg"} alt="#" />
			              </div>
			            </div>
			            <div className="col-lg-5 section-bg-1">
			              <div className="ltn__upcoming-project-info ltn__menu-widget">
			                <h6 className="ltn__secondary-color">About M3M</h6>
							<p className="mt-30">M3M Noida a prominent player in the NCR region. The company has a portfolio of premium residential and commercial projects in prime locations in Noida. M3M Noida's properties are known for their quality construction, modern design, and luxurious amenities.</p>
			                <ul>
			                  <li>Project Name: <span>M3M</span></li>
			                  <li>Project Type: <span>Residential</span></li>
			                  <li>Project Location: <span>Sector 94</span></li>
			                  <li>Configurations: <span>3 BHK,4 BHK,5 BHK</span></li>
			                  
			                </ul>
			                <div className="btn-wrapper animated go-top">
							<div className="theme-btn-1 btn btn-effect-1 bottommargin"><Enquiry/></div>
			                </div>

			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			 
			      <div className="col-lg-12">
			        <div className="ltn__upcoming-project-item">
			          <div className="row">
			            <div className="col-lg-7">
			              <div >
			                <img src={publicUrl+"assets/img/upc.png"} alt="#" />
			              </div>
			            </div>
			            <div className="col-lg-5 section-bg-1">
			              <div className="ltn__upcoming-project-info ltn__menu-widget">
			                <h6 className="ltn__secondary-color">About Max Estate</h6>
							<p className="mt-30">Max Estate Noida is Coming Soon 2 BHK and 3 BHK luxurious Residential apartments with all kind of ultra-modern specifications and facilities.nestled with massive 73 acres of lush greens golf course lies in the majestic towers of Max Sector 128 Noida.</p>
			                <ul>
			                  <li>Project Name: <span>Max Estate</span></li>
			                  <li>Project Type: <span>Residential</span></li>
			                  <li>Project Location: <span>Sector 128</span></li>
			                  <li>Configurations: <span> 3 BHK,4 BHK and Duplexes.</span></li>
			                
			                </ul>
			                <div className="btn-wrapper animated go-top">
							<div className="theme-btn-1 btn btn-effect-1 bottommargin"><Enquiry/></div>
			                </div>
						
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
		
			    </div>
			  </div>
			</div>
        }
}

export default UpcomingProductV1