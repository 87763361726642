import React from 'react'

const Banner = () => {
  return (
    <div>
      <img src="assets/img/videobackground.jpg"/>
    </div>
  )
}

export default Banner
