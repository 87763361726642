import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { data } from "../Pageview/data";
import Burger from './Burger2';
import Navbarmobile from "./Navbarmobile";
import {Link} from "react-router-dom";
const Navbar = () => {
    const [isFixed, setIsFixed] = useState(false);


    let publicUrl = process.env.PUBLIC_URL + "/";
  
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    const handleScroll = () => {
      const div = document.querySelector('.my-div');
      const divTop = div.offsetTop;
      const scrollPosition = window.scrollY;
      if (scrollPosition > window.innerHeight * 0.1) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }

    };
    const { id } = useParams();

  console.log(id);

  const filteredData = data.filter((item) => {
    return Object.values(item).includes(id);
  });

  console.log(filteredData);
    

    const divStyle = isFixed ? { position: "fixed", top: "0px", background: "black", boxShadow: "rgb(48 48 48) 0px 3px 9px 0px", width: "100%", zIndex: "999", height: "85px",display:"flex" } : {display:"none"};
    const burgerStyle=isFixed ? {width:"10%"} : {width:"10%",display:"none"}


  return (
    <div>
        {filteredData.map((data) => (
<>
      <Navbarmobile/>
      <div>
<img src={data.img} style={{position:"relative",top:"89px"}}/>
      </div>
      <nav className="my-div" style={divStyle}>
    <div style={burgerStyle} >
   <Burger/>
    </div>
    <div style={{display:"flex"}}>
      <div  style={{width:"50%",padding:"5px",marginLeft:"20px"}}>
    <Link to="/">  <div><img src="https://i.imgur.com/7JiRM2Y.png"/></div></Link>
      </div>
    </div>
          <div style={{width: "50%",padding:"5px"}}>
            <div><img src={data.logo}/></div>
           
          </div>
         
          
     
        
            <div></div>
      </nav>


<div >

</div>
</>
        ))}
    </div>
  );
};

export default Navbar;
