import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Social extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__social-media">
			<ul>
			<div className="ltn__social-media">
			<ul>
				<li><a href="https://www.facebook.com/luxuryapartmentsncr" title="Facebook"><i className="fab fa-facebook-f" style={{color:"white"}} /></a></li>
				<li><a href="https://www.instagram.com/luxuryapartmentsncr/" title="Instagram"><i className="fab fa-instagram"  style={{color:"white"}} /></a></li>
				<li><a href="https://in.linkedin.com/company/luxury-apartmentsncr" title="linkedin"><i className="fab fa-linkedin"  style={{color:"white"}} /></a></li>
			</ul>
		</div>
			</ul>
		</div>
        }
}

export default Social