import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class TestimonialV2 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <div className="ltn__testimonial-area section-bg-1--- bg-image-top pt-115 pb-65">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center---">
			          <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color--- " style={{paddingTop:"15px"}}>Client's Testimonial</h6>
			          <h1 className="section-title ">See What's Our Client <br />
			            Says About Us</h1>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__testimonial-slider-6-active slick-arrow-3">
			      <div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7 ltn__testimonial-item-8">
			          <div className="ltn__testimoni-info">
			            <div className="ltn__testimoni-author-ratting">
			              <div className="ltn__testimoni-info-inner">
			            
			                <div className="ltn__testimoni-name-designation">
			                  <h5>Inder Singh</h5>
			                  <label>Founder</label>
			                </div>
			              </div>
			              
			            </div>
			            <p> 
			            "I was a first-time homebuyer and didn't know where to start. Thankfully, I found HouseThat . Their team made the entire process simple and stress-free, and I felt confident that I was in good hands every step of the way. Thanks to them, I am now a proud homeowner and couldn't be happier."


</p>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7 ltn__testimonial-item-8">
			          <div className="ltn__testimoni-info">
			            <div className="ltn__testimoni-author-ratting">
			              <div className="ltn__testimoni-info-inner">
			               
			                <div className="ltn__testimoni-name-designation">
			                  <h5>Dheeraj Thakur</h5>
			                  <label>CEO</label>
			                </div>
			              </div>
			             
			            </div>
			            <p> 
						I recently purchased an investment property through HouseThat and I couldn't be happier with the results. Their team provided me with invaluable advice and guidance throughout the entire process, and their attention to detail was second to none. I will definitely be using their services again in the future.</p>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7 ltn__testimonial-item-8">
			          <div className="ltn__testimoni-info">
			            <div className="ltn__testimoni-author-ratting">
			              <div className="ltn__testimoni-info-inner">
			               
			                <div className="ltn__testimoni-name-designation">
			                  <h5>Ruchika Bansal</h5>
			                  <label>House Wife</label>
			                </div>
			              </div>
			              
			            </div>
			            <p> 
			            Thanks to HouseThat Team, I was able to find a property that exceeded all of my expectations. Their team provided me with excellent service and support throughout the entire process, and I would not hesitate to work with them again in the future</p>
			          </div>
			        </div>
			      </div>
			     
				 </div>
			  </div>
			</div>

        }
}

export default TestimonialV2