import React from 'react'

const Banner = () => {
  return (
    <div className='navbarimage2'>
      <img src="assets/img/rtm.jpg"/>
    </div>
  )
}

export default Banner;
