import React from 'react'

const Allbanner = () => {
  return (
    <div className='navbarimage'>
    <img src="assets/img/All.jpg"/>
  </div>
  )
}

export default Allbanner
