import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import HomeV3 from './components/home-v3';
import Faq from './components/faq';
import ComingSoon from './components/coming-soon';
import Error  from './components/404';
import ShopGrid from './components/shop-grid';
import ProdductDetails from './components/product-details';
import BlogGrid from './components/blog-grid';
import Contact from './components/contact';
import History from './components/history';
import Noidaextension from './Pageview/Noidaextension'
import CetralNoida from './Pageview/CentralNoida';
import Expressway from './Pageview/Expressway';
import Sector150 from './Pageview/Sector150';
import Pageview from './Pageview/Pageview';
import Readytomove from './Projects/ReadyToMove/Readytomove';
import UnderConstruction from './Projects/UnderConstruction.js/UnderConstruction';
import All from './Pageview/All';
import NoidaExtensionRtm from './Projects/ReadyToMove/NoidaExtension';
import CentralNoidaRtm from './Projects/ReadyToMove/CentralNoida';
import ExpresswayRtm from './Projects/ReadyToMove/ExpressWay';
import Sector150Rtm from './Projects/ReadyToMove/Sector150';
import Propertydetail from './propertydetail';
import Experiment from './design/Experiment';
import UnderConstructioncental from './Projects/UnderConstruction.js/CentralNoida';
import Ucexpressway from './Projects/UnderConstruction.js/ExpressWay';
import Ucextension from './Projects/UnderConstruction.js/NoidaExtension';
import Ucsector150 from './Projects/UnderConstruction.js/Sector150';

const App = () => {
  return (
    <div>
    
      <Routes>
      <Route exact path="/" element={<HomeV3/>}  />
                        <Route path="/detailview/:id"  element={<Pageview />}  />
                        <Route path="/history" element={ <History />} />
                        <Route path="/faq" element={ <Faq/> } />
                        <Route path="/coming-soon" element={ <ComingSoon/> } />
                        <Route path="/shop-grid" element={< ShopGrid/> } />
                        <Route path="/all" element={< All/>} />
                        <Route path="/extension" element={<Noidaextension/>} />
                        <Route path="/central" element={<CetralNoida/>} />
                        <Route path="/expressway" element={<Expressway/>} />
                        <Route path="/sector150" element={<Sector150/>} />
                        <Route path="/rtm" element={<Readytomove/>} />
                        <Route path="/uc" element={<UnderConstruction/>} />
                        <Route path="/rtmextension" element={<NoidaExtensionRtm/>} />
                        <Route path="/rtmCentral" element={<CentralNoidaRtm/>} />
                        <Route path="/rtmexpressway" element={<ExpresswayRtm/>} />
                        <Route path="/rtmsector150" element={<Sector150Rtm/>} />
                        <Route path="/uccentral" element={< UnderConstructioncental/>} />
                        <Route path="/ucexpressway" element={<Ucexpressway/>} />
                        <Route path="/ucextension" element={<Ucextension/>} />
                        <Route path="/ucsector150" element={<Ucsector150/>} />
                        <Route path="/blog-grid" element={< BlogGrid />} />
                        <Route path="/contact" element={ <Contact />} />
                         <Route path="/product-details" element={ <ProdductDetails/> } />
                         <Route path="*" element={ <Error/> } />
                        <Route path="/experiment" element={<Experiment/>} />
                        
    </Routes>
    
    </div>
  )
}

export default App

