import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { data } from "./data";
import ShowMore from 'react-show-more-button';
import Dialog from './Schedule'
import Slider from './product-slider-v1'
import { featured } from './Featured';
import Features from './Features';
import emailjs from '@emailjs/browser';
import FloorPlanSlider from './Floorplanslider';
import GallerySlider from './GallerySlider';
import Chart from '../DetailView/Chart';
import ImageContainer from '../DetailView/ImageContainer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import App from '../design/App';
import Mobileview from '../design/Mobileview';
import Page from '../design/Page';



const ShopDetails = () => {

	{/*const [isFixed, setIsFixed] = useState(false);




	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const handleScroll = () => {
		const div = document.querySelector('.my-div');
		const divTop = div.offsetTop;
		const scrollPosition = window.scrollY;
		if (scrollPosition > window.innerHeight * 0.7) {
			setIsFixed(true);
		} else {
			setIsFixed(false);
		}
	};


*/}


	const form = useRef();

	const sendEmail = (e) => {
		e.preventDefault();

		emailjs.sendForm('service_zu5ljpw', 'template_fc0jx11', form.current, 'E3iwyV_dWv0VZkPbo')
			.then((result) => {
				console.log(result.text);

			}, (error) => {
				console.log(error.text);
			});
	};

	let publicUrl = process.env.PUBLIC_URL + "/";
	const { id } = useParams();

	console.log(id);

	const filteredData = data.filter((item) => {
		return Object.values(item).includes(id);
	});

	console.log(filteredData);




	return <>
		<div className="ltn__img-slider-area" style={{display:"block"}}>
            <div className='mobileviewwhide'>

		
			{filteredData.map((data) => (

				<section class="home-banner snipcss-Mpn2F style-hntv7" id="home" >
					<div class="home-banner-img">

					</div>
					<div class="banner-cnt">
						<div className='imgposition'  id="section11">
							<img src={data.img} style={{ position: "static", height: "100%" }} />
						</div>

						<div class="container" style={{ zIndex: "10000"}} >
							<div class="project-configurations">
								<h1 style={{ color: "white", fontWeight: "600" }}>
									{data.propertyname}
								</h1>
								<p style={{ color: "white", fontWeight: "600" }}>
									{data.address1}
								</p>
								<div class="banner-configurations-site-visit">
									<div class="banner-configurations">
										<div class="configuration-item configurations-size">
											<h4>
												Price
											</h4>
											<p style={{ color: "white", fontWeight: "600" }}>
												₹{data.price}
											</p>
										</div>
										<div class="configuration-item configurations-size">
											<h4>
												Sizes
											</h4>
											<p style={{ color: "white", fontWeight: "600" }}>
												{data.sizes}
											</p>
										</div>
										<div class="configuration-item configurations-size">
											<h4>
												Configurations
											</h4>
											<p style={{ color: "white", fontWeight: "600" }}>
												{data.configurations}
											</p>
										</div>
										<div class="configuration-item configurations-size">
											<h4>
												Status
											</h4>
											<p style={{ color: "white", fontWeight: "600" }}>
												{data.status}
											</p>
										</div>
										<div class="configuration-item">
											<h4>
												Rera No.
											</h4>
											<p style={{ color: "white", fontWeight: "600" }}>
												{data.RERANO}
											</p>
										</div>
									</div>


									<div class="project-site-visit btn">
										<Dialog />
									</div>


								</div>
							</div>
						</div>
					</div>

				</section>
			))}
			 
          </div>
			<div>
			
			<Page/>
		
		</div>
		</div>

		

		{ /* 
	<div className="ltn__shop-details-area pt-0">

		{filteredData.map((data) => (
				<div className="container">
				<div className="row">
					<div className="col-lg-8 col-md-12">
					<div className="ltn__shop-details-inner ltn__page-details-inner " >

					
					<div style={{boxShadow:"0px 2px 5px 6px #504c4cd9" ,padding:"8px",marginTop:"25px",position:"relative",zIndex:"0" }} className="element" id="section1">
<div class="heading" >
About The Islands By Gaurs
                </div> 
<p>
        <ReadMore>
        The Islands by Gaurs are made to indulge you in grandeur and understated elegance. Every facet of these bespoke residences have been designed with taste, made with nature, made just for you. It is a master piece of planning, execution and refinement, and is the result of almost 3 decades of committing ourselves to delivering excellence.
        The Islands by Gaurs are made to indulge you in grandeur and understated elegance. Every facet of these bespoke residences have been designed with taste, made with nature, made just for you. It is a master piece of planning, execution and refinement, and is the result of almost 3 decades of committing ourselves to delivering excellence.
        


        </ReadMore>
      </p>
</div>
						{/* <h4 className="title-2">About</h4>
						<p style={{textAlign:"justify",fontSize:"14px"}}>{data.text1}</p>
						<h4 className="title-2">Property Detail</h4>  
						<div className="property-detail-info-list section-bg-1 clearfix mb-60">                          
						<ul>
							<li><label>Status:</label> <span style={{fontWeight:"300"}}>{data.status}</span></li>
							<li><label>Launch Date: </label> <span style={{fontWeight:"300"}}>{data.launchDate}</span></li>
							<li><label>Possession Date:</label> <span style={{fontWeight:"300"}}>{data.possessionDate}</span></li>
							<li><label>Project Area:</label> <span style={{fontWeight:"300"}}>{data.projectArea}</span></li>
							<li><label>Sizes:</label> <span style={{fontWeight:"300"}}>{data.sizes}</span></li>
							<li><label>Total Towers:</label> <span style={{fontWeight:"300"}}>{data.totalTowers}</span></li>
							
						</ul>
						<ul>
						<li><label>Total Floors:</label> <span style={{fontWeight:"300"}}>{data.totalFloors}</span></li>
							<li><label>Total Flats:</label> <span style={{fontWeight:"300"}}>{data.totalFlats}</span></li>
							<li><label>Green Area:</label> <span style={{fontWeight:"300"}}> {data.greenArea} </span></li>
							<li><label>Ceiling Height:</label> <span style={{fontWeight:"300"}}>{data.ceilingHeight}</span></li>
							<li><label>Main Door Height:</label> <span style={{fontWeight:"300"}}>{data.mainDoorHeight}</span></li>
							<li><label>Internal Door Height:</label> <span style={{fontWeight:"300"}}>{data.internalDoorHeight}</span></li>
						</ul>
						</div>
						<div style={{ width:"100%",display:"flex",boxShadow:"0px 2px 5px 6px #504c4cd9" ,padding:"8px",marginTop:"25px",position:"relative",zIndex:"0"}} className="services">
							
                             <div style={{width:"50%",borderRight:"10px solid white"}}>
<table>
 <tbody>
   <tr>
	 
	 <th style={{fontWeight:"500"}}>Status:</th>
	 <td style={{color:"#b48811"}}>{data.status}</td>
	 
   </tr>
   <tr>
	 <th style={{fontWeight:"500"}}>Launch Date:</th>
	 <td style={{color:"#b48811"}}>{data.launchDate}</td>
	 
	
   </tr>
   <tr>
	 <th style={{fontWeight:"500"}}>Possession Date:</th>
	 <td style={{color:"#b48811"}}>{data.possessionDate}</td>
	 
 
   </tr>
   <tr>
	 <th style={{fontWeight:"500"}}>RERA ID</th>
	 <td style={{color:"#b48811"}}>{data.RERANO}</td>
   </tr>
   <tr>
	 <th style={{fontWeight:"500"}}>Project Plot Area</th>
	 <td style={{color:"#b48811"}}>{data.projectArea}</td>
   </tr>
   <tr>
	 <th style={{fontWeight:"500"}}>Green Area</th>
	 <td style={{color:"#b48811"}}>{data.greenArea}</td>
   </tr>
   <tr>
	 <th style={{fontWeight:"500"}}>Lift Per Tower</th>
	 <td style={{color:"#b48811"}}>{data.lift}</td>
   </tr>
   <tr>
	 <th style={{fontWeight:"500"}}>Floor vs Flats</th>
	 <td style={{color:"#b48811"}}>{data.floorvsflats}</td>
   </tr>
   <tr>
	 <th style={{fontWeight:"500"}}>Podium/Non Podium</th>
	 <td style={{color:"#b48811"}}>{data.podium}</td>
   </tr>
   <tr>
	 <th style={{fontWeight:"500"}}>Club House Size</th>
	 <td style={{color:"#b48811"}}>{data.clubhousesize}</td>
   </tr>
 </tbody>
</table>
						
							  
							
							</div>

							<div style={{width:"50%"}}>
                         
<table>
 
 <tbody>
   <tr>
	 
	 <th style={{fontWeight:"500"}}>Price:</th>
	 <td style={{color:"#b48811"}}>{data.price}</td>
	 
   </tr>
   <tr>
	 <th style={{fontWeight:"500"}}>Property Status:</th>
	 <td style={{color:"#b48811"}}>{data.status}</td>
	 
	
   </tr>
   <tr>
	 <th style={{fontWeight:"500"}}>Construction Type:</th>
	 <td style={{color:"#b48811"}}>{data.constructionType}</td>
	 
 
   </tr>
   <tr>
	 <th style={{fontWeight:"500"}}>Total Towers</th>
	 <td style={{color:"#b48811"}}>{data.totalTowers}</td>
   </tr>
   <tr>
	 <th style={{fontWeight:"500"}}>Total Flats</th>
	 <td style={{color:"#b48811"}}>{data.totalFlats}</td>
   </tr>
   <tr>
	 <th style={{fontWeight:"500"}}>Parking Level</th>
	 <td style={{color:"#b48811"}}>{data.parkingLevel}</td>
   </tr>
   <tr>
	 <th style={{fontWeight:"500"}}>No of Parking</th>
	 <td style={{color:"#b48811"}}>{data.Noofparking}</td>
   </tr>
   <tr>
	 <th style={{fontWeight:"500"}}>Total Floors</th>
	 <td style={{color:"#b48811"}}>{data.totalFloors}</td>
   </tr>
   <tr>
	 <th style={{fontWeight:"500"}}>Ceiling Height</th>
	 <td style={{color:"#b48811"}}>{data.ceilingHeight}</td>
   </tr>
   <tr>
	 <th style={{fontWeight:"500"}}>Main Door Height</th>
	 <td style={{color:"#b48811"}}>{data.mainDoorHeight}</td>
   </tr>
 </tbody>
</table>
							</div>
						</div>

                        
						{/*<h4 className="title-2">Facts and Features</h4>
						<div className="property-detail-feature-list clearfix mb-45">                            
						<ul>
							<li>
							<div className="property-detail-feature-list-item">
								<i className="flaticon-double-bed" />
								<div>
								<h6>Living Room</h6>
								<small>20 x 16 sq feet</small>
								</div>
							</div>
							</li>
							<li>
							<div className="property-detail-feature-list-item">
								<i className="flaticon-double-bed" />
								<div>
								<h6>Garage</h6>
								<small>20 x 16 sq feet</small>
								</div>
							</div>
							</li>
							<li>
							<div className="property-detail-feature-list-item">
								<i className="flaticon-double-bed" />
								<div>
								<h6>Dining Area</h6>
								<small>20 x 16 sq feet</small>
								</div>
							</div>
							</li>
							<li>
							<div className="property-detail-feature-list-item">
								<i className="flaticon-double-bed" />
								<div>
								<h6>Bedroom</h6>
								<small>20 x 16 sq feet</small>
								</div>
							</div>
							</li>
							<li>
							<div className="property-detail-feature-list-item">
								<i className="flaticon-double-bed" />
								<div>
								<h6>Bathroom</h6>
								<small>20 x 16 sq feet</small>
								</div>
							</div>
							</li>
							<li>
							<div className="property-detail-feature-list-item">
								<i className="flaticon-double-bed" />
								<div>
								<h6>Gym Area</h6>
								<small>20 x 16 sq feet</small>
								</div>
							</div>
							</li>
							<li>
							<div className="property-detail-feature-list-item">
								<i className="flaticon-double-bed" />
								<div>
								<h6>Garden</h6>
								<small>20 x 16 sq feet</small>
								</div>
							</div>
							</li>
							<li>
							<div className="property-detail-feature-list-item">
								<i className="flaticon-double-bed" />
								<div>
								<h6>Parking</h6>
								<small>20 x 16 sq feet</small>
								</div>
							</div>
							</li>
						</ul>
					</div>*/}


		{/*			<div className="property-details-amenities mb-60">
						<div className="row">
							<div className="col-lg-4 col-md-6">
							<div className="ltn__menu-widget">
								<ul>
								<li>
									<label className="checkbox-item">Swimming Pool
									<input type="checkbox" defaultChecked="checked" />
									<span className="checkmark" />
									</label>
								</li>
								<li>
									<label className="checkbox-item">High Speed Elevators
									<input type="checkbox" defaultChecked="checked" />
									<span className="checkmark" />
									</label>
								</li>
								<li>
									<label className="checkbox-item">Cctv Surveliance
									<input type="checkbox" defaultChecked="checked" />
									<span className="checkmark" />
									</label>
								</li>
								<li>
									<label className="checkbox-item">24x7 Security
									<input type="checkbox" defaultChecked="checked" />
									<span className="checkmark" />
									</label>
								</li>
								<li>
									<label className="checkbox-item">Kids play Area
									<input type="checkbox" defaultChecked="checked" />
									<span className="checkmark" />
									</label>
								</li>
								</ul>
							</div>
							</div>
							<div className="col-lg-4 col-md-6">
							<div className="ltn__menu-widget">
								<ul>
								<li>
									<label className="checkbox-item">Earthquake Resistance
									<input type="checkbox" defaultChecked="checked" />
									<span className="checkmark" />
									</label>
								</li>
								<li>
									<label className="checkbox-item">Gym
									<input type="checkbox" defaultChecked="checked" />
									<span className="checkmark" />
									</label>
								</li>
								<li>
									<label className="checkbox-item">Firefighting Equipment
									<input type="checkbox" defaultChecked="checked" />
									<span className="checkmark" />
									</label>
								</li>
								<li>
									<label className="checkbox-item">Clubhouse
									<input type="checkbox" defaultChecked="checked" />
									<span className="checkmark" />
									</label>
								</li>
								<li>
									<label className="checkbox-item">LandScape Garden
									<input type="checkbox" defaultChecked="checked" />
									<span className="checkmark" />
									</label>
								</li>
								</ul>
							</div>
							</div>
							
		
						</div>
						</div>
		
						<h4 className="title-2 mb-30">Amenities</h4>
						<div class="services lazyloaded snipcss-eFVVK">
  <div class="row lazyloaded">
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-3 col-6 lazyloaded">
      <figure>
        <img src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-1.png" class="img-thumbnail lazyloaded" data-src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-1.png"/>
        <noscript>
          <img src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-1.png" class="img-thumbnail" data-eio="l"/>
        </noscript>
      </figure>
      <p>
        Open Badminton Court
      </p>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-3 col-6 lazyloaded">
      <figure>
        <img src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-2.png" class="img-thumbnail lazyloaded" data-src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-2.png"/>
        <noscript>
          <img src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-2.png" class="img-thumbnail" data-eio="l"/>
        </noscript>
      </figure>
      <p>
        Beauty Salon
      </p>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-3 col-6 lazyloaded">
      <figure>
        <img src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-3.png" class="img-thumbnail lazyloaded" data-src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-3.png"/>
        <noscript>
          <img src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-3.png" class="img-thumbnail" data-eio="l"/>
        </noscript>
      </figure>
      <p>
        Rain Water Harvesting
      </p>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-3 col-6 lazyloaded">
      <figure>
        <img src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-4.png" class="img-thumbnail lazyloaded" data-src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-4.png"/>
        <noscript>
          <img src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-4.png" class="img-thumbnail" data-eio="l"/>
        </noscript>
      </figure>
      <p>
        Customer Care Centre
      </p>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-3 col-6 lazyloaded">
      <figure>
        <img src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-5.png" class="img-thumbnail lazyloaded" data-src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-5.png"/>
        <noscript>
          <img src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-5.png" class="img-thumbnail" data-eio="l"/>
        </noscript>
      </figure>
      <p>
        Internet
      </p>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-3 col-6 lazyloaded">
      <figure>
        <img src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-6.png" class="img-thumbnail lazyloaded" data-src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-6.png"/>
        <noscript>
          <img src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-6.png" class="img-thumbnail" data-eio="l"/>
        </noscript>
      </figure>
      <p>
        Jogging Track
      </p>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-3 col-6 lazyloaded">
      <figure>
        <img src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-7.png" class="img-thumbnail lazyloaded" data-src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-7.png"/>
        <noscript>
          <img src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-7.png" class="img-thumbnail" data-eio="l"/>
        </noscript>
      </figure>
      <p>
        Immaculate Landscaping
      </p>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-3 col-6 lazyloaded">
      <figure>
        <img src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-8.png" class="img-thumbnail lazyloaded" data-src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-8.png"/>
        <noscript>
          <img src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-8.png" class="img-thumbnail" data-eio="l"/>
        </noscript>
      </figure>
      <p>
        Play Pan for Kids
      </p>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-3 col-6 lazyloaded">
      <figure>
        <img src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-9.png" class="img-thumbnail lazyloaded" data-src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-9.png"/>
        <noscript>
          <img src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-9.png" class="img-thumbnail" data-eio="l"/>
        </noscript>
      </figure>
      <p>
        Securities
      </p>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-3 col-6 lazyloaded">
      <figure>
        <img src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-10.png" class="img-thumbnail lazyloaded" data-src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-10.png"/>
        <noscript>
          <img src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-10.png" class="img-thumbnail" data-eio="l"/>
        </noscript>
      </figure>
      <p>
        Swimming Pool
      </p>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-3 col-6 lazyloaded">
      <figure>
        <img src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-11.png" class="img-thumbnail lazyloaded" data-src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-11.png"/>
        <noscript>
          <img src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-11.png" class="img-thumbnail" data-eio="l"/>
        </noscript>
      </figure>
      <p>
        Dedicated Lift Lobby
      </p>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-3 col-6 lazyloaded">
      <figure>
        <img src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-12.png" class="img-thumbnail lazyloaded" data-src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-12.png"/>
        <noscript>
          <img src="https://www.gulshanhomz.com/wp-content/uploads/2019/10/sf-12.png" class="img-thumbnail" data-eio="l"/>
        </noscript>
      </figure>
      <p>
        AC Gymnasium
      </p>
    </div>
  </div>
</div>




						<h4 className="title-2">Floor Plans</h4>
						<Slider/>
					{/*
						<div className="ltn__apartments-plan-area product-details-apartments-plan mb-60">
						<div className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-center---">
							<div className="nav">
							<a data-bs-toggle="tab" href="#liton_tab_3_1">First Floor</a>
							<a className="active show" data-bs-toggle="tab" href="#liton_tab_3_2">Second Floor</a>
							<a data-bs-toggle="tab" href="#liton_tab_3_3" >Third Floor</a>
							<a data-bs-toggle="tab" href="#liton_tab_3_4" >Top Garden</a>
							</div>
						</div>
						<div className="tab-content">
							<div className="tab-pane fade" id="liton_tab_3_1">
							<div className="ltn__apartments-tab-content-inner">
								<div className="row">
								<div className="col-lg-7">
									<div className="apartments-plan-img">
									<img src={data.floorPlan1} alt="#" />
									</div>
								</div>
								<div className="col-lg-5">
								<div className="row">
										<div className="col-lg-12">
										<div>
											<ul>
											<li><label>Super Area :</label> <span>{data.superArea}</span></li>
											<li><label>Carpet Area :</label> <span>{data.carpetArea}</span></li>
											</ul>
										</div>
										</div>
										
									</div>
					
								
								
								</div>
								</div>
							</div>
							</div>
							<div className="tab-pane fade active show" id="liton_tab_3_2">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
								<div className="col-lg-7">
									<div className="apartments-plan-img">
									<img src={data.floorPlan2} alt="#" />
									</div>
								</div>
								<div className="col-lg-5">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<h2>Second Floor</h2>
									<p>Enimad minim veniam quis nostrud exercitation ullamco laboris.
										Lorem ipsum dolor sit amet cons aetetur adipisicing elit sedo
										eiusmod tempor.Incididunt labore et dolore magna aliqua.
										sed ayd minim veniam.</p>
									</div>
								</div>
								<div className="col-lg-12">
									<div className="product-details-apartments-info-list  section-bg-1">
									<div className="row">
										<div className="col-lg-6">
										<div className="apartments-info-list apartments-info-list-color mt-40---">
											<ul>
											<li><label>Total Area</label> <span>2800 Sq. Ft</span></li>
											<li><label>Bedroom</label> <span>150 Sq. Ft</span></li>
											</ul>
										</div>
										</div>
										<div className="col-lg-6">
										<div className="apartments-info-list apartments-info-list-color mt-40---">
											<ul>
											<li><label>Belcony/Pets</label> <span>Allowed</span></li>
											<li><label>Lounge</label> <span>650 Sq. Ft</span></li>
											</ul>
										</div>
										</div>
									</div>
									</div>
								</div>
								</div>
							</div>
							</div>
							<div className="tab-pane fade" id="liton_tab_3_3">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
								<div className="col-lg-7">
									<div className="apartments-plan-img">
									<img src={data.floorPlan3} alt="#" />
									</div>
								</div>
								<div className="col-lg-5">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<h2>Third Floor</h2>
									<p>Enimad minim veniam quis nostrud exercitation ullamco laboris.
										Lorem ipsum dolor sit amet cons aetetur adipisicing elit sedo
										eiusmod tempor.Incididunt labore et dolore magna aliqua.
										sed ayd minim veniam.</p>
									</div>
								</div>
								<div className="col-lg-12">
									<div className="product-details-apartments-info-list  section-bg-1">
									<div className="row">
										<div className="col-lg-6">
										<div className="apartments-info-list apartments-info-list-color mt-40---">
											<ul>
											<li><label>Total Area</label> <span>2800 Sq. Ft</span></li>
											<li><label>Bedroom</label> <span>150 Sq. Ft</span></li>
											</ul>
										</div>
										</div>
										<div className="col-lg-6">
										<div className="apartments-info-list apartments-info-list-color mt-40---">
											<ul>
											<li><label>Belcony/Pets</label> <span>Allowed</span></li>
											<li><label>Lounge</label> <span>650 Sq. Ft</span></li>
											</ul>
										</div>
										</div>
									</div>
									</div>
								</div>
								</div>
							</div>
							</div>
							<div className="tab-pane fade" id="liton_tab_3_4">
							<div className="ltn__product-tab-content-inner">
								<div className="row">
								<div className="col-lg-7">
									<div className="apartments-plan-img">
									<img src={publicUrl+"assets/img/others/10.png"} alt="#" />
									</div>
								</div>
								<div className="col-lg-5">
									<div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
									<h2>Top Garden</h2>
									<p>Enimad minim veniam quis nostrud exercitation ullamco laboris.
										Lorem ipsum dolor sit amet cons aetetur adipisicing elit sedo
										eiusmod tempor.Incididunt labore et dolore magna aliqua.
										sed ayd minim veniam.</p>
									</div>
								</div>
								<div className="col-lg-12">
									<div className="product-details-apartments-info-list  section-bg-1">
									<div className="row">
										<div className="col-lg-6">
										<div className="apartments-info-list apartments-info-list-color mt-40---">
											<ul>
											<li><label>Total Area</label> <span>2800 Sq. Ft</span></li>
											<li><label>Bedroom</label> <span>150 Sq. Ft</span></li>
											</ul>
										</div>
										</div>
										<div className="col-lg-6">
										<div className="apartments-info-list apartments-info-list-color mt-40---">
											<ul>
											<li><label>Belcony/Pets</label> <span>Allowed</span></li>
											<li><label>Lounge</label> <span>650 Sq. Ft</span></li>
											</ul>
										</div>
										</div>
									</div>
									</div>
								</div>
								</div>
							</div>
							</div>
						</div>
						</div>
						{/* APARTMENTS PLAN AREA END 

<h4 className="title-2">KNOW ABOUT {data.propertyname}</h4>
					
					<ShowMore styleButtonDiv ={{opacity:"0.6"}}maxHeight={200} opacity={0.6} styleButton={{color:"rgb(180 136 17)",maxWidth:"180px",borderRadius:"0px",padding:"6px 5px",background:"white"}}>
					<div>
					  <div style={{display:"block",marginBottom:"25px",textAlign:"justify",fontSize:"14px"}}>
						  {data.para1}
					  </div>
					  <div style={{display:"block",marginBottom:"25px",textAlign:"justify",fontSize:"14px"}}>
					  {data.para2}
					  </div>
					  <div style={{display:"block",marginBottom:"25px",textAlign:"justify",fontSize:"14px"}}>
					  {data.para3}
					  </div>
					  <div style={{display:"block",marginBottom:"25px",textAlign:"justify",fontSize:"14px"}}>
					  {data.para4}
					  </div>
					  <div style={{display:"block",marginBottom:"25px",textAlign:"justify",fontSize:"14px"}}>
					  {data.para5}
					  </div>
					  </div>
					</ShowMore>
								  </div>

								 

<div style={{boxShadow:"0px 2px 5px 6px #504c4cd9" ,padding:"8px",marginTop:"25px",position:"relative",zIndex:"0" }} id="section2">
<div class="heading" >
The Islands By Gaurs Price & Floor Plan
                </div> 
             
            <Tabs style={{ width: '500px' }}>
                <TabList style={{
                    fontSize: '16px',
                    margin: '20px',
                    color: "#000",
                    borderRadius: '10px',
                }}>
                    <Tab style={{ background: 'white', 
                        borderRadius: '5px',textAlign:"center" }}>4 BHK <br/> Apartment</Tab>
                    <Tab style={{ background: 'white', 
                        borderRadius: '5px' }}>5 BHK  Apartment</Tab>
                </TabList>
                <TabPanel style={{ fontSize: '14px', 
                    margin: '20px' }}>
                    <Tabs defaultIndex={0}>
                        <TabList>
                            <Tab style={{ background: 'white', 
                                borderRadius: '5px' }}>3682 Sq.Ft.</Tab>
                            <Tab style={{ background: 'white', 
                                borderRadius: '5px' }}>4359 SQ.FT</Tab>
                            <Tab style={{ background: 'white', 
                                borderRadius: '5px' }}>4866 SQ.FT</Tab>
                        </TabList>
                        <TabPanel>
                          <div style={{display:"flex"}}>
                            <div style={{width:"50%",textAlign:"left"}}>4 BHK</div>
                            <div  style={{width:"50%",textAlign:"right",fontWeight:"500",fontSize:"16px"}}>₹ 2.95 Cr</div>
                          </div>
                          <div style={{display:"flex"}}>
                            <div style={{width:"50%",textAlign:"left"}}>Super Builtup Area :</div>
                            <div  style={{width:"50%",textAlign:"right",fontWeight:"400",fontSize:"12px"}}> 3682.00 sq.ft at ₹8.0K per sq.ft</div>
                          </div>
                          
                                <div style={{textAlign:"center",height:"280px",width:"700px",margin:"20px"}}>
                                <img src="https://is1-3.housingcdn.com/91aba65c/b2ffcf3e5945999cad1a6fba317086ce/v0/fs.jpeg.webp" style={{width:"250px"}}/>
                                </div>
                        </TabPanel>
                        <TabPanel>
                        <div style={{display:"flex"}}>
                            <div style={{width:"50%",textAlign:"left"}}>4 BHK</div>
                            <div  style={{width:"50%",textAlign:"right",fontWeight:"500",fontSize:"16px"}}>₹ 2.95 Cr</div>
                          </div>
                          <div style={{display:"flex"}}>
                            <div style={{width:"50%",textAlign:"left"}}>Super Builtup Area :</div>
                            <div  style={{width:"50%",textAlign:"right",fontWeight:"400",fontSize:"12px"}}> 3682.00 sq.ft at ₹8.0K per sq.ft</div>
                          </div>
                          
                                <div style={{textAlign:"center",height:"280px",width:"700px",margin:"20px"}}>
                                <img src="https://is1-3.housingcdn.com/91aba65c/b2ffcf3e5945999cad1a6fba317086ce/v0/fs.jpeg.webp" style={{width:"250px"}}/>
                                </div>
  
                        </TabPanel>
                        <TabPanel>
                        <div style={{display:"flex"}}>
                            <div style={{width:"50%",textAlign:"left"}}>4 BHK</div>
                            <div  style={{width:"50%",textAlign:"right",fontWeight:"500",fontSize:"16px"}}>₹ 2.95 Cr</div>
                          </div>
                          <div style={{display:"flex"}}>
                            <div style={{width:"50%",textAlign:"left"}}>Super Builtup Area :</div>
                            <div  style={{width:"50%",textAlign:"right",fontWeight:"400",fontSize:"12px"}}> 3682.00 sq.ft at ₹8.0K per sq.ft</div>
                          </div>
                          
                                <div style={{textAlign:"center",height:"280px",width:"700px",margin:"20px"}}>
                                <img src="https://is1-3.housingcdn.com/91aba65c/b2ffcf3e5945999cad1a6fba317086ce/v0/fs.jpeg.webp" style={{width:"250px"}}/>
                                </div>
                        </TabPanel>
                    </Tabs>
                </TabPanel>
                <TabPanel style={{ fontSize: '20px', 
                    margin: '20px' }}>
                    <Tabs>
                        <TabList>
                            <Tab style={{ background: 'white', 
                                borderRadius: '5px' }}>Nested-Tab_1</Tab>
                            <Tab style={{ background: 'white', 
                                borderRadius: '5px' }}>Nested-Tab_2</Tab>
                            <Tab style={{ background: 'white', 
                                borderRadius: '5px' }}>Nested-Tab_3</Tab>
                        </TabList>
                        <TabPanel>
                            <p style={{ color: 'blue' }}>
                                Welcome to GeeksforGeeks</p>
  
                        </TabPanel>
                        <TabPanel>
                            <p style={{ color: 'blue' }}>
                                A computer science portal for geeks.</p>
  
                        </TabPanel>
                        <TabPanel>
                            <p style={{ color: 'blue' }}>
                                Also known as GFG</p>
  
                        </TabPanel>
                    </Tabs>
                </TabPanel>
            </Tabs>


</div>
<div style={{boxShadow:"0px 2px 5px 6px #504c4cd9" ,padding:"8px",marginTop:"25px",position:"relative",zIndex:"0" }}>
<div class="heading" >
            The Islands By Gaurs Specifications
                </div>	
						  < ImageContainer/>
							 </div>
					
<div style={{boxShadow:"0px 2px 5px 6px #504c4cd9" ,padding:"8px",marginTop:"25px",position:"relative",zIndex:"0" }}  id="section4">
            <div class="heading" >
            The Islands By Gaurs Specifications
                </div>
                
            <Tabs style={{ width: '800px' }}>
                <TabList style={{
                    fontSize: '16px',
                    margin: '20px',
                    color: "#000",
                    borderRadius: '10px',

                }}>
                    <Tab style={{ background: 'white', 
                        borderRadius: '5px' }}><img src="https://c.housingcdn.com/demand/s/client/common/assets/homeIconTabActive.322f4b34.svg" />All</Tab>
                    <Tab style={{ background: 'white', 
                        borderRadius: '5px' }}><img src="https://c.housingcdn.com/demand/s/client/common/assets/floorCounterTab.7b7647c5.svg"/>Floor and Counter</Tab>
                            <Tab style={{ background: 'white', 
                        borderRadius: '5px' }}><img src="https://c.housingcdn.com/demand/s/client/common/assets/fittingTab.3c99916b.svg" style={{width:"20px"}}/>Fitting </Tab>
                            <Tab style={{ background: 'white', 
                        borderRadius: '5px' }}><img src="https://c.housingcdn.com/demand/s/client/common/assets/wallsCeilingTab.f6b47feb.svg" style={{width:"20px"}}/>Wall and Ceiling </Tab>
                </TabList>
                <TabPanel style={{ fontSize: '14px', 
                    margin: '20px' }}>
                    <Tabs defaultIndex={0}>
                       
                        <TabPanel>
                           <div className="csss-rewqt">
                            <div className="css-erp5gv "> Floor & Counter</div>
                            <div style={{display:"flex"}} className="css-12145tgb">
                              <div style={{width:"33%"}}>Living/Dining</div>
                              <div style={{width:"33%"}}>Master Bedroom</div>
                              <div style={{width:"33%"}}>Other Bedroom</div>
                            </div>
                           </div>
                           <div className="csss-rewqt">
                            <div className="css-erp5gv "> Fitting</div>
                            <div style={{display:"flex"}} className="css-12145tgb">
                              <div style={{width:"33%"}}>Electrical</div>
                              <div style={{width:"33%"}}>Toilets</div>
                              <div style={{width:"33%"}}>Kitchen</div>
                            </div>
                           </div>
                           <div className="csss-rewqt">
                            <div className="css-erp5gv "> Wall & Ceiling</div>
                            <div style={{display:"flex"}} className="css-12145tgb">
                              <div style={{width:"33%"}}>Interior</div>
                              <div style={{width:"33%"}}>Exterior</div>
                              <div style={{width:"33%"}}>Kitchen</div>
                            </div>
                           </div>
  
                        </TabPanel>
                       
                    </Tabs>
                </TabPanel>
                <TabPanel style={{ fontSize: '14px', 
                    margin: '20px' }}>
                    <Tabs defaultIndex={0}>
                       
                        <TabPanel>
                        <div className="csss-rewqt">
                            
                            <div style={{display:"flex"}} className="css-12145tgb">
                              <div style={{width:"33%"}}>Living/Dining</div>
                              <div style={{width:"33%"}}>Master Bedroom</div>
                              <div style={{width:"33%"}}>Other Bedroom</div>
                            </div>
                           </div>
                           <div className="csss-rewqt">
                          
                            <div style={{display:"flex"}} className="css-12145tgb">
                              <div style={{width:"33%"}}>Living/Dining</div>
                              <div style={{width:"33%"}}>Master Bedroom</div>
                              <div style={{width:"33%"}}>Other Bedroom</div>
                            </div>
                           </div>
                           
                        </TabPanel>
                       
                    </Tabs>
                </TabPanel>
                <TabPanel style={{ fontSize: '14px', 
                    margin: '20px' }}>
                    <Tabs defaultIndex={0}>
                       
                        <TabPanel>
                        <div className="csss-rewqt">
                            
                            <div style={{display:"flex"}} className="css-12145tgb">
                              <div style={{width:"33%"}}>Living/Dining</div>
                              <div style={{width:"33%"}}>Master Bedroom</div>
                              <div style={{width:"33%"}}>Other Bedroom</div>
                            </div>
                           </div>
                           <div className="csss-rewqt">
                          
                            <div style={{display:"flex"}} className="css-12145tgb">
                              <div style={{width:"33%"}}>Living/Dining</div>
                              <div style={{width:"33%"}}>Master Bedroom</div>
                              <div style={{width:"33%"}}>Other Bedroom</div>
                            </div>
                           </div>
  
                        </TabPanel>
                       
                    </Tabs>
                </TabPanel>
                <TabPanel style={{ fontSize: '14px', 
                    margin: '20px' }}>
                    <Tabs defaultIndex={0}>
                       
                        <TabPanel>
                        <div className="csss-rewqt">
                            
                            <div style={{display:"flex"}} className="css-12145tgb">
                              <div style={{width:"33%"}}>Living/Dining</div>
                              <div style={{width:"33%"}}>Master Bedroom</div>
                              <div style={{width:"33%"}}>Other Bedroom</div>
                            </div>
                           </div>
                           <div className="csss-rewqt">
                          
                            <div style={{display:"flex"}} className="css-12145tgb">
                              <div style={{width:"33%"}}>Living/Dining</div>
                              <div style={{width:"33%"}}>Master Bedroom</div>
                              <div style={{width:"33%"}}>Other Bedroom</div>
                            </div>
                           </div>
  
                        </TabPanel>
                       
                    </Tabs>
                </TabPanel>
            </Tabs>


      

            </div>  

			{/* 
			<h4 className="title-2">From Our Gallery</h4>
								<GallerySlider/>


								  <div style={{boxShadow:"0px 2px 5px 6px #504c4cd9" ,padding:"8px",marginTop:"25px",position:"relative",zIndex:"0" }}>
								  <div class="heading" >
            The Islands By Gaurs Price Trends
                </div>
                <button style={{backgroundColor:'#bcffde',height:"30px",width:"60px",border:"none",color:"#1dd38f"}}>4.14 %</button>
								  <Chart/>
								  </div>
								*/}

		{/* 	<div className="ltn__property-details-gallery mb-30">
						<div className="row" style={{width:"1200px"}}>
							<div className="col-md-6">
							<a href={data.pic1} data-rel="lightcase:myCollection">
								<img className="mb-30" src={data.pic1} alt="Image" />
							</a>
							<a href={data.pic2} data-rel="lightcase:myCollection">
								<img className="mb-30" src={data.pic2} alt="Image" />
							</a>
							</div>
							<div className="col-md-6">
							<a href={data.pic3} data-rel="lightcase:myCollection">
								<img className="mb-30" src={data.pic3} alt="Image" />
							</a>
							</div>
						</div>
						
		</div>    
		
		</div>   
						
	{/* 
						<div className="ltn__shop-details-tab-content-inner--- ltn__shop-details-tab-inner-2 ltn__product-details-review-inner mb-60">
						
						
				
						
					comment-reply 
					< Features/>
						
						
						
					</div>
					</div>
					<div className="col-sm-4">
					<aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
						{/* Author Widget */}

		{/* Search Widget */}

		{/* Form Widget 
						<form ref={form} onSubmit={sendEmail} >
						<div class="container2 snipcss-r95te">
  <div class="brand-title">
    Booking Open Now!
  </div>
  <div style={{color:"azure",
    textAlign: "center",
    textTransform:"uppercase",
    letterSpacing: "2px"}}>
    To Get The Best Offers, Schedule A Visit Now
  </div>
  <div class="inputs">
  <input type="text" name="user_name" placeholder=' Enter your name'  required />
  <input type="text"  name="contact_number" placeholder='Contact number' required title="Error Message" pattern="[1-9]{1}[0-9]{9}" />
    <button type="submit" class="btn-1">
      Register Now
    </button>
	
  </div>
</div>
</form>
						{/* Top Rated Product Widget */}

		{/* Menu Widget (Category) 
						<div className="widget ltn__popular-product-widget" style={{marginTop:"30px",fontSize:"18px"}}> 
						<h4 className="title-2" style={{fontSize:"18px"}} >{data.propertyname} Master Plan</h4>
		<div className="ltn__property-details-gallery mb-30">
						<div className="row">
							<div className="col-lg-6">
							
							<a href={data.siteplan} data-rel="lightcase:myCollection">
								<img className="mb-30" src={data.siteplan} alt="Image" />
							</a>
							</div>
						</div>
						</div>
						</div>
						<div className="widget ltn__popular-product-widget"> 
						<h4 className="title-2"  style={{fontSize:"18px"}}>Location</h4>

						<div className="property-details-google-map ">
						<iframe src={data.location} width="100%" height="100%" frameBorder={0} allowFullScreen aria-hidden="false" tabIndex={0} />
						</div>
						</div>
						
						<div className="widget ltn__popular-product-widget">  
						<h4 className="title-2"  style={{fontSize:"18px"}}>Property Video</h4> 
						<div className="ltn__video-bg-img ltn__video-popup-height-80 bg-overlay-black-50 bg-image " data-bs-bg={data.thumbnail}>
						<a className="ltn__video-icon-2 ltn__video-icon-2-border---" href={data.video} data-rel="lightcase:myCollection">
							<i className="fa fa-play" />
						</a>
						</div>
						</div>
						{/* Popular Product Widget */}

		{/* Popular Post Widget */}

		{/* Social Media Widget */}

		{/* Tagcloud Widget */}

		{/* Banner Widget 
						<div className="widget ltn__banner-widget d-none go-top">
						<Link to="/shop"><img src={publicUrl+"assets/img/banner/2.jpg"} alt="#" /></Link>
						</div>
					</aside>
					</div>
				</div>
				</div>
				))}
				*/}

	</>
}


export default ShopDetails;