import React, { useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import emailjs from '@emailjs/browser';
import { useParams } from "react-router-dom";
import { data } from "../Pageview/data";

function MyVerticallyCenteredModal(props) {

  let publicUrl = process.env.PUBLIC_URL+'/'

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_zu5ljpw', 'template_fc0jx11', form.current, 'E3iwyV_dWv0VZkPbo')
      .then((result) => {
          console.log(result.text);
         
      }, (error) => {
          console.log(error.text);
      });
  };

  const { id } = useParams();

  console.log(id);

  const filteredData = data.filter((item) => {
    return Object.values(item).includes(id);
  });

  console.log(filteredData);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{position:"relative!important"}}
    >
       {filteredData.map((data) => (
    

  <div style={{display:"flex"}}>
  <div className='leftmodal'>
  <img src={data.modal} alt="" class="img-fluid"/>
  </div>
  <div className='rightmodal' >
    <div style={{border:"2px solid #b48811",padding:"10px",height:"100%",width:"100%"}}>
      <div >
        <h3 style={{textAlign:"center",marginTop:"22px"}} >
        Get in Touch
        </h3>
        
      </div>
      <form ref={form} onSubmit={sendEmail} style={{padding:"18px"}}>
    <div style={{display:"block"}}>
      <div className='input1'><input type="text" name="user_name" placeholder=' Enter your name'  required className='inputmodal'/></div>
      <div className='input2'><input type="text"  name="contact_number" placeholder='Contact number' required title="Error Message" pattern="[1-9]{1}[0-9]{9}" className='inputmodal'/></div>
      <div className='input3'>  <input type="submit" value="Submit Now" required style={{height:"35px",width:"100px",color:"white",fontSize:"16px",borderRadius:"5px",padding:"2px",border:"none"}}/></div>
    </div>
    
    
    
   
   

  
  </form>
    </div>
  </div>
</div>
       ))}
    </Modal>
  );
}

export default function Dialog() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <div style={{fontWeight:"550",padding:"2px"}} onClick={() => setModalShow(true)}>
        Schedule A Visit
      </div>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

