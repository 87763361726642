import React,{useEffect,useState} from 'react'
import Social from '../components/section-components/social'
import Burger from './Burger'
import { Link } from 'react-router-dom';


const Navbarmobile = () => {

   

    

    return (
        <div >
        <div class="mobheader">
            <div style={{width: "75%"}} >
              <Link to="/"><img src="https://i.imgur.com/7JiRM2Y.png" style={{width:"145px",padding:"8px"}}/></Link>  
        </div>
        <div style={{
    position: "relative",
    right: "65px",
    width: "43%",
    padding: "5px",
    top: "8px"}}>
        
        <Social/>
        <div style={{display:"flex",alignContent:"center"}}>
        <img src="https://i.imgur.com/xsUmsot.png" style={{
    width:"150px",
    float:"right",
    marginTop:"5px"}}
 />
        </div>
      
       </div>
       <Burger/>
       </div>
       </div>
    )
}

export default Navbarmobile
