
let publicUrl = process.env.PUBLIC_URL + "/";

export const data =[
  { id:'HT4', 
  propertyname:"Trident Reso",
  logo:"https://i.imgur.com/NruMeHE.png",
  img:publicUrl+"assets/img/banner/tridentembassy.webp",
  floorPlan11:publicUrl+"assets/img/FloorPlan/Trident/1.jpg",
  floorPlan12:publicUrl+"assets/img/FloorPlan/Trident/0.jpg",
  floorPlan21:publicUrl+"assets/img/FloorPlan/Trident/2.jpg",
  floorPlan22:publicUrl+"assets/img/FloorPlan/Trident/0.jpg",
  floorPlan23:publicUrl+"assets/img/FloorPlan/Trident/0.jpg",
  floorPlan24:publicUrl+"assets/img/FloorPlan/Trident/0.jpg",
  floorPlan31:publicUrl+"assets/img/FloorPlan/Trident/3.jpg",
  floorPlan31:publicUrl+"assets/img/FloorPlan/Trident/0.jpg",
  pic1:publicUrl+"assets/img/Gallerynew/reso/1.webp",
  pic2:publicUrl+"assets/img/Gallerynew/reso/2.webp",
  pic3:publicUrl+"assets/img/Gallerynew/reso/3.webp",
  pic4:publicUrl+"assets/img/Gallerynew/reso/4.webp",
  pic5:publicUrl+"assets/img/Gallerynew/reso/5.webp",
  pic6:publicUrl+"assets/img/Gallerynew/reso/6.webp",
  modal:publicUrl+"assets/img/modal/reso.jpg",
  siteplan:publicUrl+"assets/img/SitePlan/reso.png",
  plan1:"2 BHK",
plan2:"3 BHK",
plan3:"4 BHK",
superareaplan11:"1272 Sq.Ft.",
superareaplan12:"1287 Sq.Ft.",
superareaplan21:"1375 Sq.Ft.",
superareaplan22:"1405 Sq.Ft.",
superareaplan23:"1625 Sq.Ft.",
superareaplan24:"1655 Sq.Ft.",
superareaplan31:"1892 Sq.Ft.",
superareaplan32:"1922 Sq.Ft.",
Noofparking:"810+",
parkingLevel:"3",
clubhousesize:"14000 sq.ft",
podium:" Podium",
lift:"3",
floorvsflats:"T(1,5)-4 T(2,3,4)-6",
price:"6,500 ",
address1:"Greater Noida West",
address2:"Plot No GH 05B, Sector 1, Noida Extension, Greater Noida West, UP",
RERANO:"UPRERAPRJ785019",
constructionType:"Mivan",
status:"Under Construction",
launchDate:"Jan 2021",
projectType:"Residential",
projectArea:"3.25 Acres",
sizes:"1272 - 1922 Sq.ft.",
launchDate:"Feb, 2021",
possessionDate:"Dec, 2025",
totalTowers:"5 Towers",
totalFloors:"22 Floors",
configurations:"2BHK, 3BHK, 4BHK ",
location:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.0129681049725!2d77.43277193488767!3d28.56937330000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ceec4a05c4e9b%3A0x7696629e81633ef!2sTRIDENT%20EMBASSY%20RESO!5e0!3m2!1sen!2sin!4v1674031721198!5m2!1sen!2sin", 
totalFlats:"572",
  greenArea:"70%",
  totalFloors:"22",
  ceilingHeight:"10 Ft.",
  mainDoorHeight:"9 Ft.",
  internalDoorHeight:"7 Ft.",
  para1:"Trident Embassy Reso is a premium residential project located in Sector 1, Greater Noida West. Developed by Trident Realty, this project offers luxurious and comfortable living spaces that cater to the needs and preferences of modern-day families.",
  para2:"The apartments at Trident Embassy Reso are well-designed and come in 2 BHK, 3 BHK, and 4 BHK configurations, with sizes ranging from 990 square feet to 2285 square feet. The apartments are spacious and well-ventilated and come equipped with a range of modern amenities and facilities, including air conditioning, modular kitchens, high-speed elevators, and 24/7 security services.",
  para3:"Trident Embassy Reso also offers a wide range of recreational and lifestyle facilities, including a swimming pool, a gymnasium, a club house, a children's play area, and a dedicated sports area. The project also features beautifully landscaped gardens and green spaces that provide a serene and peaceful environment for residents to relax and unwind.",
  para4:"Located in Sector 1, Greater Noida West, Trident Embassy Reso enjoys excellent connectivity to major business and commercial hubs in Noida and Delhi. The project is well-connected to major roads and highways, including the Noida-Greater Noida Expressway, the Yamuna Expressway, and the FNG Expressway. It is also located close to major metro stations and bus stops, making it easy for residents to commute to different parts of the city.",
  para5:"Overall, Trident Embassy Reso is a great investment for homebuyers looking for a luxurious and comfortable living experience in Greater Noida West. With its range of modern amenities and facilities, excellent connectivity, and serene environment, Trident Embassy Reso is the perfect place to call home.",
  text1:"Trident Embassy Reso is a premium residential project located in Sector 1, Greater Noida West, offering luxurious 2, 3, and 4 BHK apartments with world-class amenities such as a clubhouse, swimming pool, gymnasium, and landscaped gardens. The apartments are designed with modern architecture and spacious interiors, providing a comfortable and elegant living experience. The project is located in a prime location with easy access to major landmarks and is well-connected to other parts of the city via major highways and roads. It also incorporates sustainable features like rainwater harvesting, solar power generation, and waste management systems, making it an ideal choice for those who are environmentally conscious. With its prime location, luxurious amenities, and sustainable features, Trident Embassy Reso is an excellent choice for those looking for a premium living experience in Greater Noida West.",
  faq1:"Why to choose Trident Reso ?",
  ans1:"Posh location,Excellent Connectivity",
  faq2:"What is Trident Reso Address?",
  ans2:"Plot No GH 05B, Sector 1, Noida Extension, Greater Noida West, UP",
  faq3:"What is 2 BHK Starting Price? ",
  ans3:"2 BHK flat of Size 1272 - 1287 sq.ft. Starts from 1.08 - 1.14 Cr+ Govt. Charges",
  faq4:"How many flat units available in Trident Reso ?",
  ans4:"572 flats are available in the project",
  faq5:"Does Trident Reso has kids play area?",
  ans5:"Yes, there is kids playing area available",
  faq6:"What is the RERA no. of Trident Reso?",
  ans6:"Trident Reso RERA NO.UPRERAPRJ785019 ",
  faq7:"What is the Possession date of Trident Reso  ?",
  ans7:"Expected Possession date is around Dec, 2025 ",
  faq8:"Which is the nearby metro station from Trident Embassy Reso ?",
  ans8:"Noida Sector 101 Metro Station is the nearest metro station from Trident Embassy Reso.",
  faq9:"Are there any safety related concerns in Sector 1 Greater Noida West?",
  ans9:"Former and existing residents have rated this locality 4/5 on safety. This means, this is one of the good localities where safety is not a concern.   ",
  faq10:"What are the security features of Trident Embassy Reso ?",
  ans10:"Trident Embassy Reso offers Video Door Security, 24x7 Security, Security Cabin and InterCom to ensure all the residents feel safe and secure.",
}
   
      
      ];
    
    