import React from 'react';
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { data } from "../Pageview/data";


const Gallery = () => {
    let publicUrl = process.env.PUBLIC_URL+'/'
    const { id } = useParams();

  console.log(id);

  const filteredData = data.filter((item) => {
    return Object.values(item).includes(id);
  });

  console.log(filteredData);
  return (
    <div className="ltn__img-slider-area">
    <div className="container-fluid">
    <div className="row ltn__image-slider-4-active slick-arrow-1 slick-arrow-1-inner ltn__no-gutter-all">
    {filteredData.map((data) => (
        <>
        <div className="col-lg-12">
        <div className="ltn__img-slide-item-4">
        <a href={data.pic1} data-rel="lightcase:myCollection">
						<img src={data.pic1} alt="Image" />
						</a>
          
            
           
        </div>
        </div>
        <div className="col-lg-12">
        <div className="ltn__img-slide-item-4">
        
        <a href={data.pic2} data-rel="lightcase:myCollection">
						<img src={data.pic2} alt="Image" />
						</a>
            
            
        </div>
        </div>
        <div className="col-lg-12">
        <div className="ltn__img-slide-item-4">
            
        <a href={data.pic3} data-rel="lightcase:myCollection">
						<img src={data.pic3} alt="Image" />
						</a>
            
        
           
          
        </div>
        </div>
        <div className="col-lg-12">
        <div className="ltn__img-slide-item-4">
        
        <a href={data.pic4} data-rel="lightcase:myCollection">
						<img src={data.pic5} alt="Image" />
						</a>
            
            
            
        </div>
        </div>
        <div className="col-lg-12">
        <div className="ltn__img-slide-item-4">
        
        <a href={data.pic5} data-rel="lightcase:myCollection">
						<img src={data.pic6} alt="Image" />
						</a>
            
            
            
        </div>
        </div>
        
      
        </>
    
            ))}
    </div>

    </div>
</div>
  )
}

export default Gallery
